import { useLayoutEffect, useState } from 'react';
import { BsBellFill } from 'react-icons/bs';
import NotificacoesCard from '../Notificações/NotificacoesCard';
import { useReactQueryDashboard } from '../../Utils/ReactQuery/getDashboardUtils';
import { useQuery } from '@tanstack/react-query';
import { useUserContext } from '../../Context/useUserContext';
import { TNotifiicacoes } from '../../Utils/types/PortalTypes';

const NotificaçõesComponent = () => {
  const [showNotificações, setShowNotificações] = useState<boolean>(false);
  const [userCntext] = useUserContext();

  const empresaPadrão = localStorage.getItem('empresaPadrão');
  const token = localStorage.getItem('token');
  const { getNotificações } = useReactQueryDashboard();

  const { data: notificaçõesData, isLoading: isLoadingData } = useQuery({
    queryFn: () => getNotificações(empresaPadrão as string, token as string),
    queryKey: ['rqNotificações', empresaPadrão],
    staleTime: 1000 * 60 * 3,
  });


  const [notificaçõesPastas, setNotificaõesPastas] = useState<any>([]);
  const [notificaçõesPortais, setNotificaçõesPortais] = useState<any>([]);
  const [notificaçõesProcessos, setNotiificaçõesProcessos] = useState<any>([]);

  useLayoutEffect(() => {
    let notificaçõesPastasTemp: any = [];
    let notificaçõesPortaisTemp: any = [];
    let notificaçõesProcessosTemp: any = [];

    const trazerNotifiicações = () => {
      notificaçõesData?.notificações?.forEach((i: TNotifiicacoes) => {
        switch (true) {
          case i.tipo === 'Processo':
            return notificaçõesProcessosTemp.push(i);
          case i.tipo === 'Pasta':
            return notificaçõesPastasTemp.push(i);
          case i.tipo === 'Portal':
            return notificaçõesPortaisTemp.push(i);
        }
      });

      setNotificaçõesPortais(notificaçõesPortaisTemp);
      setNotificaõesPastas(notificaçõesPastasTemp);
      setNotiificaçõesProcessos(notificaçõesProcessosTemp);
    };

    try {
      trazerNotifiicações();
    } catch (error) {
      console.error(error);
    }
  }, [notificaçõesData]);

  return (
    <div className='z-50'>
      <div
        className='flex items-center justify-center mb-2 relative cursor-pointer opacity-75 hover:opacity-100'
        onClick={() => setShowNotificações((current) => !current)}
      >
        <BsBellFill className='text-xl' />
        <span className='absolute -top-1/2 left-5 w-4 h-4 rounded-full flex items-center justify-center bg-red-600 p-0 m-0'>
          {notificaçõesData?.notificações?.filter((i: TNotifiicacoes) => 
            i.usuarios.includes(userCntext.User.id)
          ).length || 0}
        </span>
      </div>

      <div className='h-full dark:border-gray-700'>
        {notificaçõesData && showNotificações ? (
          <NotificacoesCard notificaçõesData={notificaçõesData.notificações || []} />
        ) : null}
      </div>
    </div>
  );
};

export default NotificaçõesComponent;
