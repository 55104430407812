import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react'
import Chart, { Props } from "react-apexcharts";
import { BsBook, BsCheckSquare, BsClock, BsEye, BsFlag, BsHandThumbsDown, BsHandThumbsUp, BsTrophy, BsTruck, BsX } from 'react-icons/bs';
import { CgSandClock } from 'react-icons/cg';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { GoGraph } from 'react-icons/go';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { LiaToolsSolid } from 'react-icons/lia';
import { RiSearchEyeLine } from 'react-icons/ri';
import { TbCalendarCancel } from 'react-icons/tb';


const Operações = ({ dadosVencimento, totalProcessos }: any) => {

  useEffect(() => {
    console.log('----------------->: ', dadosVencimento)
  }, [dadosVencimento])

  const [trintaDias, setTrintaDias] = useState<number>(0);
  const [sessentaDias, setSessentaDias] = useState<number>(0);
  const [noventaDias, setNoventaDias] = useState<number>(0);
  const [vencidoTrintaDias, setVencidoTrintaDias] = useState<number>(0);
  const [vencidoSessentaDias, setVencidoSessentaDias] = useState<number>(0);
  const [vencidoNoventaDias, setVencidoNoventaDias] = useState<number>(0);

  const [vigentes, setVigentes] = useState<number>(0);

  const [valorVigente, setValorVigente] = useState<string>('');
  const [valorAVencer, setValorrAVencer] = useState<string>('');
  const [valorVencido, setValorVencido] = useState<string>('');

  const [valoresPastas, setValoresPastas] = useState<any>([]);

  const [dataVencidos, setDataVencidos] = useState<string[]>(['']);
  const [valoresVencidos, setValoresVencidos] = useState<number[]>([0]);

  const [dataAVencer, setDataAVencer] = useState<string[]>(['']);
  const [valoresAVencer, setValoresAVencer] = useState<number[]>([0]);

  const [dataVigente, setDataVigente] = useState<string[]>(['']);
  const [valoresVigente, setValoresVigente] = useState<number[]>([0]);


  const dataAtual = moment.now();

  useLayoutEffect(() => {

    const joinValues = () => {

      let vencidosArr: any = [];
      let aVencer: any = [];
      let vigentes: any = [];

      dadosVencimento && dadosVencimento.forEach((i: any) => {



        const dataFormatada = moment(i.dataVencimento);

        switch (true) {
          case dataFormatada.diff((dataAtual), 'days') < 0:
            return vencidosArr.push(i.valor.replaceAll(/[$.R]+/g, "").replaceAll(",", "."));
            break;
          case dataFormatada.diff((dataAtual), 'days') < 90 && dataFormatada.diff((dataAtual), 'days') > 0:
            return aVencer.push(i.valor.replaceAll(/[$.R]+/g, "").replaceAll(",", "."));
            break;
          case dataFormatada.diff((dataAtual), 'days') >= 90:
            return vigentes.push(i.valor.replaceAll(/[$.R]+/g, "").replaceAll(",", "."));
            break;
        }

      })

      let vencidosTotal: number = 0;
      let aVencerTotal: number = 0;
      let vigentesTotal: number = 0;

      vencidosArr.forEach((i: any) => {
        if (!Number.isNaN(parseFloat(i))) {
          vencidosTotal = vencidosTotal + parseFloat(i)
        }
      })
      aVencer.forEach((i: any) => {
        if (!Number.isNaN(parseFloat(i))) {
          aVencerTotal = aVencerTotal + parseFloat(i)
        }
      })
      vigentes.forEach((i: any) => {
        if (!Number.isNaN(parseFloat(i))) {
          vigentesTotal = vigentesTotal + parseFloat(i)
        }
      })




      setValorVigente(vigentesTotal.toLocaleString("pt-br", { style: "currency", currency: 'BRL' }))
      setValorrAVencer(aVencerTotal.toLocaleString("pt-br", { style: "currency", currency: 'BRL' }))
      setValorVencido(vencidosTotal.toLocaleString("pt-br", { style: "currency", currency: 'BRL' }))

      setValoresPastas([aVencerTotal.toLocaleString("pt-br", { style: "currency", currency: 'BRL' }), vencidosTotal.toLocaleString("pt-br", { style: "currency", currency: 'BRL' }), vigentesTotal.toLocaleString("pt-br", { style: "currency", currency: 'BRL' })])

    }

    const ajustarDadosChartUm = () => {
      dadosVencimento && dadosVencimento.forEach((i: any) => {

        const dataFormatada = moment(i.dataVencimento);
        switch (true) {
          case dataFormatada.diff((dataAtual), 'days') <= 90 && dataFormatada.diff((dataAtual), 'days') > 60:
            return setNoventaDias(noventaDias => noventaDias + 1)
            break;
          case dataFormatada.diff(dataAtual, 'days') <= 60 && dataFormatada.diff(dataAtual, 'days') > 30:
            return setSessentaDias(sessentaDias => sessentaDias + 1);
            break;
          case dataFormatada.diff(dataAtual, 'days') <= 30 && dataFormatada.diff(dataAtual, 'days') > 0:
            return setTrintaDias(trintaDias => trintaDias + 1);
            break;
          case dataFormatada.diff(dataAtual, 'days') > -30 && dataFormatada.diff(dataAtual, 'days') < 0:
            return setVencidoTrintaDias(vencidoTrintaDias => vencidoTrintaDias + 1);
            break;
          case dataFormatada.diff(dataAtual, 'days') >= -60 && dataFormatada.diff(dataAtual, 'days') < -30:
            return setVencidoSessentaDias(vencidoSessentaDias => vencidoSessentaDias + 1);
            break;
          case dataFormatada.diff(dataAtual, 'days') < -60:
            return setVencidoNoventaDias(vencidoNoventaDias => vencidoNoventaDias + 1)
            break;
        }
      })

    }

    const ajustarChartDois = () => {
      dadosVencimento && dadosVencimento.forEach((i: any) => {
        const dataFormatada = moment(i.dataVencimento);
        if (dataFormatada.diff(dataAtual, 'days') >= 90) {
          setVigentes(vigentes => vigentes + 1)
          return;
        }
      })
    }

    const ajustarAreaChart = () => {

      const valoresValidos = dadosVencimento.filter((i: any) => {
        if (i.dataVencimento !== null && i.valor !== null && i.dataVencimento !== '' && i.valor !== '') { return i }
      }).sort((a: any, b: any) => {
        let c: any = moment(a.dataVencimento);
        let d: any = moment(b.dataVencimento);
        return c - d
      })


      let dataVencida: string[] = [];
      let valorVencido: number[] = []
      let dataAVencerTemp: string[] = [];
      let valoraAVencerTemp: number[] = []
      let dataVigenteTemp: string[] = [];
      let valorVigenteTemp: number[] = []


      valoresValidos && valoresValidos.forEach((i: any) => {


        switch (true) {
          case moment(i.dataVencimento).diff((dataAtual), 'days') < 0:
            let data: any = moment(i.dataVencimento).format('DD/MM/YY')
            dataVencida.push(data);
            const numero = parseFloat(i.valor.replaceAll(/[$.R]+/g, "").replaceAll(",", "."));
            valorVencido.push(numero)
            break;
          case moment(i.dataVencimento).diff((dataAtual), 'days') < 90 && moment(i.dataVencimento).diff((dataAtual), 'days') > 0:

            let data2: any = moment(i.dataVencimento).format('DD/MM/YY')
            dataAVencerTemp.push(data2);
            const numero2 = parseFloat(i.valor.replaceAll(/[$.R]+/g, "").replaceAll(",", "."));
            valoraAVencerTemp.push(numero2)
            break;
          case moment(i.dataVencimento).diff((dataAtual), 'days') >= 90:
            let data3: any = moment(i.dataVencimento).format('DD/MM/YY')
            dataVigenteTemp.push(data3);
            const numero3 = parseFloat(i.valor.replaceAll(/[$.R]+/g, "").replaceAll(",", "."));
            valorVigenteTemp.push(numero3)
            break;

        }


      })


      setValoresVencidos(valorVencido);
      setDataVencidos(dataVencida)

      setDataAVencer(dataAVencerTemp)
      setValoresAVencer(valoraAVencerTemp)

      setDataVigente(dataVigenteTemp)
      setValoresVigente(valorVigenteTemp)

    }

    joinValues();
    ajustarDadosChartUm();
    ajustarChartDois();
    ajustarAreaChart()

  }, [dadosVencimento])



  const chartTotalData: Props = {
    options: {
      chart: {
        id: "basic-bar",
      }, title: {
        text: 'Visão geral dos processos',
      },
      xaxis: {
        labels: {
          show: false
        },
        categories: [
          'Desaprovado'   ,
          'Aprovado'      ,
          'Fazendo'       ,
          'Feito'         ,
          'Revogada'      ,
          'Orçamento'     ,
          'Sessões do Dia',
          'Acompanhamento',
          'Perdida'       ,
          'Ganha'         ,
          'Entregando'    ,
          'Ocorrencia'    ,
          'Execução'      ,
          'Encerrado'     ,
          'Registrar'     ,
        ]
      },
      yaxis: {
        show: true

      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
    },
    series: [
      {
        name: "Processos",
        data: [
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Desaprovado'    }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Aprovado'       }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Fazendo'        }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Feito'          }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Revogada'       }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Orçamento'      }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Sessões do Dia' }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Acompanhamento' }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Perdida'        }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Ganha'          }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Entregando'     }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Ocorrencia'     }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Execução'       }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Encerrado'      }).length || 0,
          totalProcessos.filter((i: any) => { return i.situacaoInterna === 'Registrar'      }).length || 0,
        ],
        color: '#8b00db',

      },
    ]
  }



  const chartUmData = {
    options: {
      chart: {
        id: "basic-bar",
      }
      , title: {
        text: 'Vigência dos contratos',
      },
      xaxis: {
        labels: {
          show: false
        },
        categories: [90, 60, 30],

      },
      yaxis: {
        show: true
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
    },
    series: [
      {
        name: "A vencer",
        data: [noventaDias || 0, sessentaDias || 0, trintaDias || 0],
        color: '#f2d724',

      },
      {
        name: "Vencidos",
        data: [vencidoNoventaDias || 0, vencidoSessentaDias || 0, vencidoTrintaDias || 0],
        color: '#eb170c'
      }
    ]
  };

  const fakeDataDonut: Props = {
    options: {
      colors: ['#b50505', '#f2d724', '#05b50b'],
      title: {
        text: 'Visão geral dos contratos',
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        position: 'bottom'
      },
      labels: [`Vencidos: ${valorVencido}`, `A vencer: ${valorAVencer}`, `Vigentes: ${valorVigente}`],
      dataLabels: {
        enabled: true,
        formatter: function (val: any, opt: any) {
          const index = opt.seriesIndex
          const labelUsed = `${val.toFixed(0)}%`
          return labelUsed
        },
        offsetX: 0,
        dropShadow: { enabled: true }
      },


    },

    series: [vencidoNoventaDias + vencidoSessentaDias + vencidoTrintaDias, trintaDias + sessentaDias + noventaDias, vigentes],
    labels: ['Vencidas', 'Em 90 dias', 'Acima de 90 dias'],

  }


  const areaChartVencidos: Props = {

    series: [{
      name: 'Valores em contratos (vencimento em até 90 dias)',
      data: valoresVencidos,
      color: '#eb170c'
    }],
    options: {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'Valores de contratos vencidos',
        align: 'left'
      },
      xaxis: {
        type: 'category',
        categories: dataVencidos
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },

  }
  const areaChartAVencer: Props = {

    series: [{
      name: 'Valores em contratos vencidos',
      data: valoresAVencer,
      color: '#f2d724'
    }],
    options: {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'Valores de contratos que vencem em 90 dias',
        align: 'left'
      },
      xaxis: {
        type: 'category',
        categories: dataAVencer
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },

  }
  const areaChartVigentes: Props = {

    series: [{
      name: 'Valores em contratos vigentes (vencimento + 90 dias)',
      data: valoresVigente,
      color: '#05b50b'
    }],
    options: {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'CValores de contratos que vencem em mais de 90 dias',
        align: 'left'
      },
      xaxis: {
        type: 'category',
        categories: dataVigente
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },

  }



  // //~~MANTENÇÃO~~//
  // useEffect(()=>{
  //   console.log('Valores nas pastas: ', valorAVencer)
  // },[valorAVencer])
  // //~~MANTENÇÃO~~//

  return (
    <div className='flex flex-col justify-between gap-10'>

      <div className='w-full flex flex-col items-center justify-center relative'>
       
        <Chart
          options={chartTotalData.options}
          series={chartTotalData.series}
          type="bar"
          width="550"

        />

        <div className='w-[550px] ml-5 flex flex-row justify-around items-stretch pr-2 pl-8 absolute bottom-4'>

          <i><BsHandThumbsDown/></i>
          <i><BsHandThumbsUp/></i>
          <i><CgSandClock/></i>
          <i><BsCheckSquare/></i>
          <i><TbCalendarCancel/></i>
          <i><FaRegMoneyBillAlt/></i>
          <i><BsClock/></i>
          <i><RiSearchEyeLine/></i>
          <i><IoIosCloseCircleOutline/></i>
          <i><BsTrophy/></i>
          <i><BsTruck/></i>
          <i><LiaToolsSolid/></i>
          <i><GoGraph/></i>
          <i><BsFlag/></i>
          <i><BsBook/></i>

        </div>
      </div>

      <div className='flex flex-row gap-2 justify-center items-center'>


        <Chart
          type='pie'
          series={fakeDataDonut.series}
          options={fakeDataDonut.options}
          width={500}
        />

        <Chart
          options={chartUmData.options}
          series={chartUmData.series}
          type="bar"
          width="500"
        />

      </div>

      <div>
        <Chart
          type='area'
          series={areaChartAVencer.series}
          options={areaChartAVencer.options}
          height={300}
        />
        <Chart
          type='area'
          series={areaChartVencidos.series}
          options={areaChartVencidos.options}
          height={300}
        />
        <Chart
          type='area'
          series={areaChartVigentes.series}
          options={areaChartVigentes.options}
          height={300}
        />

      </div>
    </div>
  )
}

export default Operações