import React, { useEffect, useRef, useState } from 'react';
import { BsArrowRight, BsEye, BsEyeSlash, BsSearch, BsX } from 'react-icons/bs';
import { IMaskInput } from 'react-imask';
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser';
import { useQuery } from '@tanstack/react-query';
import { checkCpfCnpj } from '../../Utils/checkCpfCnpj';
import { NivelHierarquicoRegistro, RegistroEmpresa } from '../../Utils/types/cadastroTypes';
import TermosRegistro from './TermosRegistro';

const ColaboradorFormEtapaUm = ({ registrar, fechar, empresas }: any) => {
    const [cpfCnpj, setCpfCnpj] = useState<string>('');
    const [cpfCnpjVerify, setCpfCnpjVerify] = useState<Boolean | any>(true);
    const [passWordError, setPasswordError] = useState<Boolean>(false);
    const [checkPassword, setCheckPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [repetirSenha, setRepetirSenha] = useState<string>('');
    const [passwordErrorrMesg, setPasswordErrorMsg] = useState<string>('');

    const [cnpjBuscar, setCnpjBuscar] = useState<string>('');
    const [empresaAfiliada, setEmpresaAfiliada] = useState<any>(null);
    const [buscarEmpresaid, setBuscarEmpresaId] = useState<string>('')
    const [usuariosError, setUsuariosError] = useState<boolean>(true);

    const [condiçõesLegais, setCondiçõesLegais] = useState<boolean>(false);
    const [politicaPrivacidade, setPoliticaPrivacidade] = useState<boolean>(false);
    const [termoAceito, setTermoAceito] = useState<boolean>(false)


    const { getOrganogramaInfo } = useReactQueryUsers();
    const { data: organogramaData, refetch } = useQuery({
        queryFn: () => getOrganogramaInfo(buscarEmpresaid || ''),
        queryKey: ["rqOrganogramaUsers", buscarEmpresaid],

    });

    const usuariosRef = useRef<HTMLInputElement | any>(null)

    // //~~ANUUTENÇÃO~~//
    // if(organogramaData){
    //     console.log('Organograma data: ', organogramaData)
    // }

    // useEffect(()=>{
    //     console.log('Cnpj buscado: ', cnpjBuscar)
    // },[cnpjBuscar])
    // //~~ANUUTENÇÃO~~//

    const empresaRef = useRef<HTMLSelectElement | null>(null);

    const checkPass = (e: any) => {
        e.preventDefault();
        if (checkPassword.length > 0 && checkPassword.length < 8) {
            setPasswordError(true);
            setPasswordErrorMsg('A senha deve ter mais de 8 dígitos');
            return;
        }

        if (repetirSenha.length >= 1 && repetirSenha !== checkPassword) {
            setPasswordError(true);
            setPasswordErrorMsg('As senhas digitadas não coincidem!');
            return;
        }
        setPasswordError(false);
    };

    const handleInputChange =  (value: string) => {
        setCnpjBuscar(value);

        if (value.length === 18) {
            try {
                const empresaTemp = empresas.find((i: any) => i.CnpjEmpresa === value);
                if (empresaTemp) {
                    setEmpresaAfiliada(empresaTemp);
                    setBuscarEmpresaId(empresaTemp._id)
                    setUsuariosError(false)
                } else {
                    setEmpresaAfiliada(null);
                    usuariosRef.current.value = ''
                    setUsuariosError(true)
                }
            } catch (error) {
                console.error("Erro ao buscar empresa:", error);
                setEmpresaAfiliada(null);
                usuariosRef.current.value = ''
                setUsuariosError(true)

            }
        } else {
            setEmpresaAfiliada(null); // Limpa caso o valor não seja completo
        }
    };

    const handleEtapa = (e: any) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);

        const dadosEtapa = {
            Nome: formData.get('Nome'),
            Email: formData.get('Email'),
            Senha: formData.get('Senha'),
            CpfCnpj: formData.get('CpfCnpj'),
            Acesso: formData.get('Acesso'),
            Cadastro: 'Colaborador',
            CargoFuncao: formData.get('CargoFuncao'),
            NivelHierarquico: indexNivelHierarquico(formData.get('NivelHierarquico') as NivelHierarquicoRegistro),
            SuperiorImediato: formData.get('SuperiorImediato'),
            Afiliação: empresaAfiliada.CnpjEmpresa
        };

        registrar(dadosEtapa);
    };

    const indexNivelHierarquico = (hierarquia: NivelHierarquicoRegistro) => {
        switch (true) {
            case hierarquia === NivelHierarquicoRegistro.ASSESSOR:
                return { tipo: 'Assessor', nivel: 1 };
            case hierarquia === NivelHierarquicoRegistro.PRESIDENTE:
                return { tipo: 'Presidente', nivel: 2 };
            case hierarquia === NivelHierarquicoRegistro.VICE_PRESIDENTE:
                return { tipo: 'Vice-Presidente', nivel: 3 };
            case hierarquia === NivelHierarquicoRegistro.DIRETOR:
                return { tipo: 'Diretor', nivel: 4 };
            case hierarquia === NivelHierarquicoRegistro.GERENTE:
                return { tipo: 'Gerente', nivel: 5 };
            case hierarquia === NivelHierarquicoRegistro.SUPERVISOR:
                return { tipo: 'Supervisor', nivel: 6 };
            case hierarquia === NivelHierarquicoRegistro.COODERNADOR:
                return { tipo: 'Coordenador', nivel: 7 };
            case hierarquia === NivelHierarquicoRegistro.TÉCNICO:
                return { tipo: 'Técnico', nivel: 8 };
            case hierarquia === NivelHierarquicoRegistro.ANALISTA:
                return { tipo: 'Analista', nivel: 9 };
            case hierarquia === NivelHierarquicoRegistro.ASSISTENTE:
                return { tipo: 'Assistente', nivel: 10 };
            case hierarquia === NivelHierarquicoRegistro.AUXILIAR:
                return { tipo: 'Auxiliar', nivel: 11 };
            case hierarquia === NivelHierarquicoRegistro.ESTAGIÁRIO:
                return { tipo: 'Estagiário', nivel: 12 };
            case hierarquia === NivelHierarquicoRegistro.TRAINEE:
                return { tipo: 'Trainee', nivel: 13 };
            case hierarquia === NivelHierarquicoRegistro.JOVEM_APRENDIZ:
                return { tipo: 'Jovem Aprendiz', nivel: 14 };
            default:
                return { tipo: '', nivel: 0 };
        }
    };


    const fecharTermoModal = () => {
        setPoliticaPrivacidade(false);
        setCondiçõesLegais(false);
    }

    // Tailwind Classes
    //Tailwind ----------------------
    const inputField = '"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"';
    const labelField = "block mb-2 text-sm font-medium text-gray-900 dark:text-white";

    const button = "cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit"
    const closeButton = "bg-red-500 cursor-pointer text-white inline-flex items-center bg-bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:bg-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 h-fit"


    return (
        <div className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">

            {           
                politicaPrivacidade && <TermosRegistro showTermsModal={false} showPrivacyModal={true} fechar={fecharTermoModal} />
            }

            {
                condiçõesLegais && <TermosRegistro showTermsModal={true} showPrivacyModal={false} fechar={fecharTermoModal} />
            }

            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
                <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                    <form onSubmit={handleEtapa} className="mt-2">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Cadastrar colaborador</h3>
                        <label> Informações do usuário</label>

                        <div className="grid gap-4 mb-4 sm:grid-cols-2">
                            <div>
                                <label htmlFor="foneLicitante" className={labelField}>Tipo de acesso a ser criado<span className="text-red-500"> *</span></label>
                                <select className={inputField} defaultValue="Empresa" required name="Acesso">
                                    <option value="Administrador" disabled>Administrador</option>
                                    <option value="Governo" disabled>Governo</option>
                                    <option value="Empresa">Empresa</option>
                                    <option value="Assessoria">Assessoria</option>
                                    <option value="Banco" disabled>Banco</option>
                                </select>
                            </div>

                            <div>
                                <label className={labelField}>Nome<span className="text-red-500"> *</span></label>
                                <input type="text" name="Nome" className={inputField} />
                            </div>

                            <div>
                                <label className={labelField}>E-mail<span className="text-red-500"> *</span></label>
                                <input type="email" name="Email" className={inputField} />
                            </div>

                            <div>
                                <label className={labelField}>CPF/CNPJ<span className="text-red-500"> *</span></label>
                                <IMaskInput
                                    mask={[
                                        { mask: '000.000.000-00', maxLength: 11 }, // CPF
                                        { mask: '00.000.000/0000-00', maxLength: 14 } // CNPJ
                                    ]}
                                    type="text"
                                    name="CpfCnpj"
                                    className={inputField}
                                    placeholder=""
                                    required
                                    value={cpfCnpj}
                                    onAccept={(value) => setCpfCnpj(value)}
                                    onBlur={() => setCpfCnpjVerify(checkCpfCnpj(cpfCnpj))}
                                />
                                {cpfCnpj && cpfCnpj.length > 2 && !cpfCnpjVerify && (
                                    <p className="text-red-500">*CPF/CNPJ Inválido!</p>
                                )}
                            </div>
                        </div>

                        <div className="grid gap-4 mb-4 sm:grid-cols-2">
                            <div>
                                <label className={labelField}>Senha<span className='text-red-500'> *</span></label>
                                <div className="relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        name="Senha"
                                        className={`${inputField} pr-10`}
                                        placeholder=" "
                                        required
                                        onBlur={checkPass}
                                        onChange={(e) => setCheckPassword(e.target.value)}
                                    />
                                    <div
                                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                        onClick={() => setShowPassword((current) => !current)}
                                    >
                                        {showPassword ? (
                                            <BsEyeSlash className="w-4 h-4" />
                                        ) : (
                                            <BsEye className="w-4 h-4" />
                                        )}
                                    </div>
                                </div>
                                {passWordError && <p className='text-red-500 '>A senha deve conter mais de 8 caracteres</p>}
                            </div>

                            <div>
                                <label className={`${labelField}`}>Confirmar senha<span className='text-red-500'> *</span></label>
                                <div className="relative">
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        name="repetirSenha"
                                        className={`${inputField} pr-10`}
                                        placeholder=" "
                                        required
                                        onBlur={checkPass}
                                        onChange={(e) => setRepetirSenha(e.target.value)}
                                    />
                                    <div
                                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                        onClick={() => setShowConfirmPassword((current) => !current)}
                                    >
                                        {showConfirmPassword ? (
                                            <BsEyeSlash className="w-4 h-4" />
                                        ) : (
                                            <BsEye className="w-4 h-4" />
                                        )}
                                    </div>
                                </div>
                                {passWordError && <p className='text-red-500 '>{passwordErrorrMesg}</p>}
                            </div>
                        </div>


                        <div className="grid gap-4 mb-4 sm:grid-cols-1">
                            <div>
                                <label className={labelField}>Buscar CNPJ<span className="text-red-500"> *</span></label>
                                <div className="relative">
                                    <IMaskInput
                                        mask="00.000.000/0000-00"
                                        onAccept={handleInputChange}
                                        className={`${inputField} pr-10`}
                                        placeholder="Digite o CNPJ da empresa que você é colaborador"
                                    />
                                    <BsSearch className="absolute inset-y-0 right-3 flex items-center justify-center h-full text-gray-500 dark:text-gray-400" />
                                </div>
                            </div>

                            <div>
                                <select
                                    name="Afiliação"
                                    required
                                    className={`${inputField} appearance-none cursor-not-allowed`}
                                    ref={empresaRef}
                                    disabled
                                >
                                    {empresaAfiliada ? (
                                        <option value={empresaAfiliada.CnpjEmpresa}>
                                            {empresaAfiliada.NomeEmpresa} ({empresaAfiliada.CnpjEmpresa})
                                        </option>
                                    ) : (
                                        <option value="">CNPJ não encontrado</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="grid gap-4 mb-4 sm:grid-cols-2">
                            <div>
                                <label className={labelField}>Nível hierárquico<span className="text-red-500"> *</span></label>
                                <select name="NivelHierarquico" className={inputField}>
                                    <option value={NivelHierarquicoRegistro.PRESIDENTE}>Presidente</option>
                                    <option value={NivelHierarquicoRegistro.VICE_PRESIDENTE}>Vice-Presidente</option>
                                    <option value={NivelHierarquicoRegistro.DIRETOR}>Diretor</option>
                                    <option value={NivelHierarquicoRegistro.GERENTE}>Gerente</option>
                                    <option value={NivelHierarquicoRegistro.SUPERVISOR}>Supervisor</option>
                                    <option value={NivelHierarquicoRegistro.COODERNADOR}>Coordenador</option>
                                    <option value={NivelHierarquicoRegistro.TÉCNICO}>Técnico</option>
                                    <option value={NivelHierarquicoRegistro.ANALISTA}>Analista</option>
                                    <option value={NivelHierarquicoRegistro.ASSISTENTE}>Assistente</option>
                                    <option value={NivelHierarquicoRegistro.AUXILIAR}>Auxiliar</option>
                                    <option value={NivelHierarquicoRegistro.ESTAGIÁRIO}>Estagiário</option>
                                    <option value={NivelHierarquicoRegistro.TRAINEE}>Trainee</option>
                                    <option value={NivelHierarquicoRegistro.JOVEM_APRENDIZ}>Jovem Aprendiz</option>
                                    <option value={NivelHierarquicoRegistro.ASSESSOR}>Assessor</option>
                                </select>
                            </div>

                            <div>
                                <label className={labelField}>Cargo/Função<span className="text-red-500"> *</span></label>
                                <select name="CargoFuncao" className={inputField}>
                                    <option value={RegistroEmpresa.GERAL}>Geral</option>
                                    <option value={RegistroEmpresa.ADMINISTRATIVO}>Administrativo</option>
                                    <option value={RegistroEmpresa.ATENDIMENTO}>Atendimento</option>
                                    <option value={RegistroEmpresa.FINANCEIRO}>Financeiro</option>
                                    <option value={RegistroEmpresa.CONTAS_Á_RECEBER}>Contas á Receber</option>
                                    <option value={RegistroEmpresa.CONTAR_Á_PAGAR}>Contar á Pagar</option>
                                    <option value={RegistroEmpresa.CONCILIAÇÃO_BANCÁRIA}>Conciliação Bancária</option>
                                    <option value={RegistroEmpresa.COBRANÇA}>Cobrança</option>
                                    <option value={RegistroEmpresa.FATURAMENTO}>Faturamento</option>
                                    <option value={RegistroEmpresa.COMERCIAL}>Comercial</option>
                                    <option value={RegistroEmpresa.COMPRAS}>Compras</option>
                                    <option value={RegistroEmpresa.VENDAS}>Vendas</option>
                                    <option value={RegistroEmpresa.MARKETING}>Marketing</option>
                                    <option value={RegistroEmpresa.PROPAGANDA}>Propaganda</option>
                                    <option value={RegistroEmpresa.ALMOXARIFADO}>Almoxarifado</option>
                                    <option value={RegistroEmpresa.LICITAÇÕES_E_CONTRATOS}>Licitações e Contratos</option>
                                    <option value={RegistroEmpresa.LICITAÇÕES}>Licitações</option>
                                    <option value={RegistroEmpresa.CONTRATOS}>Contratos</option>
                                    <option value={RegistroEmpresa.CAPTAÇÃO}>Captação</option>
                                    <option value={RegistroEmpresa.PROPOSTA}>Proposta</option>
                                    <option value={RegistroEmpresa.HABILITAÇÃO}>Habilitação</option>
                                    <option value={RegistroEmpresa.REGISTRO}>Registro</option>
                                    <option value={RegistroEmpresa.RECURSOS_ADMINISTRATIVO}>Recursos Administrativo</option>
                                    <option value={RegistroEmpresa.OPERADOR}>Operador</option>
                                    <option value={RegistroEmpresa.JURÍDICO_E_COMPLIANCE}>Jurídico e Compliance</option>
                                    <option value={RegistroEmpresa.JURÍDICO}>Jurídico</option>
                                    <option value={RegistroEmpresa.COMPLIANCE}>Compliance</option>
                                    <option value={RegistroEmpresa.CONTÁBIL}>Contábil</option>
                                    <option value={RegistroEmpresa.FISCAL}>Fiscal</option>
                                    <option value={RegistroEmpresa.AUDITORIA}>Auditoria</option>
                                    <option value={RegistroEmpresa.RECEITAS}>Receitas</option>
                                    <option value={RegistroEmpresa.REPRESENTAÇÃO}>Representação</option>
                                    <option value={RegistroEmpresa.SEGURO}>Seguro</option>
                                    <option value={RegistroEmpresa.CONTROLLER}>Controller</option>
                                    <option value={RegistroEmpresa.MANUTENÇÃO}>Manutenção</option>
                                    <option value={RegistroEmpresa.PRODUÇÃO}>Produção</option>
                                    <option value={RegistroEmpresa.SINISTRO}>Sinistro</option>
                                    <option value={RegistroEmpresa.QUALIDADE}>Qualidade</option>
                                    <option value={RegistroEmpresa.SEGURANÇA}>Segurança</option>
                                    <option value={RegistroEmpresa.DESENVOLVIMENTO}>Desenvolvimento</option>
                                    <option value={RegistroEmpresa.PESQUISA}>Pesquisa</option>
                                    <option value={RegistroEmpresa.TECNOLOGIA_DA_INFORMAÇÃO}>Tecnologia da Informação</option>
                                    <option value={RegistroEmpresa.RECURSOS_HUMANOS}>Recursos Humanos</option>
                                    <option value={RegistroEmpresa.RECRUTAMENTO}>Recrutamento</option>
                                    <option value={RegistroEmpresa.TREINAMENTO}>Treinamento</option>
                                    <option value={RegistroEmpresa.OPERAÇÕES}>Operações</option>
                                    <option value={RegistroEmpresa.LOGÍSTICA}>Logística</option>
                                    <option value={RegistroEmpresa.GESTÃO_DE_PESSOAS}>Gestão de Pessoas</option>
                                    <option value={RegistroEmpresa.CREDENCIAMENTO}>Credenciamento</option>
                                    <option value={RegistroEmpresa.EXPEDIÇÃO}>Expedição</option>
                                    <option value={RegistroEmpresa.CONSELHO}>Conselho</option>
                                    <option value={RegistroEmpresa.ORÇAMENTISTA}>Orçamentista</option>
                                    <option value={RegistroEmpresa.RELAÇÕES}>Relações</option>
                                    <option value={RegistroEmpresa.CUSTOS}>Custos</option>
                                    <option value={RegistroEmpresa.GESTÃO}>Gestão</option>
                                    <option value={RegistroEmpresa.PROJETOS}>Projetos</option>
                                    <option value={RegistroEmpresa.ESTOQUE}>Estoque</option>
                                    <option value={RegistroEmpresa.ENTREGA}>Entrega</option>
                                    <option value={RegistroEmpresa.CREDITO}>Crédito</option>
                                    <option value={RegistroEmpresa.PATRIMÔNIO}>Patrimônio</option>
                                    <option value={RegistroEmpresa.TESOURARIA}>Tesouraria</option>
                                    <option value={RegistroEmpresa.INOVAÇÃO}>Inovação</option>
                                    <option value={RegistroEmpresa.ARQUIVOS}>Arquivos</option>
                                    <option value={RegistroEmpresa.PRODUTOS}>Produtos</option>
                                    <option value={RegistroEmpresa.TÉCNICO}>Técnico</option>
                                    <option value={RegistroEmpresa.PROTOCOLO}>Protocolo</option>
                                    <option value={RegistroEmpresa.IMPRENSA}>Imprensa</option>
                                    <option value={RegistroEmpresa.AGENDAMENTO}>Agendamento</option>
                                </select>
                            </div>

                            <div>
                                <select name="SuperiorImediato" className={inputField} ref={usuariosRef}>
                                    <option className={inputField}>Selecione o seu superior imediato</option>
                                    {!usuariosError && organogramaData?.usuarios?.map((usuario: any) => (
                                        <option key={usuario._id} value={usuario._id}>{usuario.Nome}</option>
                                    ))}
                                    {
                                        usuariosError &&
                                        <option value='' className={inputField}>Selecione a empresa válida!</option>

                                    }
                                </select>
                            </div>
                        </div>

                        <p className="text-red-500 mb-4">*O login será realizado mediante ao CPF do usuário</p>
                        <div className="flex items-center mb-4">
                            <input
                                type="checkbox"
                                name="terms"
                                className="mr-2"
                                onChange={(e) => {
                                    e.target.checked ? setTermoAceito(true) : setTermoAceito(false)
                                }}
                            />

                            <label >Li e aceito as <span className='underline text-blue-600 cursor-pointer opacity-80 transition-opacity hover:opacity-100' onClick={() => setCondiçõesLegais(current => !current)}>Condições Legais </span> e a <span className='underline text-blue-600 cursor-pointer opacity-80 transition-opacity hover:opacity-100' onClick={() => setPoliticaPrivacidade(current => !current)}>Politica de Privacidade</span>do Portal GLC</label>



                        </div>

                        <div className="flex flex-row w-full justify-between">
                            <button type="submit" className={button}>Salvar informações <BsArrowRight className="text-2xl ml-2" /></button>
                            <label className={closeButton} onClick={fechar}>Cancelar <BsX className="text-2xl ml-2" /></label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ColaboradorFormEtapaUm;
