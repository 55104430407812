import { useState } from 'react'
import { BsArrowRight } from "react-icons/bs";
import { useQuery } from '@tanstack/react-query';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas'
import NovoEmpresa from './NovaEmpresa';
import NovoColaborador from './NovoColaborador';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Register = () => {

  const { getEmpresas } = useReactQueryEmpresas();
  const { data }: any = useQuery({ queryKey: ['rqEmpresas'], queryFn: getEmpresas, staleTime: Infinity });

  enum RegisterModel {
    COLABORADOR = 'colaborador',
    EMPRESA = 'empresa',
    IDLE = 'idle'
  }
  const [tipoFormulario, setTipoFormulario] = useState<RegisterModel>(RegisterModel.IDLE);
  const fechar = () => {
    setTipoFormulario(RegisterModel.IDLE)
  }

  if (tipoFormulario === RegisterModel.EMPRESA) {
    return (
      // <EmpresaForm voltar = {voltar}/>
      <NovoEmpresa fecharRegistro={fechar} />
    )
  }
  if (tipoFormulario === RegisterModel.COLABORADOR) {
    return (
      <NovoColaborador fecharRegistro={fechar} empresas={data.empresas} />
    )
  }

  const handleArrowClick = () => {
    window.location.href = '/';
  }

  const handleBancoInvestidorClick = () => {
    toast.info('Em breve!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }


  return (
    <div className='bg-gray-50 dark:bg-gray-900 w-full min-h-screen'>

      <div id="select-modal" tabIndex={-1} aria-hidden="true" className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="relative p-4 w-full max-w-md max-h-full">

          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-4 md:p-5">
              <div className='flex justify-between items-center'>
                <p className="text-gray-500 dark:text-gray-400 mb-4">Selecione o tipo de registro</p>
                <Link to={'/'}><BsArrowRight className="text-gray-500 dark:text-gray-400 mb-4 transform rotate-180 cursor-pointer" /></Link>

              </div>
              <ul className="space-y-4 mb-4">
                <li onClick={() => setTipoFormulario(RegisterModel.EMPRESA)}>
                  <label className="inline-flex items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
                    <div className="block" >
                      <div className="w-full text-lg font-semibold">Empresa ou Assessoria</div>
                      <div className="w-full text-gray-500 dark:text-gray-400">Gerencie sua empresa ou assessoria com facilidade.</div>
                    </div>
                    <BsArrowRight />
                  </label>
                </li>

                <li onClick={() => setTipoFormulario(RegisterModel.COLABORADOR)}>
                  <label htmlFor="job-2" className="inline-flex items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
                    <div className="block">
                      <div className="w-full text-lg font-semibold">Colaborador</div>
                      <div className="w-full text-gray-500 dark:text-gray-400">Exclusivo para funcionários de empresas ou assessorias registradas.</div>
                    </div>
                    <BsArrowRight />
                  </label>
                </li>

                <li onClick={handleBancoInvestidorClick}>
                  <label htmlFor="job-2" className="inline-flex items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
                    <div className="block">
                      <div className="w-full text-lg font-semibold">Banco Investidor</div>
                      <div className="w-full text-gray-500 dark:text-gray-400">Ofereça crédito e potencialize os negócios das empresas ou assessorias.</div>
                    </div>
                    <BsArrowRight />
                  </label>
                </li>

              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register