import { useState } from "react"
import { typeAlterarAcesso } from "../types/empresaTypes";



export const useGetAcessos = ()=>{

    const baseUrl = process.env.REACT_APP_PRIVATE_API_URL;

    const listarAcessos = async(data: any)=>{
        const url = `${baseUrl}/api/acessos/listar-acessos`;
        
        try {
            const res = await fetch(url,{
                method: 'POST',
                headers:{
                    'content-type':'application/json'
                },
                body: JSON.stringify(data)
            });
            const response = await res.json();
            return response;
            
        } catch (error) {
            console.error(error);
            return null;
        }        

    }


    const configurarAcesso = async(data: typeAlterarAcesso)=>{
        const url = `${baseUrl}/api/acessos/configurar-acesso?token=${data.token}&&action=${data.Action}`;

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    'content-type':'application/json'
                },
                body: JSON.stringify(data.Empresa)
            });
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return;
        }
    }

    const alterarAcesso =async( data: typeAlterarAcesso)=>{
        const url = `${baseUrl}/api/acessos/alterar-acesso?token=${data.token}&&acesso=${data.Empresa?.NovoAcesso}`;

        try {
            const res = await fetch(url);
            const response = res.json();
            return response;
        } catch (error) {
            console.error(error);
            console.error('Erro na solicitação!')
            return null;
        }
    }

    type Tsolicitação ={
        token?: string,
        cnpj: string,
        EmpresaPadrão?: string
    }

     type TSolicitaçãoAssessoria={
        token: string,
        empresaid: string,
        cnpj?: string
    }

    const buscarEmpresa = async(data: Tsolicitação)=>{
        const url = `${baseUrl}/api/acessos/buscar-empresa?cnpj=${data.cnpj}`;

        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
            
        }

    }

    const enviarSolicitação = async(data: Tsolicitação)=>{
        const url = `${baseUrl}/api/acessos/enviar-solicit?token=${data.token}&cnpj=${data.cnpj}&empresaPadrão=${data.EmpresaPadrão}`;

        try {
            const res = await fetch(url);
            const response = res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
            
        }


    }

    const assessoriaSolicitação = async(data: TSolicitaçãoAssessoria)=>{
        const url = `${baseUrl}/api/acessos/solicit-assessoria?token=${data.token}&cnpj=${data.cnpj}&empresaid=${data.empresaid}`;

        try {
            const res = await fetch(url);
            const response = res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
            
        }
    }

    return{
        listarAcessos,
        configurarAcesso,
        alterarAcesso,
        buscarEmpresa,
        enviarSolicitação,
        assessoriaSolicitação
    }
}