import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { BsCheck, BsX } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useAdminUtils } from '../../Utils/ReactQuery/getAdminUtils';
import { StatusSOlicitação } from '../../Utils/types/adminTypes';
import LoadingPage from '../LoadingPage';
import { format } from 'date-fns';
import { addHours } from 'date-fns'; 

const AssessoriaSolicitações = ({ solicitações: dataSolicitações, empresaId }: any) => {

    const { resolverSolicitação, resolverAssessoria, revogarAssessoria } = useAdminUtils();
    const [solicitações, setSolicitações] = useState<any[]>([]);
    const [seleção, setSeleção] = useState<string[]>([]);
    const [loading, setLoading] = useState<Boolean>(false);
    const [error, setError] = useState<Boolean>(false);
    const [errorMsg, setErrorMsg] = useState<String>('');


    const [revogadoId, setRevogadoId] = useState<string>('');
    const [confirmarRevogarAssesso, setConfirmarRevogarAcesso] = useState<boolean>(false)


    const empresaSelecionada = localStorage.getItem('empresaSelecionada');
    const token = localStorage.getItem('token')
    const {listarAssessoria} = useAdminUtils()

    const {data: assessoriasData, isLoading: assessoriasIsLoading} = useQuery({queryFn: ()=> listarAssessoria({empresaid: empresaSelecionada as string, token: token as string}),queryKey: ["rqAssessorias"]})

    const queryClient = useQueryClient();

    const revogarAssessoriaMutation = useMutation({
        mutationFn: revogarAssessoria,
        onMutate: (data)=>{
            setLoading(true)
            // console.log('Mutação revogação de assessoria (empresa) iniciada!')
        },
        onSuccess: async(response)=>{

        queryClient.invalidateQueries({ queryKey: ['rqAssessorias'] });
        queryClient.fetchQuery({ queryKey: ['rqAssessorias'] });
        queryClient.refetchQueries({ queryKey: ['rqAssessorias'] });


        if (response.status === 1) {
            toast.success(response.msg);
            return;
        }
        if (response.status === 3) {
            setError(true);
            setErrorMsg(response.msg);
            toast.error(errorMsg);
            return;
        }
        },
        onError: async (error) => {
            setError(true);
            setErrorMsg(error.message);
        },
        onSettled: () => {
            // console.log('Mutação da revogação de assessoria finalizada!')
            setLoading(false);
            setConfirmarRevogarAcesso(false)
        },
    })

    const solicitMutation = useMutation({
        mutationFn: resolverAssessoria,
        onMutate: async () => {
            setLoading(true);
        },
        onSuccess: async (response) => {
            queryClient.invalidateQueries({ queryKey: ['rqSolicitações'] });
            queryClient.fetchQuery({ queryKey: ['rqSolicitações'] });
            queryClient.refetchQueries({ queryKey: ['rqSolicitações'] });
            queryClient.invalidateQueries({ queryKey: ['rqAssessorias'] });
            queryClient.fetchQuery({ queryKey: ['rqAssessorias'] });
            queryClient.refetchQueries({ queryKey: ['rqAssessorias'] });
           


            if (response.status === 1) {
                toast.success(response.msg);
                return;
            }
            if (response.status === 3) {
                setError(true);
                setErrorMsg(response.msg);
                toast.error(errorMsg);
                return;
            }
        },
        onError: async (error) => {
            setError(true);
            setErrorMsg(error.message);
        },
        onSettled: () => {
            setLoading(false);
            setSeleção([]);
            setRevogadoId('');
            
        },
    });

    useEffect(() => {
        if (dataSolicitações) {
            const arrSolicitações = dataSolicitações.empresa.Empresa_SolicitaçõesAssessoria;
            setSolicitações(arrSolicitações);
        }
    }, [dataSolicitações]);

 
    
    const handleConfigSolicitação = (e: any, StatusSolicitação: StatusSOlicitação | string) => {
        e.preventDefault();

        if (seleção.length <= 0) {
            return;
        }

        let solicitaçõesSelecionadas: any = [];
        if (!solicitaçõesSelecionadas) {
            toast.warn('Nenhuma solicitação selecionada!');
            return;
        }

        seleção.map((a: any) => {
            const tempFind = solicitações.find((i: any) => { return i.EmpresaSolicitação === a });
            solicitaçõesSelecionadas.push(tempFind);
        });

        solicitMutation.mutate({
            empresaid: empresaId,
            StatusSolicitação: StatusSolicitação as string,
            token: token as string,
            data: solicitaçõesSelecionadas
        });

        return;
    };

    const handleSolicitação = (e: any) => {
        const { id, checked } = e.target;
        setSeleção((prevSeleção) =>
            checked ? [...prevSeleção, id] : prevSeleção.filter((item) => item !== id)
        );
    };

    const formatarData = (e: string) => {
        const date = new Date(e);
    
        const brDate = addHours(date, +2);
    
        const formattedDate = format(brDate, 'dd/MM/yyyy HH:mm');
    
        return `${formattedDate}h`;
    };
    
    

    const mapTipoSolicitação = (tipo: string) => {
        if (tipo === 'editar') {
            return 'Administrativo';
        }
        return tipo;
    };


    const handleRevogarAssesso =(id: string)=>{

        setRevogadoId(id);
        setConfirmarRevogarAcesso(true)
        return;
    }

    const revogarAcessoConfirmado = ()=>{
        revogarAssessoriaMutation.mutate({empresaid: empresaSelecionada as string, token: token as string, empresaidSolicitacao: revogadoId as string});


    }
        

    //Tailwind --------------------------------------------------------

    const selectedRow = 'border-b dark:border-gray-700 bg-gray-50 dark:bg-gray-600 text-blue-500';
    const idleRow = 'bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600';

    const activeButton = 'flex flex-row gap-3 text-lg font-semibold opacity-80 hover:opacity-100 transition-opacity cursor-pointer text-center';
    const inactiveButton = 'flex flex-row gap-3 text-lg font-semibold opacity-50 transition-opacity text-center';

    //Tailwind --------------------------------------------------------

    if (loading) {
        return (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-20">
                <div className="flex flex-col justify-center items-center pb-4 bg-white dark:bg-gray-900">
                    <LoadingPage />
                </div>
            </div>
        );
    }

    

    return (
        <div className="mt-20 grid grid-cols-[repeat(auto-fill,minmax(800px,1fr))] w-full">


            {
                confirmarRevogarAssesso &&
                <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-transparent">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative  rounded-lg shadow ">
                            
                            <div className="p-4 md:p-5 text-center bg-gray-100 dark:bg-gray-600">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Revogar o assesso desta empresa? </h3>
                            
                                <button  data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={revogarAcessoConfirmado}> Confirmar</button>
        
                                <button  data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={()=> setConfirmarRevogarAcesso(false)}>Cancelar</button>
        
                            </div>
                        </div>
                    </div>
                </div>    
            }


            <div className="relative overflow-x-auto shadow-md sm:rounded-lg bg-white dark:bg-gray-900 p-5 rounded-md w-full">
                <h3 className="text-lg font-semibold mb-2 font-sans">Solicitações de assessoria para esta empresa</h3>
                <small className="block mb-4 font-sans text-gray-600 dark:text-gray-300">Analise cuidadosamente a solicitação, após aceitá-la todos os colaboradores da empresa solicitante terão acesso total aos dados da sua empresa.</small>
    
                <div className="flex flex-row justify-end pb-4 bg-white dark:bg-gray-900">
                    <div className='pr-6 flex flex-row gap-5 items-center justify-center'>
                        <label onClick={(e) => handleConfigSolicitação(e, StatusSOlicitação.ACEITA)} className={seleção.length > 0 ? `${activeButton}` : `${inactiveButton}`}> <span className='text-green-500'><BsCheck className='text-3xl' /></span> Aceitar </label>
                        <label onClick={(e) => handleConfigSolicitação(e, StatusSOlicitação.NEGADA)} className={seleção.length > 0 ? `${activeButton}` : `${inactiveButton}`}> <span className='text-red-500'><BsX className='text-3xl' /> </span> Negar </label>
                    </div>
                </div>
    
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-sans">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Nome da empresa
                            </th>
                            <th scope="col" className="px-6 py-3">
                                CNPJ da empresa
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Tipo de acesso
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Contato
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Data
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            solicitações.map((solicitação: any) => (
                                <tr key={solicitação.EmpresaSolicitação} id={`mapid-${solicitação.EmpresaSolicitação}`} className={seleção.includes(solicitação.EmpresaSolicitação) ? `${selectedRow}` : `${idleRow}`}>
                                    <td className="w-4 p-4">
                                        <div className="flex items-center">
                                            <input type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" id={solicitação.EmpresaSolicitação} onChange={handleSolicitação} />
                                            <label htmlFor="checkbox-table-search-2" className="sr-only">checkbox</label>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4">
                                        {solicitação.NomeEmpresa}
                                    </td>
                                    <td className="px-6 py-4">
                                        {solicitação.CnpjEmpresa}
                                    </td>
                                    <td className="px-6 py-4">
                                        {mapTipoSolicitação(solicitação.TipoSolicitação)}
                                    </td>
                                    <td className="px-6 py-4 flex flex-col">
                                        <span>{solicitação.Email}</span>
                                        <span>{solicitação.Fone}</span>
                                    </td>
                                    <td className="px-6 py-4">
                                        {formatarData(solicitação.DataSolicitação)}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg bg-white dark:bg-gray-900 p-5 rounded-md w-full">
                <h3 className="text-lg font-semibold mb-2 font-sans">Assessorias desta empresa</h3>
                <small className="block mb-4 font-sans text-gray-600 dark:text-gray-300">Estas são as empresas que atualemnte assessoram a sua empresa, ao selecionar a opção de "Revogar assesso" a empresa selecionada não terá mais acesso a esta empresa.</small>
 
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-sans">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                           
                            <th scope="col" className="px-6 py-3">
                                Nome da empresa
                            </th>
                            <th scope="col" className="px-6 py-3">
                                CNPJ da empresa
                            </th>
    
                            <th scope="col" className="px-6 py-3">
                                Contato
                            </th>
                            <th scope="col" className="px-6 py-3">
                               Opções
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            assessoriasData ? assessoriasData.assessorias.map((empresa: any) => (
                                <tr key={empresa.CnpjEmpresa} id={`id-${empresa._id}`} className={`${idleRow}`}>
                                   
                                    <td className="px-6 py-4">
                                        {empresa.NomeEmpresa}
                                    </td>
                                    <td className="px-6 py-4">
                                        {empresa.CnpjEmpresa}
                                    </td>
                             
                                    <td className="px-6 py-4 flex flex-col">
                                        <span>{empresa.Email}</span>
                                        <span>{empresa.Fone}</span>
                                    </td>
                                    <td className="px-6 py-4">
                                        <label className='font-semibold opacity-80 hover:opacity-100 cursor-pointer' onClick={() => handleRevogarAssesso(empresa._id)}> Revogar acesso </label>
                                    </td>
                                </tr>
                            )):
                            <></>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}    

export default AssessoriaSolicitações;
