
export enum AdminTab{

    SOLICITAÇÕES = 'solicitações',
    FUNCIONÁRIOS = 'funcionários',
    EMPRESAS = 'empresas',
    IDLE = 'idle',
    REJEIÇÕES = 'rejeições',
    CRIAREMPRESA ='criarempresa',
    ASSESSORIA ='assessoria'
}

export type typePropsSolicitações={
    token: string,
    empresaid: string
}

export enum StatusSOlicitação {
    ACEITA = 'Aceita',
    NEGADA = 'Negada'

}

export type typeSolicitaçãoData={
    AcessoSolicitação?: string,
    AcessoUserId?     : string,
    DataSolicitação?  : string,
    TipoSolicitação?  : string
}
export type typeAssessoriaData={
    TipoSolicitação: 'editar' | 'visualizar', 
    NomeEmpresa: string,
    CnpjEmpresa: string,
    EmpresaSolicitação: string,
    Email?: string,
    Fone?: string
}

export type typeResolverSolicitação ={
    token?: string
    empresaid: string,
    StatusSolicitação: string,
    data : [typeSolicitaçãoData] | [typeAssessoriaData]
   
}