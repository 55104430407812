import React, { useEffect, useState } from 'react'
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas'
import { useGetAcessos } from '../../Utils/ReactQuery/getAcessos'
import { IMaskInput } from 'react-imask'
import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { useUserContext } from '../../Context/useUserContext'



const AdminAcessoria = () => {

    const { buscarEmpresa, enviarSolicitação, assessoriaSolicitação } = useGetAcessos();
    const token = localStorage.getItem('token');



    const [cnpj, setCnpj] = useState<string>('');
    const [cnpjDaBusca, setCnpjDaBusca] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false);
    const [solEnviada, setSolEnviada] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('')

    const { data, isLoading, isFetching } = useQuery({
        queryFn: () => buscarEmpresa({ cnpj: cnpj, token: token as string }),
        queryKey: ["rqBuscarEmpresa", cnpjDaBusca]
    });



    const enviarSolicitaçãoMutation = useMutation({
        mutationFn: assessoriaSolicitação,
        onMutate: async (config) => {
            // console.log('Mutação iniciada!');
            setLoading(true);
        },
        onSuccess: async (response) => {
            // console.log('Mutação bem sucedida!');
            if (response.status === 1) {
                setLoading(true)
                setSolEnviada(true);
            } else {
                setError(true);
                setErrorMsg(response.msg)
            }

        },
        onError: async (error) => {
            console.error(error.message);
            toast.error(error.message);
            setError(true);
            setErrorMsg(error.message)
        },
        onSettled: async (data) => {
            // console.log("Mutação concluída!")
            setLoading(false);

        }

    })




    const handleBuscarEmpresa = (e: any) => {
        e.preventDefault();
        setCnpjDaBusca(cnpj);
    }

    const handleEnvioSolicitação = (empresa: any) => {

        enviarSolicitaçãoMutation.mutate({ empresaid: empresa._id as string, token: token as string, cnpj: empresa.CnpjEmpresa as string })
    }


    return (
        <div className="w-full p-4 flex justify-center mt-10">
            <div className="w-full max-w-3xl bg-white rounded-xl shadow-md p-6 flex flex-col gap-8 items-center">
                <h3 className="text-3xl font-semibold text-center">Solicitar acesso</h3>
                <div className="w-full flex flex-col items-center gap-8">
                    
                    <form className="w-full max-w-md flex flex-col items-center">
                        <label htmlFor="" className="text-center mb-2">Informe o CNPJ da empresa para solicitar o acesso.</label>  
                        
                        <div className="w-full mt-2 flex flex-col gap-4 items-center">
                            <IMaskInput 
                                mask="00.000.000/0000-00" 
                                type="text" 
                                name="CnpjEmpresa" 
                                id="floating_cnpjEmpresa" 
                                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500" 
                                placeholder="Buscar empresa"  
                                required 
                                onAccept={(value, mask)=> setCnpj(value)} 
                            />
                            <button 
                                onClick={handleBuscarEmpresa} 
                                className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                            >
                                Buscar
                            </button>
                        </div>
                    </form>
    
                    <div className="w-full max-w-md">
                        {
                            data && data.empresa ?
                            <div className='flex flex-col gap-4 bg-gray-100 shadow-lg dark:bg-gray-600 rounded-md text-xl p-4 items-center'>
                                <h3 className='text-center'>Informações da empresa</h3>
                                <div className='flex flex-col items-center'>
                                    <div className='w-16 h-16 rounded-full bg-green-500 text-white flex items-center justify-center text-2xl'>
                                        {
                                            data.empresa.LogoEmpresa ?
                                            <img className='w-16 h-16 rounded-full object-cover' src={data.empresa.LogoEmpresa} alt="Empresa" />
                                            :
                                            <span>{data.empresa.NomeEmpresa.slice(0, 2)}</span>
                                        }
                                    </div>
                                    <div className='flex flex-col items-center mt-4'>
                                        <label>Nome: {data.empresa.NomeEmpresa}</label>
                                        <label>CNPJ: {data.empresa.CnpjEmpresa}</label>
                                    </div>
                                </div>
                                {
                                    solEnviada ?
                                    <div className='font-bold text-center'>
                                        Solicitação enviada com sucesso, aguarde a resposta.
                                    </div>
                                    :
                                    error ?
                                    <div className='flex flex-col gap-2 text-center'>
                                        A solicitação não foi enviada!
                                        <label className='text-red-500'>{errorMsg}</label>
                                        <button 
                                            onClick={() => setError(false)} 
                                            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                                        >
                                            Tentar novamente
                                        </button>
                                    </div>
                                    :
                                    <button 
                                        onClick={() => handleEnvioSolicitação(data.empresa)} 
                                        className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                                        disabled={loading}
                                    >
                                        {loading ? `Aguarde ...` : 'Enviar solicitação'}
                                    </button>
                                }
                            </div>
                            :
                            data && data.status === 3 && cnpj ?
                            <div className='text-center'>
                                <label>Empresa não encontrada, por favor verifique o CNPJ informado!</label>
                            </div>
                            :
                            null
                        }
                    </div>
    
                </div>
            </div>
        </div>
    );    
}

    export default AdminAcessoria