import React, { useEffect, useLayoutEffect, useState } from 'react'
import CaptacaoFiltro from './CaptacaoFiltro'
import CaptacaoDisplay from './CaptacaoDisplay'
import NovoProcesso from '../ProcessosComponents/NovoProcesso'
import { BsPencil, BsQuestionCircle } from 'react-icons/bs'
import { useUserContext } from '../../Context/useUserContext'
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import LoadingPage from '../LoadingPage'
import { useInView } from 'react-intersection-observer'
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas'
import { toast } from 'react-toastify'
import CaptaçãoAcessoAviso from './CaptaçãoAcessoAviso'
import LoadingSmall from '../LoadingSmall'

const Captacao = () => {
  const [captacaoManual, setCaptacaoManual] = useState<boolean>(false);
  const [pagina, setPagina] = useState<number>(10);
  const { ref, inView } = useInView();

  const token = localStorage.getItem('token');
  const empresaSelecionada = localStorage.getItem('empresaSelecionada');
  const { getArmazenamentoEmpresa, alterarEmpresa, selecionarEmpresa } = useReactQueryEmpresas()

  const { data: armazenamentoData, isLoading } = useQuery({
    queryFn: () => getArmazenamentoEmpresa(token as string, empresaSelecionada as string),
    queryKey: ['rqArmazenamentoEmpresa'],
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (inView) {
      setPagina(pagina + 10);
    }
  }, [inView]);

  const fechar = () => {
    setCaptacaoManual(false);
  }

  const [userContext] = useUserContext();
  const cnaePrincipal = localStorage.getItem('cnaePrincipal');
  const [filtroAtual, setFiltroAtual] = useState<string>(encodeURI(cnaePrincipal as string) || '');

  const [salvarFiltro, setSalvarFiltro] = useState<boolean>(false)

  const { getCaptacaoAutomatica } = useReactQueryCaptacao();
  const { data: captacaoData, isLoading: captacaoIsLoading, refetch } = useQuery({
    queryFn: () => getCaptacaoAutomatica({ descrição: filtroAtual as string, pagina: 2000 }),
    queryKey: ["rqCaptacao", filtroAtual, pagina],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10
  });

  const {data: filtroData, isLoading: filtroisLoading} = useQuery({queryFn: ()=> selecionarEmpresa({empresaid: empresaSelecionada as string, token: token as string}), queryKey: ['rqFiltroCaptacao']}); 

  if(filtroData){
    console.log('Filtro data:', filtroData)
  }

  useLayoutEffect(()=>{

    const filtroEmpresa = localStorage.getItem(`${empresaSelecionada}-filtro`);

    if(filtroEmpresa){
      setFiltroAtual(filtroEmpresa)
    }else{
      setFiltroAtual(cnaePrincipal as string)
    }
    

  },[]);


  // // //~MANUUTENÇÃO~~//
  // useEffect(()=>{
  //   console.log('Filtro atual:    ', filtroAtual)
  // },[filtroAtual])
  // // //~MANUUTENÇÃO~~//


  // Mutação ----------------------
 //---------------------------   
    // Alterar filtro mutação
    const queryClent = useQueryClient();
    const alterarFiltroMutaton = useMutation({
        mutationFn: getCaptacaoAutomatica,
        onMutate: (config)=>{

        },
        onSuccess: async(response)=>{
          console.log('Response da atualizaçãoo do filtro: ', response);
          queryClent.invalidateQueries({queryKey: ['rqCaptacao']});
          queryClent.refetchQueries({queryKey: ['rqCaptacao']});
        },
        onError: (error)=>{
            console.error(error.message)
        },
        onSettled: (data)=>{

        }
    })


    //---------------------------
  // Mutação ----------------------

  const alterarFiltro = (filtro: string, save: false) => {

    console.log('Save: ', save)
    //alterarFiltroMutaton.mutate({descrição: filtro, pagina: 1})


    if(save){
      //localStorage.setItem('filtroSalvo', filtro)


    localStorage.setItem(`${empresaSelecionada}-filtro`, filtro)
    }
    

    if (filtro && filtro.length > 1) {
      setFiltroAtual(filtro);
    } else {
      setFiltroAtual(cnaePrincipal as string);
    }
  }

  //------------------------------
  // Carregar filtro customizado da empresa





  //----------------------------

  // Estilizações Tailwind ajustadas
  const activePage = 'w-full min-h-screen grid grid-cols-[300px_minmax(400px,_1fr)] gap-6';
  const inactivePage = 'w-full min-h-screen grid grid-cols-[300px_minmax(400px,_1fr)] gap-6 blur-sm';

  if(armazenamentoData && armazenamentoData.planoDescrição ==="inativo"){
    return(
     <CaptaçãoAcessoAviso/>
    )
  }
  if(armazenamentoData && armazenamentoData.planoDescrição ==="visitante"){
    return(
      <CaptaçãoAcessoAviso/>

    )
  }

  return (
    <div className='w-full min-h-screen mt-2 bg-white rounded-lg shadow-lg'>
      {captacaoManual && <NovoProcesso fechar={fechar} />}
      
      <div className='w-full flex flex-col pl-4 pr-4 mb-1'>
        <div className='flex flex-col'>
          <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>Captação automática</h3>
          <p className='text-base text-gray-700 dark:text-gray-400'>Processos encontrados no PNCP de acordo com a atividade econômica primária da empresa (CNAE).</p>
        </div>
      </div>
  
      <div className={captacaoManual ? inactivePage : activePage}>
        <div>
        <div className='flex flex-col items-start ml-10 justify-center '>
          <label
            className="hover:opacity-100 cursor-pointer inline-flex items-center text-sm text-white bg-[#ff8027] hover:bg-orange-700 rounded-lg px-4 py-2 transition-colors duration-200"
            onClick={() => setCaptacaoManual(true)}
          >
            <BsPencil className="mr-2" />
            Registro Manual
          </label>
        </div>
          <CaptacaoFiltro alterarFiltro={alterarFiltro} />
        </div>


        <div className='max-h-screen overflow-y-auto flex flex-col chart'>

          {captacaoData && !isLoading ? <CaptacaoDisplay editais={captacaoData} /> : <div  className="text-center text-sm text-gray-500 h-60">Carregando mais processos...</div>}
          {
            captacaoData && captacaoData.items.length <= 1  &&
            <h3> Nenhum edital encontado na busca tlizando a atvdade econômca primária da empresa, utlize o fltro para uma busca mas precisa.</h3>
          }
          {/* <div  className="text-center text-sm text-gray-500 h-60">Carregando mais processos...</div> */}
        </div>

      </div>
    </div>
  );
}
export default Captacao;
