import React, { useEffect } from 'react'
import { AdminTab } from '../../Utils/types/adminTypes'
import LoadingPage from '../LoadingPage'
import CriarEmpresa from './CriarEmpresa'
import Funcionarios from './Funcionarios'
import Rejeições from './Rejeições'
import Solicitações from './Solicitações'
import AdminAcessoria from './AdminAcessoria'
import AssessoriaSolicitações from './AssessoriaSolicitações'
import { useUserContext } from '../../Context/useUserContext'


const AdminCenter = ({activeTab, solicitações, empresaId, usuarios, empresaData}: any) => {


  const [userContext] = useUserContext();

  // //~~MANUTENÇÃO~~//
  // useEffect(()=>{
  //     console.log('Context do usuario: ', userContext)
  // },[userContext])
  
  // //~~MANUTENÇÃO~~//


  return (
    <div className='border-1 border-black border-solid min-h-dvh w-full m-5 mt-5 rounded-lg dark:bg-gray-800  min-w-fit mr-12'>

      {
        activeTab === AdminTab.SOLICITAÇÕES && 
        <div> 
          {
            solicitações ?
          <Solicitações solicitações = {solicitações} empresaId = {empresaId}/>
          :
          <LoadingPage/>
          } 
          </div>
      }
      {
        activeTab === AdminTab.FUNCIONÁRIOS &&
        <div> <Funcionarios usuarios = {usuarios} empresaData ={empresaData && empresaData}/> </div>
      }
      {
        activeTab === AdminTab.REJEIÇÕES &&
        <div> <Rejeições solicitações = {solicitações} /> </div>

      }

      {
        activeTab === AdminTab.CRIAREMPRESA &&
        <div> <CriarEmpresa/> </div>
      }

      {
        activeTab === AdminTab.ASSESSORIA && <div> 
          {
            userContext && userContext.User.AcessoAtual === "Assessoria" ?
            <AdminAcessoria/>
            :
            <div className="flex justify-center w-full">
            <AssessoriaSolicitações solicitações = {solicitações} empresaId = {empresaId}/>
            </div>
          }
            

            
        </div>
      }
       

    </div>
  )
}

export default AdminCenter