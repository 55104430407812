import React, { useEffect, useState } from 'react'
import { getCnpjData } from '../../Utils/cnpjAutocomplete';
import { BsArrowLeft, BsArrowRight, BsCheck, BsPlus, BsX } from 'react-icons/bs';
import LoadingPage from '../LoadingPage';
import AlterarAvatar from '../PerfilComponents/AlterarAvatar';



const NovoProcessoEtapaUm = ({etapaUmData, cancelar, editar, processo, alterarParteEditada, dadosPncp }: any) => {


    // //~~ MANUTENÇÃO~~//

    // //~~ MANUTENÇÃO~~//


    const [cnpj, setCnpj] = useState<string>('');

    const [editarLogo, setEditarLogo] = useState<boolean>(false)

    const [orgaoPublico, setOrgaoPublico] = useState<string>('');
    const [endereço    , setEndereço] = useState<string>('');
    const [cidadeUf    , setCidadeUf] = useState<string>('');
    const [cep         , setCep] = useState<string>('');
    const [fone        , setFone] = useState<string>('');
    const [email       , setEmail] = useState<string>('');
    const [site        , setSite] = useState<string>('');
    const [file, setFile] = useState<any>('');
    const [fileInput, setFileInput] = useState<any>('')

    const [loadingAutocomplete, setLoadingAutoComplete] = useState<boolean>(false);
    const [cnpjData, setCnpjData] = useState<any>([])


    const handleArquivo = (e: any)=>{
        setFile(URL.createObjectURL(e.target.files[0]));
        setFileInput(e.target.files[0])
    }

    const verifyCnpj = async()=>{
    
        // if(cnpj.length < 13){
        //   setLoadingAutoComplete(false);
        //   return;
        // }
    
        setLoadingAutoComplete(true);  
      
          
          const res = await getCnpjData(cnpj as string);

          if(res === null ){
            setLoadingAutoComplete(false);  
            return;
          }
    
          setCnpjData(res);
          setOrgaoPublico(res.nome_fantasia)
          setEndereço(res.logradouro); 
          setCidadeUf(`${res.municipio}/${res.uf}`)
          setCep(res.cep );
          setFone(res.ddd_telefone_1 ); 

    
          setLoadingAutoComplete(false)

    
          return;
   
        
        
    }



    useEffect(()=>{
        if(dadosPncp)
        {
            setCnpj(dadosPncp.item.orgao_cnpj)
            verifyCnpj()
        }
    },[dadosPncp, cnpj])


    if(loadingAutocomplete){
        return(
        <div id="defaultModal" tabIndex={-1} aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">
            
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
                
                <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                    <LoadingPage/>
                </div>
            </div>
        </div>
        )
    }

    //Tailwind ----------------------
    const inputField = '"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"';
    const labelField ="block mb-2 text-sm font-medium text-gray-900 dark:text-white";

    const button = "cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit"
    const closeButton = "bg-red-500 cursor-pointer text-white inline-flex items-center bg-bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:bg-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 h-fit"
    
    //Tailwind ----------------------

    const handleEtapa =(e: any)=>{
        e.preventDefault();

        const formData = new FormData(e.currentTarget);

       if(dadosPncp){
        const primeiraEtapa ={
            CnpjLicitante         : formData.get("CnpjLicitante"),
            orgaopublicoLicitante : formData.get("orgaopublicoLicitante"),
            enderecoLicitante     : formData.get("enderecoLicitante"),
            cidadeLicitante       : formData.get("cidadeLicitante"  ),
            cepLicitante          : formData.get("cepLicitante"     ),
            foneLicitante         : formData.get("foneLicitante"    ),
            emailLicitante        : formData.get("emailLicitante"   ),
            siteLicitante         : formData.get("siteLicitante"    ),
            file: fileInput,
            captacaoPncp: true,
            LinkArquivosPncp: dadosPncp.LinkArquivos,
            LinkItensPncp: dadosPncp.LinkItens
        }

        etapaUmData(e, primeiraEtapa)
        return;
       }

        const primeiraEtapa ={
            CnpjLicitante         : formData.get("CnpjLicitante"),
            orgaopublicoLicitante : formData.get("orgaopublicoLicitante"),
            enderecoLicitante     : formData.get("enderecoLicitante"),
            cidadeLicitante       : formData.get("cidadeLicitante"  ),
            cepLicitante          : formData.get("cepLicitante"     ),
            foneLicitante         : formData.get("foneLicitante"    ),
            emailLicitante        : formData.get("emailLicitante"   ),
            siteLicitante         : formData.get("siteLicitante"    ),
            file: fileInput,
            
            
        }

        etapaUmData(e, primeiraEtapa)

    }

    const fecharEditarLogo =(e: any)=>{
        e.preventDefault();
        setEditarLogo(false);
    }


    if(editarLogo){
        return(
            <AlterarAvatar fechar ={fecharEditarLogo} processo ={processo}/> 
        )
    }

  return (
    <div>
        

        <div id="defaultModal" tabIndex={-1} aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">


            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
                
                <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                

                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                

                <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                        {editar ?`Editar processo` :`Registrar processo`}
                    </h3>
                    <label> Informações do orgão licitante</label>
                </div>

                {
                    editar && 
                    <div className='flex flex-row gap-2'>                 
                        <label onClick={()=> alterarParteEditada('forward')}>
                        <BsArrowRight className='text-3xl cursor-pointer'/> Passar 
                        </label>
                    </div>
                }

               </div>

                    <form onSubmit={handleEtapa}>
                        <div className='mb-2'>

                            <label htmlFor="siteLicitante" className={`${labelField}`}>
                                Logo do orgão licitante
                            </label>

                           {!editar ?
                            <>
                                <div className='w-12 h-12 rounded-full object-contain origin-center'>
                                    <img src={file} alt="Arquivo" className='w-12 h-12 rounded-full' />
                                </div>
                                
                                <input type="file" name="file" accept='image/png, image/jpeg' onChange={handleArquivo}/>
                                <br />
                                <small> Tamanho máximo de arquivo jpg ou png é de 25mb</small>
                            </>
                            :
                                <>
                                <div className='w-12 h-12 rounded-full object-contain origin-center border-2 border-dotted dark:border-white border-black flex items-center justify-center cursor-pointer' onClick={()=> setEditarLogo(current => !current)}>
                                   <BsPlus className='text-3xl'/>
                                </div>
                                <label className='text-xl font-semibold opacity-80 hover:opacity-100 cursor-pointer' onClick={()=> setEditarLogo(current => !current)}> Alterar logo licicitante</label>
                                </>
                            }

                            
                        </div>

                        <div className="grid gap-4 mb-4 sm:grid-cols-2">
                            
                            <div>
                                <label htmlFor="CnpjLicitante" className={`${labelField}`}>CNPJ</label>
                                <input type="text" name="CnpjLicitante" id="CnpjLicitante" className={`${inputField}`} placeholder={editar ? processo.CnpjLicitante : "CNPJ"} required={editar ? false : true} value={cnpj} onChange={(e)=> setCnpj(e.target.value)}/>

                                <label className='opacity-80 hover:opacity-100 cursor-pointer ' onClick={verifyCnpj}> Buscar dados </label>

                            </div>
                            

                            <div>
                                <label htmlFor="orgaopublicoLicitante" className={`${labelField}`}>Orgão público</label>
                                <input type="text" name="orgaopublicoLicitante" id="orgaopublicoLicitante" className={`${inputField}`} placeholder={editar ? processo.orgaopublicoLicitante : " Orgão público Licitante"} required={editar ? false : true} value={orgaoPublico} onChange={(e=> setOrgaoPublico(e.target.value))}/>
                            </div>

                            <div>
                                <label htmlFor="enderecoLicitante" className={`${labelField}`}>Endereço</label>
                                <input type="text" name="enderecoLicitante" id="enderecoLicitante" className={`${inputField}`} placeholder={editar ? processo.enderecoLicitante : "Endereco Licitante"} required={editar ? false : true} value={endereço} onChange={(e=> setEndereço(e.target.value))}/>
                            </div>

                            <div>
                                <label htmlFor="cidadeLicitante" className={`${labelField}`}>Cidade / UF *</label>
                                <input type="text" name="cidadeLicitante" id="cidadeLicitante" className={`${inputField}`} placeholder={editar ? processo.cidadeLicitante : "Cidade Licitante"} required={editar ? false : true} value={cidadeUf} onChange={(e=> setCidadeUf(e.target.value))}/>
                            </div>
                            
                            <div>
                                <label htmlFor="cepLicitante" className={`${labelField}`}>CEP *</label>
                                <input type="text" name="cepLicitante" id="cepLicitante" className={`${inputField}`} placeholder={editar ? processo.cepLicitante: "CEP"} required={editar ? false : true} value={cep} onChange={(e=> setCep(e.target.value))}/>
                            </div>

                            <div>
                                <label htmlFor="foneLicitante" className={`${labelField}`}>Telefone *</label>
                                <input type="text" name="foneLicitante" id="foneLicitante" className={`${inputField}`} placeholder={editar ? processo.foneLicitante : "Telefone"} required={editar ? false : true} value={fone} onChange={(e=> setFone(e.target.value))}/>
                            </div>
                            
                            <div>
                                <label htmlFor="emailLicitante" className={`${labelField}`}>Email *</label>
                                <input type="text" name="emailLicitante" id="emailLicitante" className={`${inputField}`} placeholder={editar ? processo.emailLicitante : "Email"} required={editar ? false : true} value={email} onChange={(e=> setEmail(e.target.value))}/>
                            </div>
                            
                            <div>
                                <label htmlFor="siteLicitante" className={`${labelField}`}>Site *</label>
                                <input type="text" name="siteLicitante" id="siteLicitante" className={`${inputField}`} placeholder={editar ? processo.siteLicitante : "Site"} required={editar ? false : true} value={site} onChange={(e=> setSite(e.target.value))}/>
                            </div>

                             
                        </div>

                        <div className='flex flex-row w-full justify-between'>
    
                            <button type='submit' className={`${button}`}> Salvar informações <BsArrowRight className='text-2xl ml-2'/></button>                       
                            <label className={`${closeButton}`} onClick={cancelar}> Cancelar <BsX className='text-2xl ml-2'/></label>
                           
                        </div>

                    </form>
                </div>
            </div>
        </div>



    </div>
  )
}

export default NovoProcessoEtapaUm