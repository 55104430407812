import React from 'react'
import { BsPencil, BsTrash, BsX } from 'react-icons/bs'
import { formatarData } from '../../Utils/formatarData'

type Props = {}

const DashboardPastasModal = ({pasta, fechar}: any) => {
  return (

        <div id="default-modal"  aria-hidden="true" className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" >

            <div className="relative p-4 w-full max-w-2xl max-h-full">


                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 ">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Arquivos da pasta
                        </h3>
                    <div onClick={fechar}>
                        <span className='text-3xl cursor-pointer'><BsX/></span>
                    </div>
                </div>

                    <div className="relative overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Nome do arquivo
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Descrição
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        ultima modificação
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Criado por
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Opções
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                            
                                {
                                    pasta && pasta.Arquivos.map((arquivo: any)=>(
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900
                                    underline underline-offset-2 whitespace-nowrap dark:text-white">
                                        <span className='cursor-pointer w-fit'> {arquivo.NomeArquivo}</span> 
                                    </th>
                                    <td className="px-6 py-4">
                                        {arquivo.Descrição}
                                    </td>
                                    <td className="px-6 py-4">
                                        {formatarData(arquivo.UltimaModificação)}
                                    </td>
                                    <td className="px-6 py-4">
                                        {arquivo.CriadoPor}
                                    </td>
                                    <td className="px-6 py-4 inline-flex text-black dark:text-gray-300  gap-4">
                                        <BsPencil className='cursor-pointer opacity-80 hover:opacity-100' /> 
                                        <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100'/>
                                    </td>
                                </tr>
                                    ))
                                }
                                
                            </tbody>
                        </table>
                    </div>




                </div>
            </div>
        </div>
  )
}

export default DashboardPastasModal