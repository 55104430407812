import React, { useEffect, useState } from 'react'
import NovoProcessoEtapaUm from './NovoProcessoEtapaUm'
import { RegistrarProcesso } from '../../Utils/types/PortalTypes';
import NovoProcessoEtapaDois from './NovoProcessoEtapaDois';
import NovoProcessoEtapaTres from './NovoProcessoEtapaTres';
import NovoProcessoEtapaQuatro from './NovoProcessoEtapaQuatro';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { toast } from 'react-toastify';

const EditarProcesso = ({processo, fechar, busca}: any) => {

    console.log('E ESTE COMPONENTE------------')

    const queryClient = useQueryClient();

    const [etapaEditada, setEtapaEditada] = useState<RegistrarProcesso>(RegistrarProcesso.INFOLICITANTE);
    const [processoData, setProcessoData] = useState<any>('');

    const {registrarProcesso, editarProcessos} = useReactQueryProcessos();
    const token =localStorage.getItem('token');
    const empresaSelecionada = localStorage.getItem('empresaSelecionada');

    const [loading, setLoading] = useState<boolean>(false);

    const editarProcessoMutation = useMutation({
        mutationFn: editarProcessos,
        onMutate: async(config)=>{
            console.log('Mutação inicida!');
            setLoading(true)
        },
        onSuccess: async(response)=>{
            // console.log('Mutação bem sucedida!', response);

            queryClient.invalidateQueries({queryKey: ['rqProcessos']})
            queryClient.fetchQuery({queryKey: ['rqProcessos']})
            queryClient.refetchQueries({queryKey: ['rqProcessos']})
            
            queryClient.invalidateQueries({queryKey: ['rqPrcessosInfo']})
            queryClient.fetchQuery({queryKey:['rqPrcessosInfo']})
            queryClient.refetchQueries({queryKey:['rqPrcessosInfo']})


            if(response.status === 1){
                toast.success(response.msg);
                fechar()
            }
            else{
                toast.error(response.msg);
            }
        },
        onError: async(error, variables, context)=> {
            console.error(error.message);
        
            
        },
        onSettled: async(data)=>{
            // console.log('Mutação concluída!');
            setLoading(false)
            
        }
    })

    

    const alterarParteEditada =(action: any)=>{

        switch(true){
            case etapaEditada === RegistrarProcesso.INFOPROCESSO && action ==='back':
                setEtapaEditada(RegistrarProcesso.INFOLICITANTE);
            return;
            break;
            case etapaEditada === RegistrarProcesso.INFODATAS && action === 'back':
                setEtapaEditada(RegistrarProcesso.INFOPROCESSO);
            return;
            break;
            case etapaEditada === RegistrarProcesso.INFODATAS && action === 'forward':
                setEtapaEditada(RegistrarProcesso.CONTATOS);
            return;
            break;
            case etapaEditada === RegistrarProcesso.INFOLICITANTE && action === 'forward':
                setEtapaEditada(RegistrarProcesso.INFOPROCESSO);
            return
            
            break;
            case etapaEditada === RegistrarProcesso.INFOPROCESSO && action === 'forward':
                setEtapaEditada(RegistrarProcesso.INFODATAS);
            break;
            
            break;
            case etapaEditada === RegistrarProcesso.CONTATOS && action === 'back':
                setEtapaEditada(RegistrarProcesso.INFODATAS);
                return;
        }


    }

    const handleEtapa =(e: any, etapaData: any, contato: any, empresaId: any, usuarioSelecionado: any, portalSelecionado: string)=>{
        e.preventDefault();
     
        setProcessoData(etapaData);
        const formData = new FormData();
        formData.append('file', processoData.file);
        
        editarProcessoMutation.mutate({
            token: token as string, 
            data: etapaData,
            Arquivo: formData,
            contatos: contato,
            processoid: processo._id,
            empresaSelecionada: empresaId,
            usuarioSelecionado: usuarioSelecionado,
            portalSelecionado: portalSelecionado

        })
        
    }

    

  return (
    <div>
        <div>

            {
                etapaEditada === RegistrarProcesso.INFOLICITANTE &&
                <NovoProcessoEtapaUm processo ={processo} cancelar ={fechar} editar={true} alterarParteEditada ={alterarParteEditada} etapaUmData ={handleEtapa} />
            }
            {
                etapaEditada === RegistrarProcesso.INFOPROCESSO &&
                <NovoProcessoEtapaDois processo ={processo} cancelar ={fechar} editar={true} alterarParteEditada ={alterarParteEditada} etapaDoisData ={handleEtapa} />
            }
            {
                etapaEditada === RegistrarProcesso.INFODATAS &&
                <NovoProcessoEtapaTres processo ={processo} cancelar ={fechar} editar={true} alterarParteEditada =   {alterarParteEditada} etapaTresData ={handleEtapa} />
            }
            {
                etapaEditada === RegistrarProcesso.CONTATOS &&
                <NovoProcessoEtapaQuatro processo ={processo} cancelar ={fechar} editar={true} alterarParteEditada = {alterarParteEditada} etapaQuatroData={handleEtapa} />
            }
           

  
        </div>
    </div>
  )
}

export default EditarProcesso