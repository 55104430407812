import React, { useState, useEffect, useRef } from 'react';
import { BsDiagram3Fill } from 'react-icons/bs';
import { RiAdminFill } from 'react-icons/ri';
import { useQuery } from '@tanstack/react-query';
import { useUserContext } from '../../Context/useUserContext';
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import NotificaçõesComponent from '../Notificações/NotificaçõesComponent';
import { Link } from 'react-router-dom';

interface SideBarProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isHovering: boolean;
  setIsHovering: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideBar: React.FC<SideBarProps> = ({ isOpen, setIsOpen, isHovering, setIsHovering }) => {
  const [isDropdownOpenUser, setIsDropdownOpenUser] = useState(false);
  const [isDropdownOpenLicit, setIsDropdownOpenLicit] = useState(false);
  const [isDropdownOpenConfig, setIsDropdownOpenConfig] = useState(false);
  const [isDropdownOpenAdmin, setIsDropdownOpenAdmin] = useState(false);

  const [userContext] = useUserContext();

  //-------------------------
  // useEffect(()=>{
  //   console.log('seer context: ', userContext)
  // },[userContext])


  //-------------------------

  const { getUser } = useReactQueryUsers();
  const token = localStorage.getItem("token");

  const { data: userData, isLoading } = useQuery({
    queryFn: () => getUser(token as string),
    queryKey: ['userInfo', token],
    staleTime: Infinity
  });


  useEffect(() => {
    if (!isOpen && !isHovering) {
      setIsDropdownOpenLicit(false);
      setIsDropdownOpenConfig(false);
      setIsDropdownOpenAdmin(false);
    }
  }, [isOpen, isHovering]);

  const user = userData ? userData.user : null;

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpenUser(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const queryClient = useQueryClient();
  const { logOut } = useReactQueryUsers()

  const logOutMutation = useMutation({
    mutationFn: logOut,
    onMutate: (config: any): any => {
      // console.log('Mutação inicida!');
      queryClient.invalidateQueries({ queryKey: ['rqUser'] });
      queryClient.invalidateQueries({ queryKey: ['rqEmpresa'] })
      queryClient.clear();

    },
    onSuccess: async (response) => {
      // console.log('Mutação bem sucedida!', response);
      localStorage.removeItem('token')
      localStorage.removeItem('empresaSelecionada')

    },
    onError: async (err) => {

    },
    onSettled: (response) => {

      window.location.href = '/'

    }
  })

  const handleLogOut = (e: any) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    logOutMutation.mutate({ config: { token: token as string } });
    return;
  }


  return (
    <section>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Abrir Menu</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
              </button>
              <Link to="/" className="flex ms-2 md:me-24">
                <img src='https://s3.sa-east-1.amazonaws.com/portalglc1.0/res/logoglc1.png' className="h-8 me-3" alt="Logo GLC" />
              </Link>
            </div>
            <div className="relative flex flex-row mr-2 gap-2 items-center">
              <div className="flex items-center ms-3">
                <div>
                  <button
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                    aria-expanded={isDropdownOpenUser}
                    onClick={() => setIsDropdownOpenUser(!isDropdownOpenUser)}
                  >
                    <span className="sr-only">Abrir Menu</span>
                    {user && user.Foto ? (
                      <img
                        className="w-8 h-8 rounded-full object-cover"
                        src={user.Foto}
                        alt="user photo"
                        style={{ width: '32px', height: '32px' }}
                      />
                    ) : (
                      <div className="w-8 h-8 rounded-full bg-green-500 flex items-center justify-center">
                        <span className="text-white font-bold">
                          {user ? user.Nome.charAt(0) : ''}
                        </span>
                      </div>
                    )}
                  </button>
                </div>
                <div ref={dropdownRef} className={`absolute right-0 mt-48 w-48 bg-white rounded-md shadow-lg py-1 ${isDropdownOpenUser ? 'block' : 'hidden'}`} id="dropdown-user">
                  <div className="px-4 py-3" role="none">
                    <p className="text-sm text-gray-900 dark:text-white" role="none">
                      {user ? `${user.Nome.split(' ')[0]} ${user.Nome.split(' ').slice(-1)}` : 'Nome'}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    <li>
                      <Link to="/portal/perfil?area=user" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Meu Perfil</Link>
                    </li>
                    <li>
                      <span onClick={handleLogOut} className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Sair</span>
                    </li>
                  </ul>
                </div>
              </div>

              <NotificaçõesComponent/>
            </div>


          </div>

        </div>
      </nav>


      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 h-screen pt-20 transition-width duration-300 ${isOpen || isHovering ? 'w-64' : 'w-16'} bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700`}
        aria-label="Sidebar"
        onMouseEnter={() => !isOpen && setIsHovering(true)}
        onMouseLeave={() => !isOpen && setIsHovering(false)}
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <ul className="space-y-2 font-medium">
            <li>
              <Link to="/portal/bem-vindo" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <BsDiagram3Fill className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className={`ms-3 ${isOpen || isHovering ? 'inline' : 'hidden'}`}>Organograma</span>
              </Link>
            </li>
            <li>
              <Link to="/portal/admin?area=assessoria" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                </svg>
                <span className={`ms-3 ${isOpen || isHovering ? 'inline' : 'hidden'}`}>Assessores</span>
              </Link>
            </li>
            <li>
              <button
                type="button"
                className={`flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${!isOpen && !isHovering ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                aria-controls="dropdown-licit"
                onClick={() => (isOpen || isHovering) && setIsDropdownOpenLicit(!isDropdownOpenLicit)}
                disabled={!isOpen && !isHovering}
              >
                <svg className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white transform scale-x-[-1]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 492.508 492.508">
                  <path d="M199.493,402.145c0-10.141-8.221-18.361-18.36-18.361H42.475c-10.139,0-18.36,8.221-18.36,18.361
			c0,3.195,0.818,6.199,2.255,8.816H0v38.067h223.607v-38.067h-26.369C198.674,408.343,199.493,405.34,199.493,402.145z"/>
                  <path d="M175.898,88.224l117.157,74.396c9.111,4.643,20.43,1.678,26.021-7.129l5.622-8.85c5.938-9.354,3.171-21.75-6.182-27.69
			L204.592,46.608c-9.352-5.939-21.748-3.172-27.688,6.182l-5.622,8.851C165.692,70.447,167.82,81.952,175.898,88.224z"/>
                  <path d="M492.456,372.433l-0.082-1.771l-0.146-1.672c-0.075-1.143-0.235-2.159-0.375-3.204c-0.562-4.177-1.521-7.731-2.693-10.946
			c-2.377-6.386-5.738-11.222-9.866-14.845c-1.027-0.913-2.126-1.714-3.218-2.528l-3.271-2.443
			c-2.172-1.643-4.387-3.218-6.587-4.815c-2.196-1.606-4.419-3.169-6.644-4.729c-2.218-1.571-4.445-3.125-6.691-4.651
			c-4.468-3.089-8.983-6.101-13.51-9.103l-6.812-4.464l-6.85-4.405c-4.58-2.911-9.167-5.813-13.785-8.667
			c-4.611-2.865-9.24-5.703-13.896-8.496l-13.979-8.363l-14.072-8.22l-14.149-8.096l-14.219-7.987l-14.287-7.882l-14.354-7.773
			c-4.802-2.566-9.599-5.137-14.433-7.653c-4.822-2.529-9.641-5.071-14.498-7.548l-4.398,6.928l-22.17-10.449l24.781-39.026
			l-117.156-74.395l-60.944,95.974l117.157,74.395l24.781-39.026l18.887,15.622l-4.399,6.929c4.309,3.343,8.657,6.619,12.998,9.91
			c4.331,3.305,8.698,6.553,13.062,9.808l13.14,9.686l13.211,9.577l13.275,9.474l13.346,9.361l13.422,9.242l13.514,9.095
			c4.51,3.026,9.045,6.009,13.602,8.964c4.547,2.967,9.123,5.882,13.707,8.792l6.898,4.324l6.936,4.266
			c4.643,2.818,9.289,5.625,13.985,8.357c2.337,1.383,4.689,2.739,7.055,4.078c2.358,1.349,4.719,2.697,7.106,4
			c2.383,1.312,4.75,2.646,7.159,3.912l3.603,1.922c1.201,0.64,2.394,1.296,3.657,1.837c5.036,2.194,10.841,3.18,17.63,2.614
			c3.409-0.305,7.034-0.949,11.054-2.216c1.006-0.317,1.992-0.606,3.061-1.023l1.574-0.58l1.639-0.68
			c2.185-0.91,4.523-2.063,7.059-3.522C492.513,377.405,492.561,374.799,492.456,372.433z"/>
                  <path d="M67.897,261.877l113.922,72.341c9.354,5.938,21.75,3.172,27.689-6.181l5.621-8.852c5.592-8.808,3.462-20.311-4.615-26.583 L93.358,218.207c-9.111-4.642-20.43-1.678-26.022,7.13l-5.62,8.85C55.775,243.541,58.543,255.938,67.897,261.877z" />
                </svg>
                <span className={`ms-3 ${isOpen || isHovering ? 'inline' : 'hidden'}`}>Licitações e Contratos</span>
                <svg className={`w-3 h-3 ms-auto ${isOpen || isHovering ? 'inline' : 'hidden'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                </svg>
              </button>
              <ul id="dropdown-licit" className={`${isDropdownOpenLicit ? 'block' : 'hidden'} py-2 space-y-2`}>
                <li>
                  <Link to="/portal" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Processos</Link>
                </li>
                <li>
                  <Link to="/portal/dashboard" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Dashboard</Link>
                </li>
                {/* <li>
                  <Link to="#" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Calendário</Link>
                </li> */}

                {/* <li>
                  <Link to="#" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Alerta Licitações</Link>
                </li> */}
                {/* <li>
                  <Link to="#" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Relatórios</Link>
                </li> */}
              </ul>
            </li>
            <li>
                <button
                  type="button"
                  className={`flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${!isOpen && !isHovering ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                  aria-controls="dropdown-admin"
                  onClick={() => (isOpen || isHovering) && setIsDropdownOpenAdmin(!isDropdownOpenAdmin)}
                  disabled={!isOpen && !isHovering}
                >
                  <RiAdminFill className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                  <span className={`ms-3 ${isOpen || isHovering ? 'inline' : 'hidden'}`}>RH Estratégico</span>
                  
                  <svg className={`w-3 h-3 ms-auto ${isOpen || isHovering ? 'inline' : 'hidden'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                  </svg>
                </button>
                <ul id="dropdown-admin" className={`${isDropdownOpenAdmin ? 'block' : 'hidden'} py-2 space-y-2`}>
                  <li>
                    <Link to="/portal/admin?area=solicitações" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Solicitações</Link>
                  </li>
                  <li>
                    <Link to="/portal/admin?area=funcionários" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">RH Estratégico</Link>
                  </li>
                  <li>
                    <Link to="/portal/admin?area=rejeições" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Solicitações rejeitadas</Link>
                  </li>
                </ul>
              </li>
            <ul className="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200 dark:border-gray-700">
              <li>
                <button
                  type="button"
                  className={`flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${!isOpen && !isHovering ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                  aria-controls="dropdown-config"
                  onClick={() => (isOpen || isHovering) && setIsDropdownOpenConfig(!isDropdownOpenConfig)}
                  disabled={!isOpen && !isHovering}
                >
                  <svg className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="-1 0 19 19">
                    <path d="M16.014 8.86v1.44a.587.587 0 0 1-.468.556l-1.182.204a.463.463 0 0 1-.114.006 5.902 5.902 0 0 1-.634 1.528.455.455 0 0 1 .078.084l.691.98a.586.586 0 0 1-.062.725l-1.02 1.02a.586.586 0 0 1-.724.061l-.98-.69a.444.444 0 0 1-.085-.078 5.908 5.908 0 0 1-1.544.637.502.502 0 0 1 0 .175l-.182 1.053a.667.667 0 0 1-.633.532h-1.31a.667.667 0 0 1-.633-.532l-.182-1.053a.495.495 0 0 1 0-.175 5.908 5.908 0 0 1-1.544-.637.444.444 0 0 1-.085.077l-.98.691a.586.586 0 0 1-.725-.062l-1.02-1.02a.586.586 0 0 1-.061-.723l.691-.98a.454.454 0 0 1 .077-.085 5.901 5.901 0 0 1-.633-1.528.466.466 0 0 1-.114-.006l-1.182-.204a.586.586 0 0 1-.468-.556V8.86a.586.586 0 0 1 .468-.556L2.636 8.1a.437.437 0 0 1 .114-.005 5.912 5.912 0 0 1 .633-1.528.466.466 0 0 1-.077-.085l-.691-.98a.587.587 0 0 1 .061-.724l1.02-1.02a.587.587 0 0 1 .725-.062l.98.691a.444.444 0 0 1 .085.078 5.903 5.903 0 0 1 1.528-.634.433.433 0 0 1 .005-.114l.204-1.182a.586.586 0 0 1 .556-.468h1.442a.586.586 0 0 1 .556.468l.204 1.182a.448.448 0 0 1 .005.114 5.908 5.908 0 0 1 1.528.634.444.444 0 0 1 .085-.078l.98-.691a.586.586 0 0 1 .724.062l1.02 1.02a.586.586 0 0 1 .062.724l-.691.98a.467.467 0 0 1-.078.085 5.902 5.902 0 0 1 .634 1.528.434.434 0 0 1 .114.005l1.182.204a.587.587 0 0 1 .468.556zm-4.955.72a2.559 2.559 0 1 0-2.56 2.56 2.559 2.559 0 0 0 2.56-2.56z" />
                  </svg>
                  <span className={`ms-3 ${isOpen || isHovering ? 'inline' : 'hidden'}`}>Configurações</span>
                  <svg className={`w-3 h-3 ms-auto ${isOpen || isHovering ? 'inline' : 'hidden'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                  </svg>
                </button>
                <ul id="dropdown-config" className={`${isDropdownOpenConfig ? 'block' : 'hidden'} py-2 space-y-2`}>
                  <li>
                    <Link to="/portal/perfil?area=empresa" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Empresa</Link>
                  </li>
                  <li>
                    <Link to="/portal/perfil?area=conta" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Plano</Link>
                  </li>
            
                  <li>
                    <Link to="/portal/perfil?area=pagamento" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Pagamentos</Link>
                  </li>
                </ul>
              </li>
              
            </ul>
          </ul>
        </div>
      </aside>
    </section>
  );
};

export default SideBar;
