import React, { useEffect } from 'react'
import { BsPlus, BsX } from 'react-icons/bs'
import ErrorPage from '../../Pages/ErrorPage'
import LoadingPage from '../LoadingPage'
import styles from './Arquivos.module.css'
import DocumentoChat from '../ProcessosChat/DocumentoChat'


const ArquivoPncpModal = ({arquivo, fechar, processo}: any) => {

    console.log('Arquivo do pncp: ', arquivo)


  return (
    <div id="crud-modal" tabIndex ={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">

  

            <div className="relative p-4 w-full max-w-7xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                
                    <div className='w-full inline-flex justify-end'>
                        <span onClick={fechar} className='w-fit inline-flex justify-end cursor-pointer' > <BsX className='text-3xl mr-2 mt-2 '/> </span>
                    </div>

                    <div className={`grid grid-cols-[minmax(550px,_1fr)_auto]`}>
                        
                        
                        <div className='h-screen min-h-screen w-full'>
                            <h3> Este arqivo não esta hospeado no portal glc, portanto não será possível abrir diretamente no portal.</h3>
                            <p> É sugerido baixar e armazenar este arquivo, pois a sua disponidade é de responsabilidade externa.</p>
                        </div>

                        <div>
                            <DocumentoChat processo ={processo}/>
                        </div>
                 
                    </div>
                    
                </div>
            </div>
        </div> 
  )
}

export default ArquivoPncpModal