import React, { useState } from 'react';
import SideNav from './SideNav';

const SideNavWrapper: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  return (
    <SideNav 
      isOpen={isOpen} 
      setIsOpen={setIsOpen} 
      isHovering={isHovering} 
      setIsHovering={setIsHovering} 
    />
  );
};

export default SideNavWrapper;
