import React, { useEffect, useLayoutEffect, useState } from 'react'
import { toast } from 'react-toastify'
import PagamentoCancelado from '../Pagamentos/PagamentoCancelado';
import { useParams, useSearchParams } from 'react-router-dom';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import { useQuery } from '@tanstack/react-query';
import { BsArrowRight, BsDot, BsDownload, BsExclamation, BsGear, BsPencil } from 'react-icons/bs';
import PagamentoSucesso from '../Pagamentos/PagamentoSucesso';
import { formatarData } from '../../Utils/formatarData';
import { FaHeadset } from 'react-icons/fa';
import { IPagamentosAssinatura, IPagamentosUsuario, fakePagamentos, fakeUserPagamento } from '../../Utils/DevUtils/FakePagemntos';
import { useReacttQueryPagamentos } from '../../Utils/ReactQuery/getPagamentos';
import { TPlanoPagamentos } from '../../Utils/types/pagamentoTypes';
import moment from 'moment';
import PlanosCard from '../Pagamentos/PlanosCard';

const PagamentoConfig = () => {

  const usuario = fakeUserPagamento;

  const [searchParams, setSearchParams] = useSearchParams()

  const token = localStorage.getItem('token');
  const empresaSelecionada = localStorage.getItem('empresaPadrão');
  const { getArmazenamentoEmpresa } = useReactQueryEmpresas()
  const { getPagamentos } = useReacttQueryPagamentos()

  const { data: armazenamentoData, isLoading } = useQuery({
    queryFn: () => getArmazenamentoEmpresa(token as string, empresaSelecionada as string),
    queryKey: ['rqArmazenamentoEmpresa'],
    refetchOnWindowFocus: false
  })


  const { data: pagamentoData, isLoading: pagamentoIsLoading } = useQuery({
    queryFn: () => getPagamentos(empresaSelecionada as string, token as string),
    queryKey: ["rqPagamentos", empresaSelecionada, token]
  })



  const result = searchParams.get('result');

  useLayoutEffect(() => {
    if (result === 'cancelado') {
      setCancelado(true)
    }

    if (result === 'sucesso') {
      setSucesso(true)
    }
  }, [result])

  const [cancelado, setCancelado] = useState<boolean>(false);
  const [sucesso, setSucesso] = useState<boolean>(false)

  const baseUrl: any = `${process.env.REACT_APP_PRIVATE_API_URL}/api`;

  const handleAssinatura = async (plano: string) => {


    let url = ''
    if (armazenamentoData.planoDescrição === 'visitante') {
      url = `${baseUrl}/pagamentos/subscribe?plano=${plano}&empresaid=${empresaSelecionada}`;
    }

    if (armazenamentoData.planoDescrição !== 'visitante') {
      url = `${baseUrl}/pagamentos/subscribe?plano=${plano}&empresaid=${empresaSelecionada}`;
    }

    const res = await fetch(url);
    const response = await res.json();

    if (response.status === 3) {
      toast.error(response.msg);
      return;
    }
    window.open(response.url, "_blank", "noreferrer");
  }

  const fechar = () => {
    setCancelado(false)
    setSucesso(false)
  }

  const ajustarValorPago = (valor: any) => {
    const valorEmReal = (valor / 100).toLocaleString("pt-br", { style: "currency", currency: 'BRL' })

    return valorEmReal
  }

  const baixarFatura = (link: string) => {
    window.open(link, "_blank", "noreferrer");
  }

  const acessarPortalCliente = async () => {

    if(pagamentoData){
      const url = `${baseUrl}/pagamentos/client-portal/${(pagamentoData.plano.CustomerId)}`;
    const res = await fetch(url);
    const response = await res.json();

    window.open(response.url, "_blank", "noreferrer");
    }
    return

  }

  const contarDiasDisponiveis =()=>{
    if(pagamentoData){

      const dataAtual = moment();
      const dataFinal   = moment.unix(pagamentoData.plano.Fim); 
      const diff = moment(dataFinal).diff(dataAtual, 'days')
      return `${diff}`
    }

    return ''
  
  }

  // Tailwind -----------------------------------------------------------
  const plano = 'border border-opacity-80 shadow-lg max-w-lg p-5 rounded-lg relative';
  const planoAtual = 'border border-opacity-80 shadow-lg max-w-lg p-5 rounded-lg relative opacity-50'

  const iconAtivo = 'w-4 h-4 rounded-full mx-2 mt-1 bg-green-500 flex justify-center items-center';
  const iconInativo = 'w-4 h-4 rounded-full mx-2 mt-1 bg-red-500 flex justify-center items-center'
  const iconSolicInativo = 'w-4 h-4 rounded-full mx-2 mt-1 bg-yellow-500 flex justify-center items-center'
  // Tailwind -----------------------------------------------------------

  return (
    <div className='min-h-screen w-full mt-12'>

      <div className='flex flex-col mb-5'>
        <h3 className='text-2xl font-semibold'> Gestão de assinaturas </h3>
        <p> Contrate e gerencie as assinaturas do portal GLC </p>
      </div>

      {
        cancelado && <PagamentoCancelado fechar={fechar} />
      }
      
      {
        sucesso && <PagamentoSucesso fechar={fechar} />
      }

      {
        armazenamentoData && armazenamentoData.planoDescrição === 'visitante' &&
        <div className='flex flex-col w-full px-4 mb-4'>
          <h3> Assine um plano para começar a utilizar as ferramentas do portal GLC</h3>
          <p> A assinaura possui um período de 7 dias para avaliação gratuíta, apos a finalização deste período, a suua empresa será cobrada pelo valor do plano contratado!</p>
          <label className='inline-flex font-medium'><BsExclamation /> Ao acessar um modelo de assinatura, você será redirecionado para o checkout com a empresa de pagamentos associada.</label>
        </div>
      }

      {
        pagamentoData && pagamentoData.status !== 3 && pagamentoData.plano.Status === 'inativo' &&
        <div className='flex flex-col w-full px-4 mb-4'>
          <h3 className='font-semibold inline-flex'> <BsExclamation className='mt-1'/> A sua assinatura foi pausada</h3>
          <p> Acesse o portal de configurações do plano para renovar a assinatura, ou escolha outro plano para voltar a utilizar as ferramentas do portal GLC.</p>
        </div>
      }
     
      {
        pagamentoData && pagamentoData.status !== 3 && pagamentoData.plano.Status === 'cancelado' &&
        <div className='flex flex-col w-full px-4 mb-4'>
          <h3 className='font-semibold inline-flex'> <BsExclamation className='mt-1'/> A sua assinatura foi pausada</h3>
          <p> Acesse o portal de configurações do plano para renovar a assinatura, ou escolha outro plano para voltar a utilizar as ferramentas do portal GLC.</p>
        </div>
      }
     
      {
        pagamentoData && pagamentoData.status !== 3 && pagamentoData.plano.Status === 'cancelamento solicitado' &&
        <div className='flex flex-col w-full px-4 mb-4'>
          <h3 className='font-semibold inline-flex'> <BsExclamation className='mt-1'/> A sua assinatura foi pausada</h3>
          <p> Acesse o portal de configurações do plano para renovar a assinatura, ou escolha outro plano para voltar a utilizar as ferramentas do portal GLC.</p>
        </div>
      }

      { 

        armazenamentoData && armazenamentoData.planoDescrição === 'visitante' &&
       
      <div className='my-2'>
        <PlanosCard handleAssinatura ={handleAssinatura}/>
        <div className='w-full flex flex-col mt-2'>
                <label className='inline-flex'> Em casos de duvidas ou suporte, entre em contato com nossa equipe através do email: 
                    <a className='font-semibold ml-5 opacity-80 hover:opacity-100' href="mailto:mtsgosk8@gmail.com?subject=Email de suporte [coompra-logado]"> atendimento@portalglc.com </a> 
                </label>
            </div>
      </div>
      }
      { 
       armazenamentoData && armazenamentoData.planoDescrição === 'inativo' &&
      <div className='my-2'>
        <h3>Ao assinar outro plano, todas as informaçõoes referentes a assinatuura anterior serão apagadas, incluindo faturas e informações de pagamento.</h3>
        <PlanosCard handleAssinatura ={handleAssinatura}/>
      </div>
      }
  
      <h3 className='text-xl font-semibold my-4'> Plano atual </h3>

      {
        pagamentoData && pagamentoData.plano ?

          <div className=' border border-opacity-80 shadow-lg max-w-lg p-5 rounded-lg relative'>

            <div className='absolute right-2 top-0'>
              <label htmlFor="" className='inline-flex opacity-80 hover:opacity-100 cursor-pointer transition-opacity' 
              onClick={acessarPortalCliente}>
              
              <BsGear className='mt-1 mr-1' /> Gerir plano</label>
            </div>

            <label className='text-xl font-semibold p-2'> {pagamentoData && pagamentoData.plano.Plano} </label>
            <div>

              <label className='inline-flex'> Status da assinatura:
                <div className={pagamentoData && pagamentoData.plano.Status === 'vigente' ? `${iconAtivo}`: pagamentoData.plano.Status === 'cancelamento solicitado' ? `${iconSolicInativo}`  :`${iconInativo}` }><div className='w-2 h-2 rounded-full bg-white flex justify-center items-center'></div></div>
                <span className='font-semibold'>{pagamentoData && pagamentoData.plano.Status.toUpperCase()}</span>
              </label>

              <div>
                <p><span>Valor: </span> {pagamentoData && ajustarValorPago(pagamentoData.plano.Valor)} - (Assinatura mensal)</p>

                {
                  pagamentoData.plano.Status === 'cancelamento solicitado' &&
                  <div className='inline-flex'>
                    <p>{contarDiasDisponiveis()} Dias disponíveis no plano atual.</p>
                    
                  </div>
                }

              </div>

              {

                pagamentoData && pagamentoData.plano.Plano.toLowerCase() === 'básico' &&
                <div className='ml-4'>
                   <p> <span> Benefícios: </span> </p>
                  <div className='ml-2 flex flex-col '>
                    <p className='inline-flex'> <BsDot className='mt-1' /> 5 gb em arquivos salvos na nuvem.</p>
                    <p className='inline-flex'> <BsDot className='mt-1' /> Acompanhamento de processos</p>
                    <p className='inline-flex'> <BsDot className='mt-1' /> Busca automática de edtais.</p>
                    <p className='inline-flex'> <BsDot className='mt-1' /> Alertas de licitações personalizados.</p>
                  </div>
                </div>

              }
              {

                pagamentoData && pagamentoData.plano.Plano.toLowerCase() === 'intermediário' &&
                <div className='ml-4'>
                  
                  <p> <span> Benefícios: </span> </p>
                  <div className='ml-2 flex flex-col '>
                    <p className='inline-flex'> <BsDot className='mt-1' /> 10 gb em arquivos salvos na nuvem.</p>
                    <p className='inline-flex'> <BsDot className='mt-1' /> Acompanhamento de processos</p>
                    <p className='inline-flex'> <BsDot className='mt-1' /> Busca automática de edtais.</p>
                    <p className='inline-flex'> <BsDot className='mt-1' /> Alertas de licitações personalizados.</p>
                  </div>
                </div>

              }

            </div>
          </div>
          :
          <div className='flex flex-col'>
            <label htmlFor=""> Nenhum plano assinado! </label>
            <p> Assine um plano para continuar utiizando a plataforma!</p>
          </div>

      }

      <h3 className='my-4 font-semibold text-xl'> Histórico de pagamentos</h3>

      <div className=' border border-opacity-80 shadow-lg p-5 rounded-lg relative'>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Nome do pagadors
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Valor pago
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Data do pagamento
                </th>
                <th scope="col" className="px-6 py-3">
                  Fatura
                </th>
              </tr>
            </thead>
            <tbody>

              {
                  pagamentoData && pagamentoData.status !== 3 ? pagamentoData.pagamentos.map((pagamento: TPlanoPagamentos) => (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                    <td className="px-6 py-4">
                      {pagamento.Nome}
                    </td>

                    <td className="px-6 py-4">
                      {pagamento.Email}
                    </td>

                    <td className="px-6 py-4">
                      {ajustarValorPago(pagamento.Valor)}
                    </td>
                    <td className="px-6 py-4 inline-flex">
                    <div className={pagamento.Status === 'pago' ? `${iconAtivo}`: pagamento.Status === 'pendente' ? `${iconSolicInativo}`  :`${iconInativo}` }><div className='w-2 h-2 rounded-full bg-white flex justify-center items-center'></div></div>
                      {pagamento.Status} 
                    </td>

                    <td className="px-6 py-4">
                       { pagamento.StripeDate ? new Date((pagamento.StripeDate)*1000).toLocaleString() as unknown as string : formatarData(pagamento.createdAt)}
                    </td>

                    <td>
                      <p className='cursor-pointer' onClick={() => baixarFatura(pagamento.Fatura_pdf as string)}><BsDownload /> </p>
                    </td>

                  </tr>
                ))
                :
                <label htmlFor="">
                    Nenhum pagamento encontrado!
                </label>
              } 
            </tbody>
          </table>
        </div>

      </div>

    </div>
  )
}

export default PagamentoConfig