import { AnyRecord } from 'dns';
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { BsCaretDown, BsDownload, BsEye, BsFilePlus, BsInfo, BsPencil, BsTrash } from 'react-icons/bs';
import { FaList, FaRegFile } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { formatarData } from '../../Utils/formatarData';
import { DisplayArquivos, DisplayPastas, ModoAbrirArquivo } from '../../Utils/types/pastasTypes';
import ArquivoModal from '../ArquivosComponents.tsx/ArquivoModal';
import EditarArquivo from '../ArquivosComponents.tsx/EditarArquivo';
import ApagarArquivo from './ApagarArquivo';
import NovoArquivo from './NovoArquivo';
import PastaInfo from './PastaInfo';
import styles from './Pastas.module.css'
import { SlShareAlt } from "react-icons/sl";
import ArquivoModalPastas from '../ArquivosComponents.tsx/ArquivosModalPastas';




const PastaConteudo = ({pasta, displayPastas, arquivos}: any) => {

    // // //~~MANUTENÇÃO~~//
    // useEffect(()=>{
    //     console.log('Arquiivos: ',arquivos)
    // },[arquivos])
    // // //~~MANUTENÇÃO~~//



    const [pastaInfo, setPastaInfo] = useState<boolean>(false);
    const [arquivosSelecionados, setArquivosSelecionados] = useState<any>('');

    const [arquivoSelecionado, setArquivoSelecionado] = useState<any>();
    
    const [apagarArquivo, setApagarArquivo] = useState<boolean>(false);
    const [arquivoAberto, setArquivoAberto] = useState<boolean>(false);
    const [novoArquivo, setNovoArquivo] = useState<boolean>(false);
    const [editarArquivo, setEditarArquivo] = useState<boolean>(false);

    const [displayArquivo, setDisplayArquivo] = useState<ModoAbrirArquivo>(ModoAbrirArquivo.EDITAR);
    const [abrirArquivoDetalhe, setAbrirArquivoDetalhe] = useState<boolean>(false);

    const fecharPastaInfo =(e: any)=>{
        e.preventDefault();
        setPastaInfo(false);
    }

    const handleApagarArquivo = (e: any, arquivo: any)=>{
        e.preventDefault();
        setApagarArquivo(true);
        setArquivoSelecionado(arquivo);
        return;
    }

    const handleAbrirArquivo = (e: any, arquivo: any)=>{
        e.preventDefault();
        setArquivoSelecionado(arquivo);
        setArquivoAberto(true)
    }

    const fecharArquivo =(e: any)=>{
        setApagarArquivo(false);
        setArquivoAberto(false);
        setNovoArquivo(false);
        setEditarArquivo(false);
        setAbrirArquivoDetalhe(false);
        return;
    }


    const handleEditarArquivo = (e: any, arquivo: any)=>{
        e.preventDefault();
        setArquivoSelecionado(arquivo);
        setEditarArquivo(true);
        setDisplayArquivo(ModoAbrirArquivo.EDITAR)
        return;
    }

    const handleAbrirArquivoDetalhe =(e: any, arquivo: any)=>{
        e.preventDefault();
        setAbrirArquivoDetalhe(true);
        setArquivoSelecionado(arquivo);
        setDisplayArquivo(ModoAbrirArquivo.VISUALIZAR);
        return;
    }


    useLayoutEffect(()=>{
        const tempArquivos = arquivos.filter((i: any)=>{
            return i.PastaArquivo === pasta._id
        })
        console.log('Pasta i: ', pasta._id)
        console.log('Arqivos ---> ', tempArquivos)
        setArquivosSelecionados(tempArquivos)
    },[pasta])

    const dowloadFile = (e: any, fileUrl: string, fileName: string, arquivoPath: string)=>{
        e.preventDefault();

        

        try {
            let contentUrl = '';
        if(fileUrl){
            contentUrl = fileUrl
        }
        if(arquivoPath){
            contentUrl = `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/${arquivoPath}`
        }
            fetch(contentUrl).then((response)=> response.blob()).then((blob)=> {
                const url = window.URL.createObjectURL( new Blob([blob]));
                const link = document.createElement('a');
                link.href =url;
                link.download = `${fileName}`
    
                document.body.appendChild(link);

                link.click();
                
                link.parentNode?.removeChild(link);
                window.URL.revokeObjectURL(fileUrl)
    
            });
        } catch (error) {
            console.error(error);
            toast.error('Erro no dowload!');
            return;
            
        }
    }

    const findArquivoLink = (arquivo: any)=>{
        console.log('Arquivoo = ====> ',arquivo)
        switch(true){
            case arquivo.Cnpj === '19001876000180':
                const link2 = `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/${arquivo.ArquivoPath}`;
                return link2;
            case arquivo.LinkAws !== undefined:
                return arquivo.LinkAws
            case arquivo.ArquivoPath !== undefined:
                const link = `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/${arquivo.ArquivoPath}` ;
                return link;    
        }
    }

    

    return (
    <div className='flex flex-col mt-5'>
        {
            pastaInfo && <PastaInfo fechar = {fecharPastaInfo} pasta ={pasta}/>
        }

        {
            apagarArquivo && <ApagarArquivo fechar ={fecharArquivo} arquivo ={arquivoSelecionado}/>
        }

        {
            arquivoAberto && <ArquivoModalPastas arquivo ={arquivoSelecionado} fechar = {fecharArquivo}/>
        }
        {
            novoArquivo && <NovoArquivo fechar ={fecharArquivo} pastaid ={pasta._id}/> 
        }
        {
            editarArquivo && <EditarArquivo arquivo ={arquivoSelecionado} fechar ={fecharArquivo} displayArquivo ={ModoAbrirArquivo.EDITAR}/>
        }
        {
            abrirArquivoDetalhe && <EditarArquivo arquivo ={arquivoSelecionado} fechar ={fecharArquivo} displayArquivo ={ModoAbrirArquivo.VISUALIZAR} />

        }

        <div className='inline-flex justify-between gap-2  mt-2  w-full' >
            
            <label onClick={()=> setPastaInfo(current => !current)} className='inline-flex gap-2 w-fit opacity-80 hover:opacity-100 transition-opacity cursor-pointer'><span className=' text-lg  '> < BsInfo/></span> Informações da pasta</label>

            <label onClick={()=> setNovoArquivo(current => !current)} className='inline-flex gap-2 w-fit opacity-80 hover:opacity-100 transition-opacity cursor-pointer'><span className=' text-lg  '><BsFilePlus/></span> Novo arquivo</label>
        
        </div>

            {
                displayPastas === DisplayPastas.GRID &&            
                <div className={`${styles.displayPastas} `}>
                    { 
                        arquivosSelecionados && arquivosSelecionados.map((arquivo: any)=>(
                        <div className ='cursor-pointer opacity-80 hover:opacity-100 '>
                            <label key={arquivo._id} id={arquivo._id} className ={`flex flex-col justify-center items-center`}  >
                            <FaRegFile className='text-7xl cursor-pointer' />

                            <div className='flex flex-col'>
                                <a href={findArquivoLink(arquivo)} target='_blank'>{arquivo.NomeArquivo}</a>  

                                <div className='flex flex-row justify-center items-center gap-2'> 
                                <BsDownload onClick={(e)=>dowloadFile(e, arquivo.LinkAws, arquivo.NomeArquivo, arquivo.ArquivoPath)} className='cursor-pointer opacity-80 hover:opacity-100'/>
                                <BsEye onClick={(e) =>handleAbrirArquivo(e, arquivo)} className='cursor-pointer opacity-80 hover:opacity-100'/>
                                <BsPencil onClick ={(e)=> handleEditarArquivo(e, arquivo)} className='cursor-pointer opacity-80 hover:opacity-100' /> 
                                <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100' onClick ={(e)=> handleApagarArquivo(e, arquivo)}/> 
                                </div>
                            </div>  

                            </label>
                        </div>
                        )) 
                    } 
                </div> 
            }

           { 
           displayPastas === DisplayPastas.LIST &&
           arquivosSelecionados && arquivosSelecionados.map((arquivo: any)=>(
                <div className='flex flex-row justify-between w-full pl-5'>
                <a href={findArquivoLink(arquivo)} target='_blank'
                 className='underline cursor-pointer'>{arquivo.NomeArquivo}</a> 
                
                <div className='inline-flex gap-4 pr-5'>
                <BsDownload onClick={(e)=>dowloadFile(e, arquivo, arquivo.NomeArquivo, arquivo.ArquivoPath)} className='cursor-pointer opacity-80 hover:opacity-100'/>
                <BsEye onClick={(e) =>handleAbrirArquivo(e, arquivo)} className='cursor-pointer opacity-80 hover:opacity-100'/>
                <BsPencil onClick ={(e)=> handleEditarArquivo(e, arquivo)} className='cursor-pointer opacity-80 hover:opacity-100' /> 
                <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100' onClick ={(e)=> handleApagarArquivo(e, arquivo)}/> 
                </div>
            </div>
            ))
            
            }   

            {
                displayPastas === DisplayPastas.DETAILS &&
            
            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Nome do arquivo
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Descrição
                            </th>
                            <th scope="col" className="px-6 py-3">
                                ultima modificação
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Criado por
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Opções
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                       
                        {
                           arquivosSelecionados && arquivosSelecionados.map((arquivo: any)=>(
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900
                            underline underline-offset-2 whitespace-nowrap dark:text-white">
                                <label className='cursor-pointer w-fit' onClick={(e)=> handleAbrirArquivoDetalhe(e, arquivo)}> {arquivo.NomeArquivo}</label> 
                            </th>
                            <td className="px-6 py-4">
                                {arquivo.Descrição && arquivo.Descrição.length >= 25 ? arquivo.Descrição.slice(0, 25)+ '...' :arquivo.Descrição}
                            </td>
                            <td className="px-6 py-4">
                                {formatarData(arquivo.UltimaModificação)}
                            </td>
                            <td className="px-6 py-4">
                                {arquivo.CriadoPor}
                            </td>
                            <td className="px-6 py-4 inline-flex text-black dark:text-gray-300  gap-4">
                                <a href={findArquivoLink(arquivo)} target='_blank'><SlShareAlt className='cursor-pointer opacity-80 hover:opacity-100'/> </a>
                                <BsDownload onClick={(e)=>dowloadFile(e, arquivo.LinkAws, arquivo.NomeArquivo, arquivo.ArquivoPath)} className='cursor-pointer opacity-80 hover:opacity-100'/>
                                <BsEye onClick={(e) =>handleAbrirArquivo(e, arquivo)} className='cursor-pointer opacity-80 hover:opacity-100'/>
                                <BsPencil onClick ={(e)=> handleEditarArquivo(e, arquivo)} className='cursor-pointer opacity-80 hover:opacity-100' /> 
                                <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100' onClick ={(e)=> handleApagarArquivo(e, arquivo)}/>
                            </td>
                        </tr>
                            ))
                        }
                        
                    </tbody>
                </table>
            </div>
            }



        
    </div>
  )
}

export default PastaConteudo