import React from 'react'
import { DisplayMiddle } from '../../Utils/types/PortalTypes'
import { BsChat, BsChatFill, BsFilter, BsPerson, BsPersonFill, BsStar, BsStarFill } from 'react-icons/bs';

const FiltroGlobal = ({ filtroGlobal, changeFiltroGlobal, alterarFiltroImportante, importante }: any) => {

    const baseLabelItem = 'flex flex-row justify-center items-center w-full text-center gap-2 px-2 py-1 opacity-80 hover:opacity-100 transition-opacity cursor-pointer text-sm rounded-md';
    const selectedLabelItem = 'flex flex-row justify-center items-center w-full text-center gap-2 px-2 py-1 cursor-pointer text-sm font-semibold bg-[#ff8027] text-white dark:bg-orange-800 rounded-md';

    return (
        <div className='w-full flex flex-row justify-between h-fit bg-white dark:bg-gray-800 p-1 -mt-2 pr-4'>
            
            <div className='flex flex-row min-w-28 mr-5 gap-2 pl-3'>
                <label className={` mt-1 cursor-pointer opacity-75 hover:opacity-100`} onClick={alterarFiltroImportante}>
                
                    {
                        importante ?
                        <BsStarFill className='text-xl'/>
                        :<BsStar className='text-xl'/>
                    }
                
                </label>
                
                <label className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center' onClick={() => changeFiltroGlobal(DisplayMiddle.CONTATO)}>
                    { 
                    filtroGlobal === DisplayMiddle.CONTATO ? 
                    <BsPersonFill className='text-xl'/>
                    :
                    <BsPerson className='text-xl'/> 
                    }
                </label>
                
                <label className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center'  onClick={() => changeFiltroGlobal(DisplayMiddle.CHAT)}>
                    {
                        filtroGlobal === DisplayMiddle.CHAT ? 
                        <BsChatFill className='text-xl'/>
                        :
                        <BsChat className='text-xl'/> 
                    } 
                    
                </label>
                
            </div>
            
            <label className={filtroGlobal === DisplayMiddle.RESUMO ? `${selectedLabelItem}` : `${baseLabelItem}`} onClick={() => changeFiltroGlobal(DisplayMiddle.RESUMO)}>Resumo </label>
            <label className={filtroGlobal === DisplayMiddle.DOCUMENTOS ? `${selectedLabelItem}` : `${baseLabelItem}`} onClick={() => changeFiltroGlobal(DisplayMiddle.DOCUMENTOS)}>Documentos </label>
            <label className={filtroGlobal === DisplayMiddle.ARP ? `${selectedLabelItem}` : `${baseLabelItem}`} onClick={() => changeFiltroGlobal(DisplayMiddle.ARP)}>A.R.P </label>
            <label className={filtroGlobal === DisplayMiddle.CONTRATOS ? `${selectedLabelItem}` : `${baseLabelItem}`} onClick={() => changeFiltroGlobal(DisplayMiddle.CONTRATOS)}>Contratos </label>
            <label className={filtroGlobal === DisplayMiddle.OBJETO ? `${selectedLabelItem}` : `${baseLabelItem}`} onClick={() => changeFiltroGlobal(DisplayMiddle.OBJETO)}>Objeto </label>
            <label className={filtroGlobal === DisplayMiddle.EMPENHO ? `${selectedLabelItem}` : `${baseLabelItem}`} onClick={() => changeFiltroGlobal(DisplayMiddle.EMPENHO)}>Empenho</label>
            <label className={filtroGlobal === DisplayMiddle.OS ? `${selectedLabelItem}` : `${baseLabelItem}`} onClick={() => changeFiltroGlobal(DisplayMiddle.OS)}>O.S'ᴮ</label>
            <label className={filtroGlobal === DisplayMiddle.FATURAMENTO ? `${selectedLabelItem}` : `${baseLabelItem}`} onClick={() => changeFiltroGlobal(DisplayMiddle.FATURAMENTO)}>Faturamento </label>
            <label className={filtroGlobal === DisplayMiddle.ACT ? `${selectedLabelItem}` : `${baseLabelItem}`} onClick={() => changeFiltroGlobal(DisplayMiddle.ACT)}>A.C.T </label>
         
        </div>
    );
}

export default FiltroGlobal;
