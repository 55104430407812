import React, { useEffect, useState } from 'react'
import { DisplayMiddle } from '../../Utils/types/PortalTypes';
import { Link } from 'react-router-dom';
import { formatarData } from '../../Utils/formatarData';
import { BsDownload, BsEye, BsFilePlus, BsFolderPlus, BsPencil, BsTrash } from 'react-icons/bs';
import { FaFolderOpen } from "react-icons/fa";

import moment from 'moment';
import AbrirPastaProcesso from './AbrirPastaProcesso';
import NovaPastaProcesso from './NovaPastaProcesso';
import ApagarArquivo from '../PastasComponents/ApagarArquivo';
import ApagarPastaArquivoProcesso from './ApagarPastaArquivoProcesso';
import { TbFilePlus } from 'react-icons/tb';
import { useQuery } from '@tanstack/react-query';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import ArquivoModal from '../ArquivosComponents.tsx/ArquivoModal';
import { toast } from 'react-toastify';
import CaptacaoArquivos from '../CaptacaoAutomatica/CaptacaoArquivos';
import CaptacaoArquivosProcesso from '../CaptacaoAutomatica/CaptacaoArquivosProcesso';
import { converterValor } from '../../Utils/ConverterValor';


const ProcessosPastas = ({filtro, processo}: any) => {

    const [pastas, setPastas] = useState<any>([]);
    const [pastaSelecionada, setPastaSelecionada] = useState<any>('');
    const [arquivoSelecionado, setArquivoSelecionado] = useState<any>('');

    const [pastaAberta, setPastaAberta] = useState<boolean>(false);
    const [novaPasta, setNovaPasta] = useState<boolean>(false);
    const [pastaEditar, setPastaEditar] =useState<boolean>(false);
    const [apagar, setApagar] = useState<boolean>(false);
    const [tipoApagado, setTipoApagado] = useState<string>('');
    const [arquivosPasta, setArquivosPasta] = useState<any>('');
    const [abirArquivo, setAbrirArquivo] = useState<boolean>(false);


    const token = localStorage.getItem('token');
    const {listarPastasProcessos} =  useReactQueryProcessos()

    const {data: pastaArquivoData, isLoading} = useQuery({
        queryKey: ['rqPastasProcesso', processo, filtro],
        queryFn: ()=> listarPastasProcessos({processoid: processo._id as string, token: token as string})
    })


    const abrirArquivoModal = (arquivo: any)=>{
        setArquivoSelecionado(arquivo);
        setAbrirArquivo(true);
    }

    const dataAtual = moment();

    const checkStatus =(dataVencimento: string, status: string)=>{


        if(!dataVencimento && status === 'Nenhum'){
            return <span className=' text-green-500'> --- </span>

        }


        const dataFinal = moment(dataVencimento);
        const dif = moment(dataFinal).diff(dataAtual, 'days');
        const hours = moment(dataFinal).diff(dataAtual, 'hours');


        switch(true){
            case status === 'Deferido':
                return <span className=' text-green-500'> Deferido </span>
                break;
            case status === 'Indeferido':
                return <span className=' text-red-500'> Indeferido </span>
                break;
            case dif === 0 && status ==='Nenhum' || dif === 0 && !status :
                return <span className=' text-yellow-500'> Vence hoje</span>
            case dif < 90 && dif > 0 &&status ==='Nenhum' || dif < 90 && dif> 0 && !status :
                return <span className=' text-yellow-500'> Vence em {dif} dias</span>
                break;
            case dif > 0 && status ==='Nenhum' || dif > 0 && !status:
                return <span className=' text-green-500'> Vigente</span>
                break;
            case dif < 0 && status === 'Nenhum' || dif < 0 && !status:
                return <span className=' text-red-500'> Vencido </span>
                break;
            case status === 'entregue':
                return <span className=' text-green-500'> Entregue </span>
                break;
            case status === 'distratado':
                return <span className=' text-red-500'> Distratado </span>
                break;
            case status === 'encerrado':
                return <span className=' text-red-500'> Encerrado </span>
                break;
            
               

            
        }

    }

      const abrirPasta = (pasta: any)=>{
        setPastaSelecionada(pasta)
        setPastaAberta(true);
      }

      const editarPasta =(pasta: any)=>{
        setPastaSelecionada(pasta)
        setPastaEditar(true);
      }

      const findLinkArquivo =(arquivo: any) =>{
        switch(true){
            case arquivo.oldDescartmed === true && arquivo.arquivoPath !== undefined:
                return `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/${arquivo.arquivoPath.replaceAll(/\\/g, "/")}`

            case arquivo.LinkAws !== undefined:
                return arquivo.LinkAws as string;
                break;
            case arquivo.arquivoPath !== undefined:
                const link = `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/${arquivo.arquivoPath.replaceAll(/\\/g, "/")}`
                return link;
                break;
            
        }

      }

      const ajustarCategoria =(categoria : string)=>{

        switch(true){
            case categoria === 'os':
                return `Ordem Serviço'ᴮ'`
                break;
            case categoria === 'act':
                return 'Atestdo de capacidade técnica'
                break
            case categoria === 'objeto':
                return 'Proposta readequada'
                break
            case categoria === 'arp':
                return 'Ata registros preços'
                break
            default: return categoria
        }

    }

      const apagarPastaArquivo = (data: any, tipo: any)=>{
        if(tipo === 'arquivo'){
            setArquivoSelecionado(data);
            setTipoApagado('arquivo')
            setApagar(true)
        }else{
            setTipoApagado('pasta')
            setPastaSelecionada(data);
            setApagar(true)
        }
        }
   

      const fechar =()=>{
        setPastaAberta(false);
        setNovaPasta(false);
        setPastaEditar(false);
        setApagar(false);
        setPastaSelecionada('')
      }

      const fecharArquivo = ()=>{
        setAbrirArquivo(false);
        setArquivoSelecionado('');
      }



    useEffect(()=>{

    

        if(pastaArquivoData)
        {

         
            if(filtro === DisplayMiddle.ESCLARECIMENTO)
            {const pastaSelecionada = pastaArquivoData.pastas.filter((i: any)=>{
                if (
                    i.categoria ===  'Pedido Esclarecimento' || i.categoria === 'Resposta Esclarecimento' || i.categoria === 'Esclarecimento' &&  i.processoId === processo._id
                ){
                    return i
                }
            })
            setPastas(pastaSelecionada);
                return
            }
      
            if(filtro === DisplayMiddle.IMPUGNAÇÃO)
            { 
                const pastaSelecionada2 = pastaArquivoData.pastas.filter((i: any)=>{
                    if (
                        i.categoria ===  'Pedido Impugnação' || i.categoria === 'Resposta Impugnação' || i.categoria === 'impugnação' &&  i.processoId === processo._id 
                    ){
                        return i
                    }
                })
                setPastas(pastaSelecionada2);
                return;
            }

            
            if(filtro === DisplayMiddle.DILIGENCIA)
            { 
                const pastaSelecionada = pastaArquivoData.pastas.filter((i: any)=>{
                    if (
                        i.categoria ===  'solicitação Diligência' || i.categoria === 'Resposta Diligência' || i.categoria === 'Diligência' &&  i.processoId === processo._id 
                    ){
                        return i
                    }
                })
                setPastas(pastaSelecionada);
                return;
            }
           
            if(filtro === DisplayMiddle.MANDATOS)
            { 
                const pastaSelecionada = pastaArquivoData.pastas.filter((i: any)=>{
                    if (
                        i.categoria ===  'Pedido Mandato.s' || i.categoria === 'Resposta Mandato.s' || i.categoria === 'Mandatos' &&  i.processoId === processo._id 
                    ){
                        return i
                    }
                })
                setPastas(pastaSelecionada);
                return;
            }
            if(filtro === DisplayMiddle.CONTRATOS)
            { 
                const pastaSelecionada = pastaArquivoData.pastas.filter((i: any)=>{
                    if (i.categoria === 'Contrato' && !i.pastaid){
                        return i
                    }
                })

                const findPastaComArquivos = pastaArquivoData.pastas.filter((i: any)=>{
                    if(i.processoId === processo._id && i.pastaid ){
                       return i
                    }
               })
               setArquivosPasta(findPastaComArquivos)

                setPastas(pastaSelecionada);
                return;
            }
            
            if(filtro === DisplayMiddle.OS)
            { 
                const pastaSelecionada = pastaArquivoData.pastas.filter((i: any)=>{
                    if (
                        i.categoria ===  "Ordem Serviço'ᴮ" && !i.pastaid
                    ){
                        return i
                    }
                })
                setPastas(pastaSelecionada);
                return;
            }
            if(filtro === DisplayMiddle.EMPENHO)
            { 
                const pastaSelecionada = pastaArquivoData.pastas.filter((i: any)=>{
                    if (
                        i.categoria ===  "Empenho" && !i.pastaid
                    ){
                        return i
                    }
                })
                setPastas(pastaSelecionada);
                return;
            }

            
            
            if(filtro === DisplayMiddle.PUBLICAÇÃO)
            { 
                const pastaSelecionada = pastaArquivoData.pastas.filter((i: any)=>{
                    if (
                        i.categoria ==='Publicação Aviso' ||
                        i.categoria ==='Licitação' ||
                        i.categoria ==='Edital/Anexos' ||
                        i.categoria ==='Termo Referência' ||
                        i.categoria ==='Recibo Edital' ||
                        i.categoria ==='Retificação' ||
                        i.categoria ==='Rerratificação' ||
                        i.categoria ==='Adiamento' ||
                        i.categoria ==='Cancelamento' ||
                        i.categoria ==='Revogado' ||
                        i.categoria ==='Suspenso' ||
                        i.categoria ==='Fracassado' ||
                        i.categoria ==='Deserto' 
                       
                        &&  i.processoId === processo._id 
                    ){
                        return i
                    }
                })
                setPastas(pastaSelecionada);
                return;
            }


           

            if(filtro === DisplayMiddle.DOCUMENTOS && pastaArquivoData){

                
                
                const findPastaComArquivos = pastaArquivoData.pastas.filter((i: any)=>{
                    if(i.processoId === processo._id && i.pastaid){
                       return i
                    }
               })
               setArquivosPasta(findPastaComArquivos)

                const findPasta = pastaArquivoData.pastas.filter((i: any)=>{
                     if(i.processoId === processo._id && !i.pastaid){
                        return i
                     }
                })
                setPastas(findPasta)
            }
            else{
            const pastaSelecionada = pastaArquivoData.pastas.filter((i: any)=>{
                if (i.categoria ===  filtro && i.processoId === processo._id){
                    return i
                }
            })
            setPastas(pastaSelecionada);
    
            }

        }


        
    },[pastaArquivoData, filtro])

    const dowloadFile = (e: any, arquivo: any, fileName: string)=>{
  
        e.preventDefault();

        const nomeValido = `${fileName.replaceAll(/\s/g, '_')}.pdf`

        const fileUrl : any = arquivo.LinkAws;
        // const fileUrl : any = findLinkArquivo(arquivo);
        try {
            fetch(fileUrl).then((response)=> response.blob()).then((blob)=> {

                const url = window.URL.createObjectURL( new Blob([blob]));
                const link = document.createElement('a');
                link.href =url;
                link.download = `${nomeValido}`
    
                document.body.appendChild(link);
    
                link.click();
                
                link.parentNode?.removeChild(link);
                window.URL.revokeObjectURL(fileUrl)
    
            });
        } catch (error) {
            console.log(error);
            toast.error('Erro no dowload!');
            return;
            
        }
    }

    // //~~MANUTENÇÃO~~//
    // useEffect(()=>{
    //     console.log('Pastas: ', pastas)
    // },[pastas])
    // //~~MANUTENÇÃO~~//


  return (
    <div className='w-full mt-1 h-auto bg-gray-600'>
        {
            pastaAberta && <AbrirPastaProcesso pasta ={pastaSelecionada} processo ={processo} fechar ={fechar} arquivos ={arquivosPasta}/>
        }
        {
            novaPasta && <NovaPastaProcesso fechar ={fechar} processo ={processo}/>
        }
        {
            pastaEditar && <NovaPastaProcesso fechar ={fechar} processo ={processo} pasta ={pastaSelecionada} editar={true}/>
        }
        {
            apagar && <ApagarPastaArquivoProcesso fechar={fechar} pasta ={pastaSelecionada} arquivo ={arquivoSelecionado} tipo ={tipoApagado}/>
        }
        {
            abirArquivo && <ArquivoModal arquivo ={arquivoSelecionado} fechar={fecharArquivo} processo ={processo}/>
        }
        
        {/* {
            <CaptacaoArquivos url ={processo.LinkArquivosPncp}/>
        } */}
            


        {
            filtro !== DisplayMiddle.RESUMO && 
            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
                        <tr className='py-0'>
                            <th scope="col" className="px-6 ">
                                Descrição
                            </th>
                            <th scope="col" className="px-6 ">
                                Opção
                            </th>
                            <th scope="col" className="px-6 ">
                                Categoria
                            </th>
                            <th scope="col" className="px-6 ">
                                Emissão
                            </th>
                            <th scope="col" className="px-6 ">
                                Vencimento
                            </th>
                            <th scope="col" className="px-6 ">
                                Valor
                            </th>
                            <th scope="col" className="px-6 ">
                                Status
                            </th>
                          
                        </tr>
                    </thead>
                    <tbody >
                        {
                            filtro === DisplayMiddle.DOCUMENTOS &&
                            <CaptacaoArquivosProcesso url={processo.LinkArquivosPncp} processo ={processo}/>}
                        {
                            pastas && pastas.map((pasta: any)=>(
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6  font-medium text-gray-900
                                 whitespace-nowrap dark:text-white">
                                    <a target='_blank' href={findLinkArquivo(pasta)} className='cursor-pointer w-fit'> {pasta.nomeDoArquivo ? pasta.nomeDoArquivo.replaceAll('_', " ") :
                                    pasta.nomePasta.replaceAll('_', " ")}</a> 
                                </th>
                                <td className="px-6  flex flex-row gap-2">

                                {
                                    pasta.nomeDoArquivo ? <BsEye onClick={()=> abrirArquivoModal(pasta)} className='cursor-pointer opacity-80 hover:opacity-100' title='abrir arquivo'/>:<FaFolderOpen className='cursor-pointer opacity-80 hover:opacity-100' title='abrir pasta'onClick={(e)=> abrirPasta(pasta)}/> 
                                }

                                {
                                    pasta.nomeDoArquivo ? <BsDownload className='cursor-pointer opacity-80 hover:opacity-100' title='Baixar' onClick={(e)=> dowloadFile(e, pasta, pasta.nomeDoArquivo)}/>
                                    : <BsPencil className='cursor-pointer opacity-80 hover:opacity-100' title='Editar' onClick={()=> editarPasta(pasta)}/>

                                }

                                {
                                    pasta.nomeDoArquivo ?
                                    <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100' title='Apagar' onClick={()=> apagarPastaArquivo(pasta, 'arquivo')} />
                                    :
                                    <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100' title='Apagar' onClick={()=> apagarPastaArquivo(pasta, 'pasta')} />   
                                }
                                
                                </td>
                              
                                <td className="px-6 ">
                                    {ajustarCategoria(pasta.categoria).toUpperCase()}
                                </td>
                                <td className="px-6 ">
                                    {pasta.dataInicio ? formatarData(pasta.dataInicio): "Sine Die"}
                                </td>
                                <td className="px-6 ">
                                    {pasta.dataVencimento ? formatarData(pasta.dataVencimento) : "Sine Die"}
                                </td>
                                <td className="px-6 ">
                                    {converterValor(pasta.valor)}
                                </td>
                           
                                <td className="px-6  inline-flex text-black dark:text-gray-300  gap-4">
                                    {checkStatus(pasta.dataVencimento, pasta.status)}
                                </td>

                             

                                </tr>
                            ))
                        }
                        
                    </tbody>
                </table>
            </div>

        }


    </div>
  )
}

export default ProcessosPastas