import React, { useEffect, useState } from 'react'
import { BsDownload, BsEye, BsPencil, BsTrash } from 'react-icons/bs';
import { SlShareAlt } from 'react-icons/sl';
import { formatarData } from '../../Utils/formatarData';
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils';
import { useQuery } from '@tanstack/react-query';


const CaptacaoItens = ({item, url, total}: any) => {


    const [itens, setItens] = useState<any>(['']);

    const {getCaptacaoItens} = useReactQueryCaptacao();

    const {data: itensData, isLoading} = useQuery({
        queryFn: ()=> getCaptacaoItens(url as string),
        queryKey: ['rqCaptacaoArquivos', url]
    })

    if(itensData && total){
        total(itensData)
    }

  return (
    <div className='mb-12'>
        <div className="relative">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Numero
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Descrição
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Quantidade
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Valor unitario estimado 
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Valor total estimado
                            </th>
                    

                        </tr>
                    </thead>
                    <tbody>
                       
                        {
                           itensData && itensData.map((item: any, index: number)=>(
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                           
                            <td className="px-6 py-4">
                                {item.numeroItem}
                            </td>
                           
                            <td className="px-6 py-4">
                                {item.descricao}
                            </td>
                            <td className="px-6 py-4">
                                {item.quantidade}
                            </td>
                            
                            <td className="px-6 py-4">
                                {item.valorUnitarioEstimado === 0 ? 'Sigiloso' : item.valorUnitarioEstimado ? item.valorUnitarioEstimado.toLocaleString("pt-br", {style: "currency", currency: 'BRL'}) : item.valorUnitarioEstimado}
                            </td>
                            
                            <td className="px-6 py-4">
                                {item.valorTotal === 0 ? 'Sigiloso' : item.valorTotal ? item.valorTotal.toLocaleString("pt-br", {style: "currency", currency: 'BRL'}): item.valorTotal}
                            </td>

                   
                        </tr>
                            ))
                        }
                        
                    </tbody>
                </table>
            </div>

    </div>
  )
}


export default CaptacaoItens