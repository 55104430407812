import { typePropsSolicitações, typeResolverSolicitação } from "../types/adminTypes";


export const useAdminUtils =()=>{

    const baseUrl: any = process.env.REACT_APP_PRIVATE_API_URL

    
    const listarSolicitações = async(data: typePropsSolicitações)=>{
        const url = `${baseUrl}/api/admin/listar-solicit?token=${data.token}&empresaid=${data.empresaid}`;

        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const listarEmpresas = async(token: string)=>{
        const url = `${baseUrl}/api/admin/listar-empresas?token=${token}`;
        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    }



    const resolverSolicitação = async(data: typeResolverSolicitação)=>{
        const url = `${baseUrl}/api/admin/configurar-solicit`;
        
        try {
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    'content-type':'application/json'
                },
                body: JSON.stringify(data)
            })
            const response = await res.json();
            return response;

        } catch (error) {
            console.error(error)
            return null;
        }
    }

    type typeListarUsuarios ={
        empresaId: string,
        token: string
    }

    const listarUsuarios = async(token: string, empresaid: string )=>{

        const url = `${baseUrl}/api/admin/listar-usuarios?token=${token}`;

        try {
            const res = await fetch(url,{
                method: 'POST',
                headers:{
                    'content-type':'application/json'
                },
                body: JSON.stringify({empresaid})
            });
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return;
        }
    }

    type subTypeConfigUser ={
        useridAlterado: string,
        empresaid: string,
        action: string,
        Permissão?: string,
        Atividade?: string
    }

    type typeConfigUser ={
        token: string,
        data: subTypeConfigUser
    }

    const configUsuario = async(data: typeConfigUser)=>{

        const url = `${baseUrl}/api/admin/configurar-usuario?token=${data.token}`;

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    'content-type':'application/json'
                },
                body: JSON.stringify(data.data)
            })

            const response = await res.json()
            return response;

        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const resolverAssessoria = async(data: typeResolverSolicitação)=>{
        const url = `${baseUrl}/api/admin/config-assessoria?token=${data.token}`;
        
        try {
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    'content-type':'application/json'
                },
                body: JSON.stringify(data)
            })
            const response = await res.json();
            return response;

        } catch (error) {
            console.error(error)
            return null;
        }
    }


    type TListarAssessoria = {
        token: string,
        empresaid: string
    }
    
    const listarAssessoria = async(data: TListarAssessoria)=>{
        const url = `${baseUrl}/api/admin/listar-assessorias?token=${data.token}&empresaid=${data.empresaid}`;

        try {
            try {
                const res = await fetch(url);
                const response = await res.json();
                return response;
            } catch (error) {
                console.error(error);
                return null;
            }
        } catch (error) {
            console.error('Erro interno na rquisição!');
            return;
        }

    }

    type TRevogarAssessoria ={
        token: string,
        empresaid: string,
        empresaidSolicitacao: string,
        action?: 'revogar' | 'aceitar'
    }

    const revogarAssessoria =async(data: TRevogarAssessoria)=>{
        const url = `${baseUrl}/api/admin/revogar-assessoria?token=${data.token}&empresaid=${data.empresaid}&empresaidSolicitacao=${data.empresaidSolicitacao}`;

        try {
            const res = await fetch(url);
            const response = await res.json();
                return response;
         } catch (error) {
            console.error(error);
            return null;
        }
      

    }


    
    
    return {
        listarSolicitações,
        listarEmpresas,
        resolverSolicitação,
        listarUsuarios,
        configUsuario,
        resolverAssessoria,
        listarAssessoria,
        revogarAssessoria
    }
}