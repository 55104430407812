import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { BsSearch,  BsQuestionCircle } from 'react-icons/bs';
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";
import { toast } from 'react-toastify';
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils';



const CaptacaoFiltro = ({ alterarFiltro }: any) => {

    const [filtroModalidade, setFiltroModalidade] = useState<string[]>(['']);
    const [filtroUf, setFiltroUf] = useState<string[] | any>([]);
    const [instrumentoConvocatorio, setInstrumentoConvocatorio] = useState<string[]>(['']);
    const [filtoEsfera, setFiltroEsfera] = useState<string[]>(['']);
    const [filtroMontado, setFiltroMontado] = useState<string>('');
    const [isHovering, setIsHovering] = useState(true);
    const [palavraChave, setPalavraChave] = useState<string>('');


    enum Filtro {
        MODALIDADE = 'modalidade',
        UF = 'uf',
        INSTRUMENTO = 'instrumento',
        ESFERA = 'esfera',
        IDLE = 'idle'
    }

    const [filtroAtual, setFiltroAtual] = useState<Filtro>(Filtro.IDLE);
    const [hepFiltro, setHelpFiltro] = useState<boolean>(false)

    const modalidadeFiltro = (e: any) => {
        if (filtroModalidade.includes(e.target.id)) {
            const filtroTemp = filtroModalidade.filter((i: any) => {
                return i !== e.target.id
            });
            setFiltroModalidade(filtroTemp);
            return;
        }
        setFiltroModalidade([...filtroModalidade, e.target.id]);
    }

    const ufFiltro = (e: any) => {
        if (filtroUf.includes(e.target.id)) {
            const filtroTemp = filtroUf.filter((i: any) => {
                return i !== e.target.id
            });
            setFiltroUf(filtroTemp);
            return;
        }
        setFiltroUf([...filtroUf, e.target.id]);
    }

    const instrumentoConvFiltro = (e: any) => {
        if (instrumentoConvocatorio.includes(e.target.id)) {
            const filtroTemp = instrumentoConvocatorio.filter((i: any) => {
                return i !== e.target.id
            });
            setInstrumentoConvocatorio(filtroTemp);
            return;
        }
        setInstrumentoConvocatorio([...instrumentoConvocatorio, e.target.id]);
    }

    const handleEsferaFiltro = (e: any) => {
        if (filtoEsfera.includes(e.target.id)) {
            const filtroTemp = filtoEsfera.filter((i: any) => {
                return i !== e.target.id
            });
            setFiltroEsfera(filtroTemp);
            return;
        }
        setFiltroEsfera([...filtoEsfera, e.target.id]);
        
    }
    

   const {getCaptacaoAutomatica} =  useReactQueryCaptacao();

     const queryClent = useQueryClient();
    const alterarFiltroMutaton = useMutation({
        mutationFn: getCaptacaoAutomatica,
        onMutate: (config)=>{

        },
        onSuccess: async(response)=>{
          console.log('Response da atualizaçãoo do filtro: ', response);
          queryClent.invalidateQueries({queryKey: ['rqCaptacao']});
          queryClent.refetchQueries({queryKey: ['rqCaptacao']});
        },
        onError: (error)=>{
            console.error(error.message)
        },
        onSettled: (data)=>{

        }
    })


    const empresaSelecionada = localStorage.getItem('empresaSelecionada');
    //--
    const limparFiltro = (e: any) => {
        e.preventDefault();
        setFiltroModalidade([]);
        setFiltroUf([]);
        setInstrumentoConvocatorio([]);
        setFiltroEsfera([]);
        alterarFiltro('');


        const cnaePrincipal = localStorage.getItem('cnaePrincipal')
    alterarFiltroMutaton.mutate({descrição: cnaePrincipal as string, pagina: 2000})        
        localStorage.removeItem(`${empresaSelecionada}-filtro`);
    }

    const handleFitrarProcesso = (save: boolean) => {

        if(save){
            toast.success('Filtro salvo para busca automática!')
        }


        let filtroUfString = '';
        if (filtroUf && filtroUf.length >= 1) {
            filtroUfString = filtroUf.reduce((sum: any, atual: any) => {
                return atual + "%7C" + sum
            });
            setFiltroMontado(`&ufs=${filtroUfString}`);
        }

        let filtroModalidadeString = '';
        if (filtroModalidade && filtroModalidade.length >= 1) {
            filtroModalidadeString = filtroModalidade.reduce((sum: any, atual: any) => {
                return atual + "%7C" + sum
            });
        }

        let filtroInstConvString = '';
        if (instrumentoConvocatorio && instrumentoConvocatorio.length >= 1) {
            filtroInstConvString = instrumentoConvocatorio.reduce((sum: any, atual: any) => {
                return atual + "%7C" + sum
            });
        }

        let filtroEsferaConvString = '';
        if (filtoEsfera && filtoEsfera.length >= 1) {
            filtroEsferaConvString = filtoEsfera.reduce((sum: any, atual: any) => {
                return atual + "%7C" + sum
            });
        }

        alterarFiltro(`${palavraChave}&ufs=${filtroUfString}&modalidades=${filtroModalidadeString}&tipos=${filtroInstConvString}&esferas=${filtroEsferaConvString}`, save);

    }

    

    const inputField = 'bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#ff8027] focus:border-[#ff8027] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-orange-500 dark:focus:border-orange-500';
    const labelField = 'block mb-2 text-sm font-medium text-gray-900 dark:text-white';
    const button = 'cursor-pointer text-white inline-flex items-center bg-[#ff8027] hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800 h-fit';
    const closeButton = 'cursor-pointer text-white inline-flex items-center bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 h-fit';

    return (
        <div className='w-full min-h-screen h-fit px-4 flex'>
            <div className='bg-white dark:bg-gray-700 rounded-md flex flex-col gap-4 h-full justify-between p-4'>
                <div className={`flex items-center ${inputField}`}>
                    <BsSearch className='text-xl text-gray-500 dark:text-gray-400 mr-2' />
                    <input
                        className={`w-full ${inputField}`}
                        type='text'
                        placeholder='Palavra-chave'
                        value={palavraChave}
                        onChange={(e) => setPalavraChave(e.target.value)}
                    />
                </div>

                {/* Modalidade de contratação */}
                <div className='relative shadow-lg rounded-lg p-1'>
                    <label className='text-lg font-semibold text-gray-900 dark:text-white'>Modalidade da contratação</label>
                    <div className='rounded-sm relative'>
                        <label
                            className='opacity-85 inline-flex w-full text-lg cursor-pointer hover:opacity-100 justify-between'
                            htmlFor='modalidadesCb2'
                            onClick={() => (filtroAtual === Filtro.MODALIDADE ? setFiltroAtual(Filtro.IDLE) : setFiltroAtual(Filtro.MODALIDADE))}
                        >
                            Selecione as categorias
                            <svg
                                className={`w-3 h-3 ms-auto ${filtroAtual === Filtro.MODALIDADE || isHovering ? 'inline' : 'hidden'}`}
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 10 6'
                            >
                                <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 4 4 4-4' />
                            </svg>
                        </label>
                        <input type='checkbox' className='modalidadesCb2' id='modalidadesCb2' checked={filtroAtual === Filtro.MODALIDADE} />

                        {filtroAtual === Filtro.MODALIDADE && (
                            <div className='absolute top-full left-0 mt-1 w-full bg-gray-200 dark:bg-gray-800 rounded-md shadow-lg z-10'>
                                <div className='max-h-52 overflow-y-auto p-2'>
                                    <label onClick={modalidadeFiltro} id='6' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroModalidade.includes('6') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Pregão eletrônico
                                    </label>
                                    <label onClick={modalidadeFiltro} id='8' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroModalidade.includes('8') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Dispensa
                                    </label>
                                    <label onClick={modalidadeFiltro} id='9' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroModalidade.includes('9') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Dispensa de licitação
                                    </label>
                                    <label onClick={modalidadeFiltro} id='7' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroModalidade.includes('7') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Pregão presencial
                                    </label>
                                    <label onClick={modalidadeFiltro} id='12' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroModalidade.includes('12') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Credenciamento
                                    </label>
                                    <label onClick={modalidadeFiltro} id='4' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroModalidade.includes('4') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Concorrência eletrônica
                                    </label>
                                    <label onClick={modalidadeFiltro} id='5' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroModalidade.includes('5') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Concorrência presencial
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* UFs */}
                <div className='relative shadow-lg rounded-lg p-1'>
                    <label className='text-lg font-semibold text-gray-900 dark:text-white'>UFs</label>
                    <div className='rounded-sm relative'>
                        <label
                            className='opacity-85 inline-flex w-full text-lg cursor-pointer hover:opacity-100 justify-between'
                            htmlFor='modalidadesCb'
                            onClick={() => (filtroAtual === Filtro.UF ? setFiltroAtual(Filtro.IDLE) : setFiltroAtual(Filtro.UF))}
                        >
                            {filtroUf.length <= 0
                                ? 'Selecione as categorias'
                                : filtroUf.length === 1
                                    ? `(${filtroUf[0]})`
                                    : `(${filtroUf[0]}) + ${filtroUf.length - 1}`}
                            <svg
                                className={`w-3 h-3 ms-auto ${filtroAtual === Filtro.UF || isHovering ? 'inline' : 'hidden'}`}
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 10 6'
                            >
                                <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 4 4 4-4' />
                            </svg>
                        </label>
                        <input type='checkbox' className='modalidadesCb' id='modalidadesCb' checked={filtroAtual === Filtro.UF} />

                        {filtroAtual === Filtro.UF && (
                            <div className='absolute top-full left-0 mt-1 w-full bg-gray-200 rounded-md shadow-lg z-10'>
                                <div className='max-h-52 overflow-y-auto p-2'>
                                    <label onClick={ufFiltro} id='MG' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('MG') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        MG
                                    </label>
                                    <label onClick={ufFiltro} id='SP' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('SP') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        SP
                                    </label>
                                    <label onClick={ufFiltro} id='CE' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('CE') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        CE
                                    </label>
                                    <label onClick={ufFiltro} id='BA' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('BA') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        BA
                                    </label>
                                    <label onClick={ufFiltro} id='RS' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('RS') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        RS
                                    </label>
                                    <label onClick={ufFiltro} id='GO' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('GO') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        GO
                                    </label>
                                    <label onClick={ufFiltro} id='PR' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('PR') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        PR
                                    </label>
                                    <label onClick={ufFiltro} id='PB' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('PB') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        PB
                                    </label>
                                    <label onClick={ufFiltro} id='SC' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('SC') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        SC
                                    </label>
                                    <label onClick={ufFiltro} id='PE' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('PE') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        PE
                                    </label>
                                    <label onClick={ufFiltro} id='RJ' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('RJ') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        RJ
                                    </label>
                                    <label onClick={ufFiltro} id='RN' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('RN') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        RN
                                    </label>
                                    <label onClick={ufFiltro} id='PA' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('PA') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        PA
                                    </label>
                                    <label onClick={ufFiltro} id='PI' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('PI') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        PI
                                    </label>
                                    <label onClick={ufFiltro} id='MA' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('MA') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        MA
                                    </label>
                                    <label onClick={ufFiltro} id='ES' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('ES') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        ES
                                    </label>
                                    <label onClick={ufFiltro} id='MT' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('MT') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        MT
                                    </label>
                                    <label onClick={ufFiltro} id='TO' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('TO') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        TO
                                    </label>
                                    <label onClick={ufFiltro} id='AM' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('AM') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        AM
                                    </label>
                                    <label onClick={ufFiltro} id='DF' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('DF') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        DF
                                    </label>
                                    <label onClick={ufFiltro} id='SE' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('SE') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        SE
                                    </label>
                                    <label onClick={ufFiltro} id='MF' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('MF') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        MF
                                    </label>
                                    <label onClick={ufFiltro} id='AL' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('AL') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        AL
                                    </label>
                                    <label onClick={ufFiltro} id='RO' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('RO') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        RO
                                    </label>
                                    <label onClick={ufFiltro} id='RR' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('RR') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        RR
                                    </label>
                                    <label onClick={ufFiltro} id='AP' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('AP') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        AP
                                    </label>
                                    <label onClick={ufFiltro} id='AC' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtroUf.includes('AC') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        AC
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Instrumento convocatório */}
                <div className='relative shadow-lg rounded-lg p-1'>
                    <label className='text-lg font-semibold'>Instrumento convocatório</label>
                    <div className='rounded-sm relative'>
                        <label
                            className='opacity-85 inline-flex w-full text-lg cursor-pointer hover:opacity-100 justify-between'
                            htmlFor='modalidadesCb3'
                            onClick={() => (filtroAtual === Filtro.INSTRUMENTO ? setFiltroAtual(Filtro.IDLE) : setFiltroAtual(Filtro.INSTRUMENTO))}
                        >
                            Selecione as categorias
                            <svg
                                className={`w-3 h-3 ms-auto ${filtroAtual === Filtro.INSTRUMENTO || isHovering ? 'inline' : 'hidden'}`}
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 10 6'
                            >
                                <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 4 4 4-4' />
                            </svg>
                        </label>
                        <input type='checkbox' className='modalidadesCb3' id='modalidadesCb3' checked={filtroAtual === Filtro.INSTRUMENTO} />

                        {filtroAtual === Filtro.INSTRUMENTO && (
                            <div className='absolute top-full left-0 mt-1 w-full bg-gray-200 rounded-md shadow-lg z-10'>
                                <div className='max-h-52 overflow-y-auto p-2'>
                                    <label onClick={instrumentoConvFiltro} id='1' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!instrumentoConvocatorio.includes('1') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Edital
                                    </label>
                                    <label onClick={instrumentoConvFiltro} id='2' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!instrumentoConvocatorio.includes('2') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Aviso de contratação direta
                                    </label>
                                    <label onClick={instrumentoConvFiltro} id='4' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!instrumentoConvocatorio.includes('4') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Edital de chamamento público
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Esfera */}
                <div className='relative shadow-lg rounded-lg p-1'>
                    <label className='text-lg font-semibold'>Esfera</label>
                    <div className='rounded-sm relative'>
                        <label
                            className='opacity-85 inline-flex w-full text-lg cursor-pointer hover:opacity-100 justify-between'
                            htmlFor='modalidadesCb4'
                            onClick={() => (filtroAtual === Filtro.ESFERA ? setFiltroAtual(Filtro.IDLE) : setFiltroAtual(Filtro.ESFERA))}
                        >
                            Selecione a esfera
                            <svg
                                className={`w-3 h-3 ms-auto ${filtroAtual === Filtro.ESFERA || isHovering ? 'inline' : 'hidden'}`}
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 10 6'
                            >
                                <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 4 4 4-4' />
                            </svg>
                        </label>
                        <input type='checkbox' className='modalidadesCb4' id='modalidadesCb4' checked={filtroAtual === Filtro.ESFERA} />

                        {filtroAtual === Filtro.ESFERA && (
                            <div className='absolute top-full left-0 mt-1 w-full bg-gray-200 rounded-md shadow-lg z-10'>
                                <div className='max-h-52 overflow-y-auto p-2'>
                                    <label onClick={handleEsferaFiltro} id='M' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtoEsfera.includes('M') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Municipal
                                    </label>
                                    <label onClick={handleEsferaFiltro} id='E' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtoEsfera.includes('E') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Estadual
                                    </label>
                                    <label onClick={handleEsferaFiltro} id='F' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtoEsfera.includes('F') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Federal
                                    </label>
                                    <label onClick={handleEsferaFiltro} id='D' className='flex items-center cursor-pointer opacity-85 hover:opacity-100'>
                                        {!filtoEsfera.includes('D') ? <MdOutlineCheckBoxOutlineBlank className='mr-2' /> : <MdOutlineCheckBox className='mr-2' />}
                                        Distrital
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className='flex flex-row justify-between w-full p-2'>
                    <button className={`${closeButton}`} onClick={limparFiltro}> Limpar </button>
                    <button className={`${button}`} onClick={() => handleFitrarProcesso(false)}> Pesquisar </button>
                </div>

                <div className='relative flex flex-row gap-2'>
                    <button className={`${button} bg-green-500 inline-flex justify-center gap-2 px-4 w-44`} onClick={()=> handleFitrarProcesso(true)}> Aplicar e salvar filtro </button>
                
                    <div className='relative'>
                        
                        <BsQuestionCircle className='mt-2 w-fit cursor-pointer' onClick={()=> setHelpFiltro(current => !current)}/>
                        
                            {
                                hepFiltro &&
                                <div className='absolute top-5 left-5 z-50 max-w-48 w-48'>
                                <div className='bg-gray-100 rounded-lg p-1'>
                                    <p className='text-[0.7rem] font-semibold'> Ao clicar neste botão, o filtro desta pesquisa será utilizado nas buscas automáticas de editais.</p>
                                </div>
                            </div>}


                        </div>
                    </div>

            </div>

        </div>
    )
}

export default CaptacaoFiltro;
