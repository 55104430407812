import axios from "axios"
import { useState } from "react"
import { RegistrarProcesso, SituaçãoInterna, TListarProcessos, typeProcessoContato, typeRegistarProcesso } from "../types/PortalTypes";

export const useReactQueryProcessos = ()=>{

    const [fileUpload, setFileUpload] =useState<number | string>('');

    const baseUrl: any = process.env.REACT_APP_PRIVATE_API_URL

    


    const registrarProcesso = async(data: typeRegistarProcesso)=>{
        
        const contatosFiltrados = Object.fromEntries(Object.entries(data.contatos).filter(([key, value])=> value !== ""));

        const url = `${baseUrl}/api/processos/registrar-processo?token=${data.token}&empresaid=${data.empresaid}`;

        try{
        
            const res: any = await axios({
            url: url,
            params:{
                resumo: {
                    contatos: contatosFiltrados,
                    orgaopublicoLicitante: data.data.orgaopublicoLicitante,
                    CnpjLicitante: data.data.CnpjLicitante,
                    empresaSelecionada: data.empresaSelecionada,
                    usuarioSelecionado: data.usuarioSelecionado,
                    portalSelecionado: data.portalSelecionado,
                    enderecoLicitante: data.data.enderecoLicitante,
                    cidadeLicitante: data.data.cidadeLicitante,
                    cepLicitante: data.data.cepLicitante,
                    foneLicitante: data.data.foneLicitante,
                    emailLicitante: data.data.emailLicitante,
                    siteLicitante: data.data.siteLicitante,
                    modalidade: data.data.modalidade,
                    numero: data.data.numero,
                    processo: data.data.processo,
                    tipo: data.data.tipo,
                    srp: data.data.srp,
                    objeto: data.data.objeto,
                    valorestimado: data.data.valorestimado,
                    LocalsessaoPublica: data.data.LocalsessaoPublica,
                    situacaoInterna: data.data.situacaoInterna,
                    exclusividade: data.data.exclusividade,
                    DataHoraPublicacao: data.data.DataHoraPublicacao,
                    DataHoraLimiteEsclarecimento: data.data.DataHoraLimiteEsclarecimento,
                    DataHoraImpugnacao: data.data.DataHoraImpugnacao,
                    DataHoraContraProposta: data.data.DataHoraContraProposta,
                    DataHoraAberturaLicitacao: data.data.DataHoraAberturaLicitacao,
                    DataHoraContinuacaoLicitacao: data.data.DataHoraContinuacaoLicitacao,
                    DataHoraIntencaoRecurso: data.data.DataHoraIntencaoRecurso,
                    DataHoraRecurso: data.data.DataHoraRecurso,
                    DataHoraLimiteContrarrazao: data.data.DataHoraLimiteContrarrazao,
                    DataHoraLimiteJulgamentoRecurso: data.data.DataHoraLimiteJulgamentoRecurso,
                    DataHoraHomologacao: data.data.DataHoraHomologacao,
                    DataHoraAdjudicação: data.data.DataHoraAdjudicação,
                    DataMandatoS: data.data.DataMandatoS,
                    captacaoPncp: data.data.captacaoPncp,
                    LinkArquivosPncp: data.data.LinkArquivosPncp,
                    LinkItensPncp: data.data.LinkItensPncp

                    
                    
                           
        
                }

            },
            method: 'POST',
            headers:{
                'Content-Type': 'multipart/form-data'
            },
            data: data.Arquivo,
            onUploadProgress: (p: any)=>{
                const completedUpload = p.loaded/p.total ;
                setFileUpload(completedUpload)
            }
            });
    
            const response = await res.data;
    
           return response;
    
            }
            catch(err){
                console.error(err);
                return null
    
            }

        }

 




        const listarProcessos = async(data: TListarProcessos)=>{
            const token = localStorage.getItem("token")
            const url = `${baseUrl}/api/processos/listar-processo?empresaid=${data.empresaid}&pageParam=${data.pageParam}&filtro=${data.filtro}&token=${token}`;

            try {
                const res =await fetch(url);
                const response = await res.json();
                return response;
            } catch (error) {
                console.error(error);
                return null;
                
            }
        }

   

        const editarProcessos = async(data: typeRegistarProcesso )=>{

            let contatosFiltrados =''
            if(data.contatos){
                Object.fromEntries(Object.entries(data.data.contatos).filter(([key, value])=> value !== ""));
            }
            

            const url = `${baseUrl}/api/processos/editar-processo?token=${data.token}&processoid=${data.processoid}&Files=${data.File}`;

    
            try{
            
                const res: any = await axios({
                url: url,
                params:{
                    resumo: {
                        contatos: contatosFiltrados,
                        orgaopublicoLicitante: data.data.orgaopublicoLicitante,
                        CnpjLicitante: data.data.CnpjLicitante,
                        empresaSelecionada: data.data.empresaSelecionada,
                        usuarioSelecionado: data.data.usuarioSelecionado,
                        enderecoLicitante: data.data.enderecoLicitante,
                        cidadeLicitante: data.data.cidadeLicitante,
                        cepLicitante: data.data.cepLicitante,
                        foneLicitante: data.data.foneLicitante,
                        emailLicitante: data.data.emailLicitante,
                        siteLicitante: data.data.siteLicitante,
                        modalidade: data.data.modalidade,
                        numero: data.data.numero,
                        processo: data.data.processo,
                        tipo: data.data.tipo,
                        srp: data.data.srp,
                        objeto: data.data.objeto,
                        valorestimado: data.data.valorestimado,
                        LocalsessaoPublica: data.data.LocalsessaoPublica,
                        portalSelecionado: data.data.portalSelecionado,
                        situacaoInterna: data.data.situacaoInterna,
                        exclusividade: data.data.exclusividade,
                        DataHoraPublicacao: data.data.DataHoraPublicacao,
                        DataHoraLimiteEsclarecimento: data.data.DataHoraLimiteEsclarecimento,
                        DataHoraImpugnacao: data.data.DataHoraImpugnacao,
                        DataHoraContraProposta: data.data.DataHoraContraProposta,
                        DataHoraAberturaLicitacao: data.data.DataHoraAberturaLicitacao,
                        DataHoraContinuacaoLicitacao: data.data.DataHoraContinuacaoLicitacao,
                        DataHoraIntencaoRecurso: data.data.DataHoraIntencaoRecurso,
                        DataHoraRecurso: data.data.DataHoraRecurso,
                        DataHoraLimiteContrarrazao: data.data.DataHoraLimiteContrarrazao,
                        DataHoraLimiteJulgamentoRecurso: data.data.DataHoraLimiteJulgamentoRecurso,
                        DataHoraHomologacao: data.data.DataHoraHomologacao,
                        DataHoraAdjudicação: data.data.DataHoraAdjudicação,
                        DataMandatoS: data.data.DataMandatoS,
                        DataHoraDiligencia: data.data.DataHoraDiligencia,
                        DataHoraApresentação: data.data.DataHoraApresentação,
                        

                               
            
                    }
    
                },
                method: 'POST',
                headers:{
                    'Content-Type': 'multipart/form-data'
                },
                data: data.data.file,
                onUploadProgress: (p: any)=>{
                    const completedUpload = p.loaded/p.total ;
                    setFileUpload(completedUpload)
                }
                });
        
                const response = await res.data;
        
               return response;
        
                }
                catch(err){
                    console.error(err);
                    return null
        
                }

        }

   

        const processoContatos =async(data: typeProcessoContato)=>{
            const url = `${baseUrl}/api/processos/contato-processo?token=${data.token}&processoid=${data.processoid}&action=${data.action}`;

            try {

                const res = await fetch(url, {
                    method: 'POST',
                    headers:{
                        'content-type':'application/json'
                    },
                    body: JSON.stringify({contato: data.contato})
                })
                
                const response = await res.json();
                return response;

            } catch (error) {
                console.error(error);
                return null;
            }

        }

        type typeApagarProcessos ={
            token: string, 
            processoid: string, 
            processo: any
        }

        const apagarProcesso = async(data: typeApagarProcessos)=>{
            const url = `${baseUrl}/api/processos/apagar-processo?token=${data.token}&processoid=${data.processoid}`;

            try {
                const res = await fetch(url,{
                    method: 'POST',
                    headers:{
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data.processo)
                });
                const response = await res.json();
                return response;
            } catch (error) {
                console.error(error);
                return null;                
                
            }
        }

        type typePastasProcessos ={
            processoId: string ,
            nomePasta: string ,
            dataInicio: string ,
            dataVencimento: string ,
            categoria: string ,
            valor: string ,
            status: string ,
            LinkAws?: string
        }

        type typeCriarPastaProcesso ={
            token: string,
            processoid: string,
            pastaid?: string,
            data?: typePastasProcessos,
            action: 'nova' | 'editar' | 'apagar',
            empresaid: string
        }

        const processosPastas = async(data: typeCriarPastaProcesso)=>{


            const url = `${baseUrl}/api/processos/salvar-pasta?token=${data.token}&processoid=${data.processoid}&pastaid=${data.pastaid}&action=${data.action}&empresaid=${data.empresaid}`;

            try {
                const res = await fetch(url, {
                    method: 'POST',
                    headers:{
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({data: data.data})
                })
                const response = await res.json();
                return response;
            } catch (error) {
                console.error(error);
                return null

            }
        }

        type typeGetPastasArquivos ={
            token: string,
            processoid?: string,
            pastaid?: string
        }

        const listarPastasProcessos = async(data: typeGetPastasArquivos)=>{
            const url = `${baseUrl}/api/processos/listar-pastas?token=${data.token}&processoid=${data.processoid}`;        
        
            try {
                const res = await fetch(url);
                const response = await res.json();
                return response;
            } catch (error) {
                console.error(error);
                return null;
            }
        
        }

        const apagarPasta = async(data: typeGetPastasArquivos)=>{
            const url = `${baseUrl}/api/processos/apagar-pasta?token=${data.token}&pastaid=${data.pastaid}`;

            try {
                const res = await fetch(url);
                const response = await res.json();
                return response
            } catch (error) {
                console.error(error)
                return null
            }

        }
        
        type typeArquivoProcessos ={
            nomeDoArquivo:  string, 
            dataInicio:     string, 
            dataVencimento: string, 
            categoria:      string, 
            valor:          string, 
            arquivoPath?:    string, 
            processoId:     string,
            LinkAws?:        string,
            file?: HTMLFormElement | any,
            Size?:  number,
            empresaid: string
        }

        type typeSalvarArquivoProcessos ={
            token: string,
            processoid: string,
            pastaid?: string,
            data: typeArquivoProcessos,
            action: 'novo'|'novo-pasta' |'editar'|'apagar',
            Arquivo: HTMLFormElement | any
        }

        const salavarArquivoProcesso =async(data: typeSalvarArquivoProcessos)=>{
            const url = `${baseUrl}/api/processos/arquivo-processo?token=${data.token}&action=${data.action}&processoid=${data.processoid}&pastaid=${data.pastaid}`;

            try{
        
                const res: any = await axios({
                url: url,
                params:{
                    resumo: {
                        nomeDoArquivo  : data.data.nomeDoArquivo  ,  
                        dataInicio     : data.data.dataInicio     ,     
                        dataVencimento : data.data.dataVencimento , 
                        categoria      : data.data.categoria      ,      
                        valor          : data.data.valor          ,          
                        arquivoPath    : data.data.arquivoPath    ,    
                        processoId     : data.data.processoId     ,     
                        LinkAws        : data.data.LinkAws        ,        
                        file           : data.Arquivo           , 
                        Size: data.data.Size,
                        empresaid: data.data.empresaid
                
                    }
    
                },
                method: 'POST',
                headers:{
                    'Content-Type': 'multipart/form-data'
                },
                data: data.Arquivo,
                onUploadProgress: (p: any)=>{
                    const completedUpload = p.loaded/p.total ;
                    setFileUpload(completedUpload)
                }
                });
        
                const response = await res.data;
        
               return response;
        
                }
                catch(err){
                    console.error(err);
                    return null
        
                }
        }

        type typeApagarArquivosProcesso={
            token: string,
            arquivoid: string
        }

        const apagarArquivosProcessos = async(data: typeApagarArquivosProcesso)=>{
            const url = `${baseUrl}/api/processos/apagar-arquivo-processo?token=${data.token}&arquivoid=${data.arquivoid}`;

            try {
                const res = await fetch(url);
                const response = await res.json();
                return response;
            } catch (error) {
                console.error(error);
                return null;
            }
        }

        type typeEditarStatusProcesso ={
            token: string, 
            processoid: string,
            data:{
                situacaoInterna?: 'Aprovado'|'Desaprovado',
                importante?: boolean
            }
        }

        const editarStatusProcesso =async(data: typeEditarStatusProcesso)=>{
            const url = `${baseUrl}/api/processos/editar-status-processo?token=${data.token}&processoid=${data.processoid}`;

            try {
                const res =  await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'Application/Json'
                    },
                    body: JSON.stringify(data)
                });

                const response = await res.json();
                return response;
                
            } catch (error) {
                console.error(error);
                return null;
            }
        }


        type typeEditarLogoProcesso ={
            token: string,
            processoid: string,
            Arquivo: HTMLFormElement | any
        }

        const editarLogoProcesso = async(data: typeEditarLogoProcesso) =>{
            const url = `${baseUrl}/api/processos/editar-logo-processo?token=${data.token}&processoid=${data.processoid}`;

            try {

                const res: any = await axios({
                    url: url,
                    method: 'POST',
                    headers:{
                        'Content-Type': 'multipart/form-data'
                    },
                    data: data.Arquivo,
                    onUploadProgress: (p: any)=>{
                        const completedUpload = p.loaded/p.total ;
                        setFileUpload(completedUpload)
                    }
                    });
    
                const response = await res.data;
                return response;
                
            } catch (error) {
                console.error(error);
                return null;
            }


        }

        const getProcessosInfo = async(empresaid: string, token: string)=>{
            const url = `${baseUrl}/api/processos/processos-total?empresaid=${empresaid}&token=${token}`;

            try {

                const res = await fetch(url);
                const response  = await res.json();
                return response;
                
            } catch (error) {
                console.error(error);
                return null;
            }
 

        }

        type TBuscarProcessos = {
            token: string,
            empresaid: string,
            body: {
                texto?: string,
                situacaoInterna?: SituaçãoInterna  
            },
            pageParam: number
            
        }

        const buscarProcessos = async(data: TBuscarProcessos)=>{
            const url = `${baseUrl}/api/processos/processos-buscar?empresaid=${data.empresaid}&token=${data.token}&pageParam=${data.pageParam}`;
            
            try {
                const res= await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'Application/Json'
                    },
                    body: JSON.stringify(data.body)
                });
                const response = await res.json();
                return response;
                
            } catch (error) {
                console.error(error);
                return null;
            }
        
        }
        
        return {
                registrarProcesso,
                listarProcessos,
                editarProcessos,
                fileUpload,
                processoContatos,
                apagarProcesso,
                processosPastas,
                listarPastasProcessos,
                apagarPasta,
                salavarArquivoProcesso,
                apagarArquivosProcessos,
                editarStatusProcesso,
                editarLogoProcesso,
                getProcessosInfo,
                buscarProcessos
        }
}