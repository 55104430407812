import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react'
import { BsExclamation, BsExclamationCircle, BsPlus, BsX } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useArquivosUtils } from '../../Utils/ReactQuery/getArquivos';
import { ModoAbrirArquivo } from '../../Utils/types/pastasTypes';

const EditarArquivo = ({arquivo, fechar, displayArquivo}: any) => {


    const [loading, setLoading] = useState<boolean>(false);
    const [concluido, setConcluido] = useState<boolean>(false);
    const [nomeArquivo, setNomeArquivo] = useState<string>('');
    const [descrição, setDescrição] = useState<string>('');


    const {editarArquivo} = useArquivosUtils()
    const queryClient = useQueryClient();

    const token = localStorage.getItem('token');
    const empresaid = localStorage.getItem('empresaSelecionada');


    const editarArquivoMutation =useMutation({
        mutationFn: editarArquivo,
        onMutate: async(config)=>{
            // console.log('Mutação inicida!');
            setLoading(true);
        },
        onSuccess: async(response)=>{
            // console.log('Mutação bem sucedida!', response);

            queryClient.invalidateQueries({queryKey: ['rqPastas']});
            queryClient.refetchQueries({queryKey: ["rqPastas"]});

            if(response.status === 1){
                toast.success(`${response.msg}`);
                setNomeArquivo('');
                setDescrição('');
                setConcluido(true);
            }
            else{
                toast.error(`${response.msg}`);
            }
        }, onError: async(error)=>{
            console.error(error.message);
            toast.error(error.message);
        },
        onSettled: async(data)=>{
            // console.log('Mutação concluída!')
            setLoading(false);
        }

    })


    const handleEditarArquivo=(e: any)=>{
        e.preventDefault();

        if(descrição.length <= 0 && nomeArquivo.length <=0){
            toast.error('Não são permitidos envios vazios!');
            return;
        }

        editarArquivoMutation.mutate({ token: token as string, empresaid: empresaid as string, Arquivo: {Descrição: descrição as string, ArquivoId: arquivo._id}});
    }

    if(concluido){
        return(
            <div id="crud-modal" tabIndex ={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col justify-center items-center gap-5 min-h-52">
                        <label className='text-3xl font-semibold'>Informações do arquivo editadas com sucesso!</label>

                        <span className='text-3xl'><BsExclamationCircle/></span>

                        <button onClick={fechar} className="text-white inline-flex items-center bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-800 dark:focus:ring-gray-800"> Fechar </button>

                    </div>
                </div>
            </div>

        )
    }


  return (
    <div id="crud-modal" tabIndex ={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full ">
        <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            
            <span className='w-full inline-flex justify-end cursor-pointer' onClick={fechar}> <BsX className='text-3xl mr-2 mt-2'/> </span>
                
                <form className="p-4 md:p-5" onSubmit={handleEditarArquivo}>

                    <div className="grid gap-4 mb-4 grid-cols-2">
                        
                        <div className="col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome do arquivo</label>
                            <input type="text" name="NomeArquivo" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={arquivo.NomeArquivo? arquivo.NomeArquivo : 'Nome do arquivo'} onChange={(e)=> setNomeArquivo(e.target.value)} readOnly ={true} value ={displayArquivo === ModoAbrirArquivo.EDITAR ? nomeArquivo : arquivo.NomeArquivo}/>
                        </div>


                        <div className="col-span-2">
                            <div className="col-span-2">
                            <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Descrição do arquivo</label>
                            <textarea id="description" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={arquivo.Descrição ? `${arquivo.Descrição}` :"Descreva o conteudo do arquivo"} name='Descrição'  onChange={(e)=> setDescrição(e.target.value)} readOnly={displayArquivo === ModoAbrirArquivo.EDITAR ? false: true} value={displayArquivo === ModoAbrirArquivo.EDITAR ? descrição : arquivo.Descrição }></textarea>                    
                            </div>
                        </div>



                    </div>

                    {
                        displayArquivo === ModoAbrirArquivo.VISUALIZAR &&
                        <div className='flex flex-row text-sm gap-2'>
                            <label > última data de modificação: </label>
                            <label>{arquivo.UltimaModificação}</label>
                        </div>
                    }

        
                    {
                        displayArquivo === ModoAbrirArquivo.EDITAR &&
                        <div className='flex flex-row gap-2'>
                            <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <BsPlus className='text-xl'/>
                                Salvar informaçõs do arquivo
                            </button>
                        </div>
                    }
                    


                </form>
            </div>
        </div>
    </div> 
  )
}

export default EditarArquivo