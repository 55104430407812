import SideNavConfig from '../Components/PerfilComponents/SideNavConfig';
import Config from '../Components/PerfilComponents/Config'
import { useEffect, useLayoutEffect, useState } from 'react';
import { TabActive } from '../Utils/types/tabTypes';
import { useParams, useSearchParams } from 'react-router-dom';
import SideNavWrapper from '../Components/PortalComponents/SideNavWrapper';
import { usePreChecagem } from '../Utils/usePreChecagem';


type Props = {}

const Perfil = ({user, empresaData}: any ) => {


  const [searchParams, setSearchParams] = useSearchParams()

  // //~~MANUTENÇÃO~~//
  // useEffect(()=>{
  //   console.log('Empresa data: ', empresaData)
  //   console.log('User data: ', user)
  // },[empresaData, user])
  // //~~MANUTENÇÃO~~//


  const pageParam = searchParams.get("area")

  const [activeTab, setActiveTab] = useState<String>(TabActive.USER);

  const changeTab =(tab: string)=>{

    // localStorage.setItem('perfilTab', tab as string);
    // console.log(tab);
    switch(pageParam){
      case 'user':
        setActiveTab(TabActive.USER);
        break;
      case 'empresa':
        setActiveTab(TabActive.EMPRESA);
        break;
      case 'pagamento':
        setActiveTab(TabActive.PAGAMENTO);
        break;
      default:
        setActiveTab(TabActive.USER);
      }   
  }

  useLayoutEffect(()=>{
    // const tab = localStorage.getItem('perfilTab');
    switch(pageParam){
      case 'usuario':
        setActiveTab(TabActive.USER);
        break;
      case 'empresa':
        setActiveTab(TabActive.EMPRESA);
        break;
      case 'pagamento':
        setActiveTab(TabActive.PAGAMENTO);
        break;
      case 'conta':
        setActiveTab(TabActive.CONTA);
        break;
      default:
        setActiveTab(TabActive.USER);
      }  
  })


  return (
    <div className='min-h-screen w-full h-fit text-black dark:text-white bg-gray-50 dark:bg-gray-900'>

        <div className='grid grid-cols-[100px_minmax(900px,_1fr)]'>
            {/* <SideNavConfig changeTab ={changeTab} activeTab ={activeTab} /> */}
            <SideNavWrapper />
            <Config activeTab ={activeTab} user ={user} empresaData ={empresaData} />
        </div>

        

    </div>
  )
}

export default Perfil