import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas'
import EmpresaConfig from './EmpresaConfig'
import PagamentoConfig from './PagamentoConfig'
import styles from './Perfil.module.css'
import UserConfig from './UserConfig'
import CadastroCompletado from './CadastroCompletado'
import ContaConfig from './ContaConfig'



const Config = ({activeTab, user}: any ) => {

  

  const {listarEmpresas, empresasPermitidas} = useReactQueryEmpresas()
  const {data: empresaData, isLoading: empresaisLoading, isError: empresaIsError, isSuccess: empresaIsSuccess} = useQuery({queryFn: ()=> listarEmpresas(user.Acessos.Empresas), queryKey: ['rqEmpresa', user], staleTime: 60000});

  
// Taillwind -------------------------------------
  const activetab = `text-blue-600 dark:text-blue-500 flex items-center space-x-2.5 rtl:space-x-reverse`
  const activeIcon = "flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0 dark:border-blue-500"
  const inactivetab ="flex items-center text-red-500 dark:text-red-400 space-x-2.5 rtl:space-x-reverse";
  const inactiveIcon ="flex items-center justify-center w-8 h-8 border border-red-500 rounded-full shrink-0 dark:border-red-400"


  return (
    <div className={`
    border-1 border-black border-solid  max-w-5xl m-5 mt-20 rounded-lg dark:bg-gray-800 `}>


        <div className={`w-full flex flex-col  justify-center items-center`}>
        {(() => {
          switch(activeTab){
            case 'userTab':
              return <UserConfig user ={user|| null} empresaData = {empresaData}/>
            break
            case 'empresaTab':
              return <EmpresaConfig empresas ={empresaData || null} user ={user || null} />
              break
            case 'pagamentoTab':
              return <PagamentoConfig/>
            case 'conta':
              return <ContaConfig/>
            default:
              return <UserConfig/>
          }
        })()}
        </div>


  
    </div>
  )
}

export default Config