import { Link } from 'react-router-dom';
import { BsEye, BsEyeSlash, BsWhatsapp } from 'react-icons/bs';
import { useMutation } from '@tanstack/react-query';
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser';
import { Suspense, useEffect, useState } from 'react';
import AlertaCadastro from '../Alertas';
import { checkCpfCnpj } from '../../Utils/checkCpfCnpj';
import { useGetAcessos } from '../../Utils/ReactQuery/getAcessos';
import VerificarAcesso from './VerificarAcesso';
import { formatCpfCnpj } from '../../Utils/formatCpfCnpj';
import ErrorPage from '../../Pages/ErrorPage';
import { toast } from 'react-toastify';

const Login = () => {
    const { logIn } = useReactQueryUsers();
    const { listarAcessos } = useGetAcessos();

    const [Senha, setSenha] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [success, setSuccess] = useState<boolean>(false);

    const [acessos, setAcessos] = useState<string[]>([]);
    const [empresas, setEmpresas] = useState<string[]>([]);
    const [acessosVerify, setAcessosVerify] = useState<boolean>(false);
    const [cpfCnpj, setCpfCnpj] = useState<string>('');
    const [cpfCnpjVerify, setCpfCnpjVerify] = useState<boolean | null>(null);
    const [acessoLogIn] = useState<string>('');

    const [errorPage, setErrorPage] = useState(false);
    const [errorPageMsg, setErrorPageMsg] = useState('');

    const close = (e: any) => {
        e.preventDefault();
        setError(false);
        setSuccess(false);
        setAcessosVerify(false);
        setCpfCnpjVerify(null);
        setErrorMsg('');
    };

    const logInMutation = useMutation({
        mutationFn: logIn,
        onMutate: (): any => {
            // console.log('Login mutation iniciada!');
            setLoading(true);
        },
        onSuccess: async (response) => {
            if (response.status === 3) {
                setAcessosVerify(false);
                setError(true);
                setErrorMsg(response.msg);
                return;
            }
            if (response.status === 5) {
                setErrorPage(true);
                setErrorPageMsg(response.msg);
            } else {
                setSuccess(true);
                localStorage.setItem('token', response.token);
                localStorage.setItem('empresaSelecionada', response.empresa);
                localStorage.setItem('empresaPadrão', response.empresa);
                window.location.href = '/portal/bem-vindo';
                setLoading(false);
            }
        },
        onError: async (response) => {
            const err = await response;
            // console.log('Erro: ', err);
            setLoading(false);
            setError(true);
        },
        onSettled: async (response) => {
            // console.log('Mutação concluída!: ', response);
            setLoading(false);
        }
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const formattedValue = formatCpfCnpj(value);
        setCpfCnpj(formattedValue);
    };

    const handleCadastoVerify = async (e: any) => {
        e.preventDefault();

        try {
            if (checkCpfCnpj(cpfCnpj as string)) {
                const res = await listarAcessos({
                    CpfCnpj: formatCpfCnpj(cpfCnpj as string),
                    Senha: Senha
                });

                if (res.status === 3) {
                    setErrorMsg(res.msg);
                    setError(true);
                    toast.error(res.msg);
                    return;
                }
                if (res.Acessos) {
                    setAcessosVerify(true);
                    setAcessos(res.Acessos);
                    setEmpresas(res.Empresas);
                    // console.log('Acesso: ', acessos);
                    return;
                }
            } else {
                setErrorMsg('CPF ou CNPJ inválido!');
            }
        } catch (error) {
            console.error(error);
            setAcessosVerify(false);
            return;
        }
    };



    const getAcessoLogIn = (acl: string) => {
        if (acl && acl.length > 1) {
            try {
                logInMutation.mutate({
                    config: {
                        CpfCnpj: formatCpfCnpj(cpfCnpj as string) as string,
                        Senha: Senha as string,
                        Acesso: acl as string
                    }
                });
                return;
            } catch (error) {
                console.error(error);
                setError(true);
                return;
            }
        }
        return;
    };


    const handleCpfCnpjBlur = () => {
        if (cpfCnpj.length === 14 || cpfCnpj.length === 18) {
            setCpfCnpjVerify(checkCpfCnpj(cpfCnpj));
        } else {
            setCpfCnpjVerify(null);
        }
    };

    if (errorPage) {
        return <ErrorPage message={errorPageMsg} />;
    }

    return (
        <section
            className="flex items-center justify-center h-screen min-h-dvh bg-cover bg-center"
            style={{ backgroundImage: 'url(/background.webp)' }}
        >

            {acessosVerify && acessos ? (
                <Suspense fallback={<p>Carregando ...</p>}>
                    <VerificarAcesso close={close} acessos={acessos} empresas={empresas} acessoLogIn={getAcessoLogIn} loading={loading} success={success as boolean} />
                </Suspense>
            ) : (
                <div className="flex flex-col items-center justify-center px-12 py-16 mx-auto lg:py-0" style={{ filter: 'drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.5))' }}>
                    <div className="w-full bg-white rounded-lg shadow dark:border sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <a className="flex justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                                <img className="w-42 h-auto px-12" src="./logooficial.webp" alt="logo" />
                            </a>
                            <form className="space-y-4 md:space-y-6" onSubmit={handleCadastoVerify}>
                                <label htmlFor="cnpj-login" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"></label>
                                <div className="relative mb-6">
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                        <svg viewBox="0 0 24 24" className="w-4 h-4 text-gray-500 dark:text-gray-400" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <g id="SVGRepo_bgCarrier"></g>
                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path d="M6.25 7a5.75 5.75 0 1 1 11.5 0 5.75 5.75 0 0 1-11.5 0ZM11.797 14.261a.755.755 0 0 1 .13-.011h.144c.044 0 .088.004.131.011l7.295 1.283a.64.64 0 0 1 .038.008c1.343.31 2.787 1.163 3.068 2.82a.73.73 0 0 1 .005.029l.113.877v.002c.265 2.009-1.328 3.47-3.21 3.47a.753.753 0 0 1-.123-.01h-14.9c-1.882 0-3.475-1.462-3.21-3.472l.114-.869a.753.753 0 0 1 .005-.03c.28-1.627 1.735-2.528 3.077-2.819a.719.719 0 0 1 .029-.006l7.294-1.283Z"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        id="cnpj-login"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        placeholder="CPF/CNPJ"
                                        required
                                        value={cpfCnpj}
                                        onChange={handleInputChange}
                                        onBlur={handleCpfCnpjBlur}
                                        maxLength={18}
                                    />
                                </div>
                                {cpfCnpjVerify === false && (
                                    <p className="text-red-500 text-xs absolute top-[263px] left-35">
                                        *CPF/CNPJ Inválido!
                                    </p>
                                )}
                                <div>
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-900 dark:text-white"></label>
                                    <div className="relative mb-6">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <svg
                                                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                                fill="currentColor"
                                                viewBox="0 0 15 15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M11 11H10V10H11V11Z" />
                                                <path d="M8 11H9V10H8V11Z" />
                                                <path d="M13 11H12V10H13V11Z" />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M3 6V3.5C3 1.567 4.567 0 6.5 0C8.433 0 10 1.567 10 3.5V6H11.5C12.3284 6 13 6.67157 13 7.5V8.05001C14.1411 8.28164 15 9.29052 15 10.5C15 11.7095 14.1411 12.7184 13 12.95V13.5C13 14.3284 12.3284 15 11.5 15H1.5C0.671573 15 0 14.3284 0 13.5V7.5C0 6.67157 0.671573 6 1.5 6H3ZM4 3.5C4 2.11929 5.11929 1 6.5 1C7.88071 1 9 2.11929 9 3.5V6H4V3.5ZM8.5 9C7.67157 9 7 9.67157 7 10.5C7 11.3284 7.67157 12 8.5 12H12.5C13.3284 12 14 11.3284 14 10.5C14 9.67157 13.3284 9 12.5 9H8.5Z"
                                                />
                                            </svg>
                                        </div>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            id="password"
                                            placeholder="SENHA"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-10 pr-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            required
                                            name="Senha"
                                            onChange={(e) => setSenha(e.target.value)}
                                            onFocus={() => setError(false)}
                                        />

                                        <div
                                            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                            onClick={() => setShowPassword((current) => !current)}
                                        >
                                            {showPassword ? (
                                                <BsEyeSlash className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                            ) : (
                                                <BsEye className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                            )}
                                        </div>
                                    </div>
                                    {
                                        error &&
                                        <p className='text-red-500 text-sm'> {errorMsg}</p>
                                    }
                                </div>
                                <div className="flex items-center justify-center">
                                    <a
                                        href="/auth/recuperar-senha"
                                        className="text-sm font-medium text-primary-800 hover:underline dark:text-primary-500"
                                    >Esqueceu sua senha?</a>
                                </div>
                                {loading || !cpfCnpj || !Senha ? (
                                    <button
                                        type="submit"
                                        disabled={true}
                                        className="w-full text-white bg-primary-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-500 "
                                    >
                                        {loading ? (
                                            <div role="status" className="flex flex-row justify-center gap-2 items-center">
                                                <svg
                                                    aria-hidden="true"
                                                    className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                    viewBox="0 0 100 101"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentFill"
                                                    />
                                                </svg>
                                                <span className="sr-only">Loading...</span>
                                                <span> Aguarde ...</span>
                                            </div>
                                        ) : (
                                            'Entrar'
                                        )}
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                    > Entrar
                                    </button>
                                )}
                                <p className="justify-center flex text-sm font-light text-gray-500 dark:text-gray-400">
                                    Não tem uma conta ainda?
                                    <Link className="font-medium text-primary-800 hover:underline dark:text-primary-500 ml-1" to={'/registrar'}>
                                        Registre-se
                                    </Link>
                                </p>
                                <hr className="my-4 border-gray-300 dark:border-gray-600" />
                                <div className="text-center">
                                    <p className="text-sm font-semibold text-gray-700 dark:text-gray-300">Canal de Atendimento</p>
                                    <div className="flex justify-center space-x-4">
                                        <a
                                            href="https://wa.me/5562995642949"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="flex items-center text-primary-800 dark:text-primary-500 hover:underline"
                                        >
                                            <BsWhatsapp className="mr-2" />
                                            WhatsApp
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default Login;