import React, { useEffect, useState } from 'react'
import LoadingPage from '../LoadingPage'
import { BsPlus, BsX } from 'react-icons/bs';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { error } from 'console';
import { DisplayMiddle } from '../../Utils/types/PortalTypes';
import LoadingComponent from '../LoaadingComponent';
import { usePortaisUtils } from '../../Utils/ReactQuery/getPortais';
import { TArquivosPortais } from '../../Utils/types/portaisTypes';
import AlertaAssinatura from '../AlertaAssinatura';


const NovoArquivoPortal= ({fechar, portal, arquivo, editar}: any) => {



    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false);

    const [NomeArquivo, setNomeArquivo] = useState<string>('');
    const [descrição, setDescrição] = useState<string>('');
    const [file, setFile] = useState<any>('');

    const [planos, setPlanos] = useState<boolean>(false);


    // MUTAÇÃO ----------------------------------
    const token = localStorage.getItem('token');
    const empresaSelecionada = localStorage.getItem('empresaSelecionada')
    const {salvarArquivoPortal} = usePortaisUtils();
    const queryClient =useQueryClient();

    const salvarArquivoMutation = useMutation({
        mutationFn: salvarArquivoPortal,
        onMutate: async(config)=>{
            // console.log('Mutação iniciada!');
            setLoading(true);
        },
        onSuccess: async(response)=>{
            // console.log('Mutação bem sucedida: ', response);
            queryClient.invalidateQueries({queryKey: ['rqArquivosPortais']});
            queryClient.fetchQuery({queryKey: ['rqArquivosPortais']});
            queryClient.refetchQueries({queryKey: ['rqArquivosPortais']});

            console.log('Response: ', response)

            if(response.status === 1){
                toast.success(response.msg);
                setSucesso(true)
            }
            if(response.status === 4){
                toast.warn(response.msg);
                setPlanos(true)
            }
            
            if(response.status === 3){
                toast.error(response.msg);
            }
        },
        onError: async(error)=>{
            console.error(error.message);
            toast.error(error.message)
        },
        onSettled(data, error, variables, context) {
            // console.log('Mutação concluída!');
            setLoading(false);
        },
    })
    
    // MUTAÇÃO ----------------------------------

    const handleNovoArquivo = (e: any)=>{
        e.preventDefault();


        const formData = new FormData(e.currentTarget);
        
        const arquivoProcessoData: TArquivosPortais = {
            NomeDoArquivo  :formData.get('nomeDoArquivo') || file.name,
            Categoria      :portal.Categoria || 'Nenhuma', 
            Descrição : formData.get('descrição') as string,
            Portalid: portal._id as string,
            Size: file.size || 0,
            empresaid: empresaSelecionada as string
        }

        formData.append('file', file);

        salvarArquivoMutation.mutate({portalid: portal._id as string, token: token as string, resumo: arquivoProcessoData, Arquivo: formData, empresaid: empresaSelecionada as string })
    }


    // Tailwind --------------------------------------------------

    const selectInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
    const optionInput ="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
        // Tailwind --------------------------------------------------

        const fecharAlerta=()=>{
            setPlanos(false)
        }
    
        if(planos){
            return(
                <>
                <AlertaAssinatura fechar ={fecharAlerta} tipo ={'arquivo'}/>
                </>
            )
        }
      

        if(sucesso){
            return(
            <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        
                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Arquivo inserido com sucesso!</h3>
                        
                        
    
                            <button onClick={()=> setSucesso(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Fechar</button>

                            
    
                        </div>
                    </div>
                </div>
            </div>
    
            )
        }
        
        if(loading){
            return(
            <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        
                        <div className="p-4 md:p-5 text-center">
                        <div role="status" className='flex items-center justify-center'>
                            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                    
                    </div>
                    <label htmlFor="">Aguarde</label>
                        
                        </div>
                    </div>
                </div>
            </div>
    
            )
        }



        

  return (
 
        <div id="crud-modal" tabIndex ={-1} className="overflow-x-hidden fixed top-0 left-0 z-50 flex justify-center items-center w-sc md:inset-0 h-[calc(100%-1rem)] max-h-full">

            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                {
                loading && <LoadingComponent/>
                }
         
                

                    <form className="p-4 md:p-5" onSubmit={handleNovoArquivo}>

                        <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Anexar arquivo </label>                            
                        <input className='rounded-xl' accept="application/pdf" type="file" name="file" onChange={(e: any)=> setFile(e.target.files[0])} required={true} placeholder={file ? file.name : ''}/>
                            <br />
                        </div>

                        <div className="grid gap-4 mb-4 grid-cols-2">

       
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome do arquivo </label>
                                <input type="text" name="nomeDoArquivo" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={ editar ? arquivo.nomeArquivo: file ? file.name : 'Nome do arquivo'}  />
                          
                            </div>
                           
                            <div className="col-span-2">
                                <div className="col-span-2">
                                <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Descrição do arquivo</label>
                                <textarea id="description" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Descreva o conteudo do arquivo" name='descrição' ></textarea>                    
                                </div>
                            </div>

                            

               

                        </div>

               
                        <div className='flex flex-row gap-2'>
                            <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <BsPlus className='text-xl'/>
                                Criar arquivo
                            </button>
                            <label onClick={fechar}  className="text-white inline-flex items-center cursor-pointer bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                                <BsX className='text-xl'/>
                                Cancelar
                            </label>
                        </div>
                        


                    </form>
                    
                </div>
            </div>
        </div> 
 
  )
}

export default NovoArquivoPortal