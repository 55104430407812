import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { BsCheck, BsX } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useAdminUtils } from '../../Utils/ReactQuery/getAdminUtils';
import { StatusSOlicitação, typeResolverSolicitação } from '../../Utils/types/adminTypes';
import LoadingPage from '../LoadingPage';
import SolicContextMenu from './SolicContextMenu';


const Solicitações = ({solicitações: dataSolicitações, empresaId}: any) => {

    const {resolverSolicitação} =  useAdminUtils();

    const [solicitações, setSolicitações] = useState<[String]>(['']);
    const [userSolicitação, setUserSolicitação] = useState<[String]>(['']);
    const [seleção, setSeleção] = useState<[String]>(['']);


    const [loading, setLoading] = useState<Boolean>(false);
    const [error, setError] = useState<Boolean>(false);
    const [errorMsg, setErrorMsg] = useState<String>('');



    // Mutação -----
    const queryClient = useQueryClient();

    const solicitMutation = useMutation({
        mutationFn: resolverSolicitação,
        onMutate: async(config)=>{
            // console.log('Mutaçãos iniciada!');
            setLoading(true);
            
            
        },
        onSuccess: async(response)=>{
            // console.log('Mutação bem sucedida!', response);

            queryClient.invalidateQueries({queryKey:['rqSolicitações']});
            queryClient.fetchQuery({queryKey:['rqSolicitações']});
            queryClient.refetchQueries({queryKey:['rqSolicitações']})
            queryClient.invalidateQueries({queryKey:['rqListaUsuarios']});
            queryClient.fetchQuery({queryKey:['rqListaUsuarios']});
            queryClient.refetchQueries({queryKey:['rqListaUsuarios']})

            if(response.status === 1){
                toast.success(response.msg);
                return;
            }
            if(response.status === 3){
                setError(true);
                setErrorMsg(response.msg);
                toast.error(errorMsg)
                return;
            }
            
        },
        onError: async(error)=>{
            // console.log('Erro na mutação das solicitções!', error.message)
            setError(true);
            setErrorMsg(error.message);
            return;
        },
        onSettled: (response)=> {
            // console.log('Mutação concluida!');
            setLoading(false);
            setSeleção(['']);
        },
    })



    //----------------

    // Hnalder
 

    const handleConfigSolicitação = (e: any, StatusSolicitação: StatusSOlicitação | string)=>{
        e.preventDefault();

        if(seleção.length <=0){
            return;
        }
        
        let solicitaçõesSelecionadas: any = [];
        if(!solicitaçõesSelecionadas){
            toast.warn('Nenhuma solicitação selecionada!')
            return;
        }

        seleção.map((a: any)=> {
            const tempFind = solicitações.find((i: any)=> {return i.AcessoUserId === a});
            solicitaçõesSelecionadas.push(tempFind)
        })

        // const solicitaçãoData: typeResolverSolicitação = {
        //      empresaid: empresaId,
        //      StatusSolicitação: StatusSolicitação as string,
        //      data:[
        //         solicitaçõesSelecionadas
        //      ]
        // }


        const empresaPadrão = localStorage.getItem('empresaPadrão')


        solicitMutation.mutate({empresaid: empresaPadrão as string, StatusSolicitação: StatusSolicitação, data: solicitaçõesSelecionadas})

        return;

    }

    //--------------------




    const [menuSelect, setMenuSelect] = useState<string>('')

    const [contextMenu, setContextMenu] = useState({
        position:{
            y: 0,
            x: 0
        },
        isOpen: false
    });

    const contextMenuRef = useRef<any | null>(null)

    useEffect(()=>{
        if(dataSolicitações){
            const arrSolicitações = dataSolicitações.empresa.Empresa_Solicitações;
            setSolicitações(arrSolicitações)
            setUserSolicitação(dataSolicitações.users)
        }
    },[dataSolicitações])


    //-------------- Tailwind -----------------

    const selectedRow = 'border-b dark:border-gray-700 bg-gray-50 dark:bg-gray-600 text-blue-500';
    const idleRow = 'bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600';

    //-------------- Tailwind -----------------

    //nÃO APAGAR espaço para manutenção //~~MANUTENÇÃO~~// 
    // useEffect(()=>{
    //     console.log('Seleção: ', seleção)
    // },[seleção])

    // useEffect(()=>{
    //     console.log('Solicitações: ', solicitações)
    // },[solicitações])

    // useEffect(()=>{
    //     console.log('Temp data', menuSelect)
    // },[menuSelect])

    // useEffect(()=>{
    //     console.log('Empresa id: ', empresaId)
    // },[empresaId])

    // useEffect(()=>{
    //     console.log('User da solicitação: ', userSolicitação)
    // },[userSolicitação])
    //~~MANUTENÇÃO~~// 


    const handleSolicitação = (e: any)=>{

        if(e.target.checked){
            if(seleção.includes(e.target.id)){
                return;
            }
    
            setSeleção([...seleção, e.target.id] as any)
        }else{
            const filtered = seleção.filter((i: any)=> i !== e.target.id);
            setSeleção(filtered as [String]);
        }

    }

    const handleContextMenu =(e: any, solicitação: any )=>{
        e.preventDefault()
        const contextMentAttr = contextMenuRef.current.getBoundingClientRect();
        const isLeft = e.clientX < window?.innerWidth/2
        let x;
        let y = e.clientY;
        if(isLeft){
            x = e.clientX
        }else{
            x = e.clientX - contextMentAttr.width
        }
        setContextMenu({
            position:{
                x,
                y
            },
            isOpen: true
        })

        setMenuSelect(solicitação.AcessoUserId);
        if(!seleção.includes(menuSelect) && menuSelect !== ''){
            setSeleção([menuSelect] as any)
        }
        return;
       
    }

  
    const resetContextMenu =()=>{

        if(seleção.includes(menuSelect)){
            const filterSeleção = seleção.filter((i: any)=> i !== menuSelect);
            setSeleção(filterSeleção as [string])
            setMenuSelect('')
        }
        
       if(contextMenu.isOpen){
        setContextMenu({
            position:{
                x: 0,
                y: 0
            },
            isOpen: false
        })
       }
    }
   
    useEffect(()=>{
        const handler =(e: any)=>{
            if(contextMenuRef.current){
                if(!contextMenuRef.current.contains(e.target)){
                    resetContextMenu()
                }
            }
        }

        document.addEventListener('click', handler)

        return ()=>{
            document.removeEventListener('click', handler)
        }
    })
    


const primeiroAcesso =(e: any)=>{
    const userData: any  = userSolicitação.find((i: any)=>{
        return i._id ===e
    })
    const primeiroAceso = userData?.PrimeiroAcesso;
    return primeiroAceso as string
}

const retornarNome = (e: any)=>{
   const userData : any =  userSolicitação.find((i: any)=>{
       return i._id === e
    });
    const userNome = userData?.Nome;
    return userNome;
}

const retornarEmail =(e: any)=>{
    const userData: any =  userSolicitação.find((i: any)=>{
        return i._id === e
     });
 
     const userNome = userData?.Email;
     return userNome as string;
}

const formatarData = (e:string)=>{
    const date = new Date(e);
    let dia =date.getDate();
    let mes = (date.getMonth()+1);
    let ano = date.getFullYear();

    return   date.getDate() + '-'+ (date.getMonth() +1)+'-' + date.getFullYear() + ' as ' + date.getHours() + ':'+date.getMinutes() + 'h' 
}


// Tailwwind--------------------------------
const activeButton = 'flex flex-row gap-3 text-lg font-semibold opacity-80 hover:opacity-100 transition-opacity cursor-pointer text-center';
const inactiveButton ='flex flex-row gap-3 text-lg font-semibold opacity-50  transition-opacity text-center'
// Tailwwind--------------------------------


    if(loading){
        return(
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex flex-col justify-center items-center pb-4 bg-white dark:bg-gray-900">
                    <div className="relative mt-1">
                        <LoadingPage/>
                        
                    </div>
                </div>
            </div>
        )
    }




  return (
    <div>
         <h3 className='sm:text-md font-semibold md:text-xl'> Solicitações de acesso para esta empresa </h3>
         <small> As permissões iniciais para colaboradores é colocada em visualizar como padrão. </small>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
           
            <div className="flex  flex-row justify-end pb-4 bg-white dark:bg-gray-900">
                

                <div className='pr-6 flex flex-rpw gap-5 items-center justify-center'>
                    <label onClick={(e)=> handleConfigSolicitação(e, StatusSOlicitação.ACEITA)} className= {seleção.length >0 ? `${activeButton}`:`${inactiveButton}`}> <span className='text-green-500'><BsCheck className='text-3xl'/></span> Aceitar </label>
                    <label onClick={(e)=> handleConfigSolicitação(e, StatusSOlicitação.NEGADA)} className= {seleção.length >0 ? `${activeButton}`:`${inactiveButton}`}> <span className='text-red-500'><BsX className='text-3xl'/> </span> Negar   </label>
                </div>

            </div>

            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="p-4">
                            <div className="flex items-center">
                                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                            </div>
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <sup>Nome</sup>/<sub>email</sub>
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Solicitação
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Acesso
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Data
                        </th>
                        <th scope="col" className="px-6 py-3">
                            PRIMEIRO ACESSO
                        </th>
                    </tr>
                </thead>
                <tbody>
                    
                    

                    {
                        solicitações.map((solicitação: any)=>(
                        <tr key={solicitação.AcessoUserId} id={`mapid-${solicitação.AcessoUserId}`} className={seleção.includes(solicitação.AcessoUserId) ?`${selectedRow}` : `${idleRow}`} onContextMenu={(e)=> handleContextMenu(e, solicitação)}>
                            <td className="w-4 p-4" >
                                <div className="flex items-center">
                                    <input  type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" id={solicitação.AcessoUserId} onChange ={handleSolicitação} />
                                    <label htmlFor="checkbox-table-search-2" className="sr-only">checkbox</label>
                                </div>
                            </td>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                               <sup>{
                                    retornarNome(solicitação.AcessoUserId)
                               }</sup>/<sub className=' opacity-80'>{
                                retornarEmail(solicitação.AcessoUserId)
                           }</sub>
                            </th>
                            <td className="px-6 py-4">
                                {solicitação.TipoSolicitação}
                            </td>
                            <td className="px-6 py-4">
                                {solicitação.AcessoSolicitação}
                            </td>
                            <td className="px-6 py-4">
                                {formatarData(solicitação.DataSolicitação)}
                            </td>
                            <td className="px-6 py-4">
                                <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">{primeiroAcesso(solicitação.AcessoUserId) ? "Sim": "Não"}</a>
                            </td>
                        </tr>



                        ))
                    }
                    
                </tbody>
            </table>
        </div>

        <SolicContextMenu
        positionX =   {contextMenu.position.x}
        positionY =   {contextMenu.position.y}
        isToggled =   {contextMenu.isOpen}
        contextMenuRef ={contextMenuRef}
        buttons={[
            {
                text: 'Aceitar',
                onClick: (e: any)=> handleConfigSolicitação(e, StatusSOlicitação.ACEITA) 
            },
            {
                text: 'Negar',
                onClick: (e: any)=> handleConfigSolicitação(e, StatusSOlicitação.NEGADA) 
            },
            {
                text: 'Perfil',
                onClick: ()=> toast.warn('Opção 3! ...')
            }

        ]}
        
        />
    </div>
  )
}

export default Solicitações