import { useState, useEffect } from 'react';
import { TCaptacaoCard } from '../../Utils/DevUtils/FakeCaptacao';
import CaptacaoItem from './CaptacaoItem';
import { BsQuestionCircle, BsPlus } from 'react-icons/bs';
import CaptacaoArquivos from './CaptacaoArquivos';
import CaptacaoItems from './CaptacaoItems';
import { cnpj } from 'cpf-cnpj-validator';
import NovoProcesso from '../ProcessosComponents/NovoProcesso';

const CaptacaoCard = ({ item }: any) => {
    const [itemSelecionado, setItemSelecionado] = useState<any>('');
    const [abiriItem, setAbrirItem] = useState<boolean>(false);
    const [arquivosUrl, setArquivosUrl] = useState<string>('');
    const [itensUrl, setItensUrl] = useState<string>('');
    const [anexarNoPortal, setAnexarNoPortal] = useState<boolean>(false);
    const [orgaoInfo, setOrgaoInfo] = useState<any>(null);
    const [mostrarObjetoCompleto, setMostrarObjetoCompleto] = useState<boolean>(false);

    enum SelecionarItem {
        ARQUIVO = 'Arquivos',
        ITEM = 'Items',
        RESUMO = 'resumo',
        ANEXAR = 'anexar'
    }

    const [captacaoArea, setCaptacaoArea] = useState<SelecionarItem>(SelecionarItem.RESUMO);

    const fecharItem = () => {
        setAbrirItem(false);
        setItemSelecionado('');
        setAnexarNoPortal(false);
    };

    const selecionarItem = (itemAtivo: TCaptacaoCard, action: SelecionarItem) => {
        const arquivosUrl = `https://pncp.gov.br/api/pncp/v1/orgaos/${itemAtivo.orgao_cnpj}/compras/${itemAtivo.ano}/${itemAtivo.numero_sequencial}/arquivos`;
        setArquivosUrl(arquivosUrl);
        const itensUrl = `https://pncp.gov.br/api/pncp/v1/orgaos/${itemAtivo.orgao_cnpj}/compras/${itemAtivo.ano}/${itemAtivo.numero_sequencial}/itens`;

        const data = {
            item: itemAtivo,
            LinkArquivos: arquivosUrl,
            LinkItens: itensUrl
        };
        setItensUrl(itensUrl);
        setItemSelecionado(data);

        switch (action) {
            case SelecionarItem.ARQUIVO:
                return setCaptacaoArea(SelecionarItem.ARQUIVO);
            case SelecionarItem.ITEM:
                return setCaptacaoArea(SelecionarItem.ITEM);
            case SelecionarItem.RESUMO:
                return setCaptacaoArea(SelecionarItem.RESUMO);
            case SelecionarItem.ANEXAR:
                return setAnexarNoPortal(true);
        }
    };

    useEffect(() => {
        if (item.orgao_cnpj) {
            fetch(`https://brasilapi.com.br/api/cnpj/v1/${item.orgao_cnpj}`)
                .then(response => response.json())
                .then(data => {
                    setOrgaoInfo(data);
                })
                .catch(error => console.error('Erro ao buscar informações do órgão:', error));
        }
    }, [item.orgao_cnpj]);

    const formatCep = (cep: string) => {
        return cep ? cep.replace(/(\d{5})(\d{3})/, "$1-$2") : '';
    };

    const formatTelefone = (telefone: string) => {
        return telefone ? telefone.replace(/(\d{2})(\d{4,5})(\d{4})/, "($1) $2-$3") : '';
    };

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        const date = new Date(dateString);
        return date.toLocaleDateString('pt-BR', options);
    };

    // Função para alternar a exibição do objeto completo
    const toggleObjetoCompleto = () => {
        setMostrarObjetoCompleto(!mostrarObjetoCompleto);
    };


    const pncpLink = (linkPncp: any)=>{
        const pncpUrl: string = linkPncp.replace('/compras','https://pncp.gov.br/app/editais');
        return pncpUrl;
    }
    

    // TAILWIND --------------------------------------------------------------------
    const button = 'cursor-pointer text-white inline-flex items-center bg-[#ff8027] hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800 h-fit';
    const activeTab = 'border-b-2 border-[#ff8027] text-[#ff8027] font-semibold inline-flex p-2 transition ease-in-out duration-300';
    const inactiveTab = 'text-gray-600 hover:text-[#ff8027] cursor-pointer transition ease-in-out duration-300 inline-flex p-2';
    const tooltip = 'absolute bg-gray-700 text-white text-xs rounded-lg py-2 px-3 right-0 bottom-full mb-2';

    // TAILWIND --------------------------------------------------------------------

    return (
        <div className='flex'>
            <div className='w-full h-fit bg-white rounded-lg shadow-md flex flex-col p-1 relative overflow-hidden'>
                {abiriItem && 
                <div className='fadeIn'>
                    <CaptacaoItem item={itemSelecionado} fechar={fecharItem} />
                </div>}
                {anexarNoPortal && 
                    <NovoProcesso fechar={fecharItem} captacaoPncp={itemSelecionado} />
                }

                <div className='flex flex-row justify-between items-center'>
                    <div>
                        <label className={captacaoArea === SelecionarItem.RESUMO ? `${activeTab}` : `${inactiveTab}`} onClick={() => selecionarItem(item, SelecionarItem.RESUMO)}>Resumo</label>
                        <label className={captacaoArea === SelecionarItem.ARQUIVO ? `${activeTab}` : `${inactiveTab}`} onClick={() => selecionarItem(item, SelecionarItem.ARQUIVO)}>Documentos</label>
                        <label className={captacaoArea === SelecionarItem.ITEM ? `${activeTab}` : `${inactiveTab}`} onClick={() => selecionarItem(item, SelecionarItem.ITEM)}>Itens</label>
                    </div>
                    <div>
                        <label className='text-gray-500'>Fonte: <a target='_blank' href={pncpLink(item.item_url)} className='font-semibold cursor-pointer'>PNCP</a></label>
                        <div className='text-sm text-gray-500 mt-1'>Última Atualização: <span className='font-semibold'>{formatDate(item.data_atualizacao_pncp)}</span></div>
                    </div>
                </div>

                {captacaoArea === SelecionarItem.ARQUIVO && (
                    <div className='overflow-y-auto chart fadeIn'>
                        <CaptacaoArquivos item={item} url={arquivosUrl} />
                    </div>
                )}
                {captacaoArea === SelecionarItem.ITEM && (
                    <div className='overflow-y-auto chart fadeIn'>
                        <CaptacaoItems item={item} url={itensUrl} />
                    </div>
                )}
                {captacaoArea === SelecionarItem.RESUMO && (
                    <div className="grid grid-cols-2 gap-4 text-gray-700 dark:text-gray-300 fadeIn">
                        {/* Primeira Coluna */}
                        <div className="flex flex-col gap-1">
                            <div className="font-bold text-xl text-green-600">{item.orgao_nome}</div>
                            <div className="text-sm text-gray-500 italic">{item.unidade_nome}</div>
                            <div><span className='font-semibold'>CNPJ:</span> {cnpj.format(item.orgao_cnpj)}</div>
                            {orgaoInfo && (
                                <>
                                    <div><span className='font-semibold'>Endereço:</span> {orgaoInfo.descricao_tipo_de_logradouro} {orgaoInfo.logradouro}, N° {orgaoInfo.numero}, {orgaoInfo.complemento} - {orgaoInfo.bairro}</div>
                                    <div><span className='font-semibold'>Cidade/UF:</span> {orgaoInfo.municipio}/{orgaoInfo.uf}</div>
                                    <div><span className='font-semibold'>Cep:</span> {formatCep(orgaoInfo.cep)}</div>
                                    <div><span className='font-semibold'>Telefone:</span> {formatTelefone(orgaoInfo.ddd_telefone_1)}</div>
                                </>
                            )}
                        </div>

                        {/* Segunda Coluna */}
                        <div className="flex flex-col gap-1">
                            <div><span className='font-semibold'>Número:</span> {item.title}</div>
                            <div><span className='font-semibold'>Modalidade Licitação:</span> {item.modalidade_licitacao_nome}</div>
                            <div><span className='font-semibold'>Esfera:</span> {item.esfera_nome}</div>
                            <div><span className='font-semibold'>Data:</span> {formatDate(item.data_fim_vigencia)}</div>
                            

                            <div className="flex items-center">
                                <span className='font-semibold'>Objeto:</span>
                                <span className='ml-2'>
                                    {item.description.length > 50 && !mostrarObjetoCompleto
                                        ? `${item.description.slice(0, 50)}...`
                                        : item.description}
                                    {item.description.length > 50 && (
                                        <button onClick={toggleObjetoCompleto} className="ml-2">
                                            <BsPlus className="inline" />
                                        </button>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                <div className='w-full flex flex-row justify-between items-center'>
                    <button className={button} onClick={() => selecionarItem(item, SelecionarItem.ANEXAR)}>
                        Enviar para Análise
                    </button>
                    <div className='relative group'>
                        <span className='text-gray-500 hover:text-gray-700 dark:text-gray-300 cursor-pointer transition ease-in-out duration-300'>
                            <BsQuestionCircle />
                        </span>
                        <div className={`${tooltip} hidden group-hover:block`}>
                            Esses são dados de captação automática. Para dar andamento no processo licitatório, envie para análise.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CaptacaoCard;
