export const converterValor = (valor: string)=>{
    try {
        if(!valor){
            return '---'
        }
        const valorTemp: any = parseFloat(valor).toLocaleString("pt-br", {style: "currency", currency: 'BRL'}) 
        if(isNaN(valorTemp)){
          return `R$ ${valor}`;
        }
        return valorTemp;
    } catch (error) {
            return `R$ ${valor}`;
    }
}