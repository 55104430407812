import React, { useEffect, useRef, useState } from 'react'
import { formatarData } from '../../Utils/formatarData'
import { BsX } from 'react-icons/bs'
import moment from 'moment-timezone';
import CountTime from '../../Utils/CountTime';
import ProcessosPastas from './ProcessosPastas';
import { DisplayMiddle } from '../../Utils/types/PortalTypes';


const BottonProcessos = ({ processo }: any) => {

  const [timeLineModal, setTimeLineModal] = useState<boolean>(false);
  const [filtro, setFiltro] = useState<DisplayMiddle>(DisplayMiddle.DOCUMENTOS);

  const formatarDataMoment = (data: string): string => {
    return moment(data).format('DD/MM/YYYY - HH:mm');
  }

  const hanldeFiltroTimeLine = (categoria: DisplayMiddle) => {
    setFiltro(categoria);
    setTimeLineModal(true);
  }

  const formatarTituloTimeline = (filtro: string)=>{
    switch(true){
      case filtro === 'Diligencia':
        return 'Diligência';
      case filtro === 'Registroprop':
        return 'Registro de Proposta';
      case filtro === 'Irecurso':
        return 'Manifestação de Recurso';
      case filtro === 'Julgamentor':
        return 'Julgamento Recursos';
      case filtro === 'Mandatos':
        return 'Mandato de Segurança'
      default:
        return filtro
    }
  }

  return (
    <div className='w-full flex flex-row dark:bg-gray-600 rounded-md'>

      {
        timeLineModal &&
        <div id="popup-modal" tabIndex={1} className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative p-4 min-w-80 max-w-fit max-h-full">
            <div className="relative bg-white rounded-xl shadow dark:bg-gray-700 p-5">
              <div className='w-full flex flex-row justify-between items-center'>
                <label className='capitalize'> {formatarTituloTimeline(filtro)}</label>
                <span className='w-fit cursor-pointer opacity-80 hover:opacity-100' onClick={() => setTimeLineModal(false)}><BsX className='text-3xl' /></span>
              </div>
              <ProcessosPastas filtro={filtro} processo={processo} />
            </div>
          </div>
        </div>
      }

      <div className='w-full flex flex-row justify-between h-fit no-scrollbar text-xs'>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.PUBLICAÇÃO)}>
          <label className='font-semibold cursor-pointer text-xs'>Publicação</label>
          <label> {processo.DataHoraPublicacao !== '' ? formatarDataMoment(processo.DataHoraPublicacao) : ""}</label>
          <label> {processo.DataHoraPublicacao ? <CountTime dataFinal={processo.DataHoraPublicacao} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.ESCLARECIMENTO)}>
          <label className='font-semibold cursor-pointer text-xs'>Esclarecimento</label>
          <label>{processo.DataHoraLimiteEsclarecimento ? formatarDataMoment(processo.DataHoraLimiteEsclarecimento) : ""}</label>
          <label>{processo.DataHoraLimiteEsclarecimento ? <CountTime dataFinal={processo.DataHoraLimiteEsclarecimento} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.IMPUGNAÇÃO)}>
          <label className='font-semibold cursor-pointer text-xs'>Impugnação</label>
          <label> {processo.DataHoraImpugnacao ? formatarDataMoment(processo.DataHoraImpugnacao) : ""}</label>
          <label> {processo.DataHoraImpugnacao ? <CountTime dataFinal={processo.DataHoraImpugnacao} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.REGISTROPROP)}>
          <label className='font-semibold cursor-pointer text-xs'>R.Proposta</label>
          <label> {processo.DataHoraContraProposta ? formatarDataMoment(processo.DataHoraContraProposta) : ""}</label>
          <label> {processo.DataHoraContraProposta ? <CountTime dataFinal={processo.DataHoraContraProposta} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.CPH)}>
          <label className='font-semibold cursor-pointer text-xs'>Abertura</label>
          <label> {processo.DataHoraAberturaLicitacao ? formatarDataMoment(processo.DataHoraAberturaLicitacao) : ""}</label>
          <label> {processo.DataHoraAberturaLicitacao ? <CountTime dataFinal={processo.DataHoraAberturaLicitacao} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.DILIGENCIA)}>
          <label className='font-semibold cursor-pointer text-xs'>Diligência</label>
          <label> {processo.DataHoraDiligencia ? formatarDataMoment(processo.DataHoraDiligencia) : ""}</label>
          <label> {processo.DataHoraDiligencia ? <CountTime dataFinal={processo.DataHoraDiligencia} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.APRESENTAÇÃO)}>
          <label className='font-semibold cursor-pointer text-xs'>Apresentação</label>
          <label> {processo.DataHoraApresentação ? formatarDataMoment(processo.DataHoraApresentação) : ""}</label>
          <label> {processo.DataHoraApresentação ? <CountTime dataFinal={processo.DataHoraApresentação} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.ATR)}>
          <label className='font-semibold cursor-pointer text-xs'>Continuação</label>
          <label> {processo.DataHoraContinuacaoLicitacao ? formatarDataMoment(processo.DataHoraContinuacaoLicitacao) : ""}</label>
          <label> {processo.DataHoraContinuacaoLicitacao ? <CountTime dataFinal={processo.DataHoraContinuacaoLicitacao} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.IRECURSO)}>
          <label className='font-semibold cursor-pointer text-xs'>I.Recurso</label>
          <label> {processo.DataHoraIntencaoRecurso ? formatarDataMoment(processo.DataHoraIntencaoRecurso) : ""}</label>
          <label> {processo.DataHoraIntencaoRecurso ? <CountTime dataFinal={processo.DataHoraIntencaoRecurso} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.RECURSO)}>
          <label className='font-semibold cursor-pointer text-xs'>Recurso</label>
          <label> {processo.DataHoraRecurso ? formatarDataMoment(processo.DataHoraRecurso) : ""}</label>
          <label> {processo.DataHoraRecurso ? <CountTime dataFinal={processo.DataHoraRecurso} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.CONTRARRAZÃO)}>
          <label className='font-semibold cursor-pointer text-xs'>Contrarrazão</label>
          <label> {processo.DataHoraLimiteContrarrazao ? formatarDataMoment(processo.DataHoraLimiteContrarrazao) : ""}</label>
          <label> {processo.DataHoraLimiteContrarrazao ? <CountTime dataFinal={processo.DataHoraLimiteContrarrazao} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.JULGAMENTOR)}>
          <label className='font-semibold cursor-pointer text-xs'>Julgamento R.</label>
          <label> {processo.DataHoraLimiteJulgamentoRecurso ? formatarDataMoment(processo.DataHoraLimiteJulgamentoRecurso) : ""}</label>
          <label> {processo.DataHoraLimiteJulgamentoRecurso ? <CountTime dataFinal={processo.DataHoraLimiteJulgamentoRecurso} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.HOMOLOGAÇÃO)}>
          <label className='font-semibold cursor-pointer text-xs'>Homologação</label>
          <label> {processo.DataHoraHomologacao ? formatarDataMoment(processo.DataHoraHomologacao) : ""}</label>
          <label> {processo.DataHoraHomologacao ? <CountTime dataFinal={processo.DataHoraHomologacao} /> : ""}</label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.ADJUDICAÇÃO)}>
          <label className='font-semibold cursor-pointer text-xs'>Adjudicação</label>
          <label> {processo.DataHoraAdjudicação ? formatarDataMoment(processo.DataHoraAdjudicação) : ""}</label>
          <label> {processo.DataHoraAdjudicação ? <CountTime dataFinal={processo.DataHoraAdjudicação} /> : ""} </label>
        </div>

        <div className='flex flex-col  text-center text-xs' onClick={() => hanldeFiltroTimeLine(DisplayMiddle.MANDATOS)}>
          <label className='font-semibold cursor-pointer text-xs'>Mandato S.</label>
          <label> {processo.DataMandatoS ? formatarDataMoment(processo.DataMandatoS) : ""}</label>
          <label> {processo.DataMandatoS ? <CountTime dataFinal={processo.DataMandatoS} /> : ""}</label>
        </div>

      </div>
    </div>
  )
}

export default BottonProcessos;
