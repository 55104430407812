import { useEffect, useLayoutEffect, useState } from 'react'
import { BsDownload,  BsFilePlus, BsTrash, BsX } from 'react-icons/bs';
import { FaEye} from 'react-icons/fa';

import { formatarData } from '../../Utils/formatarData';

import moment from 'moment';
import ApagarPastaArquivoProcesso from './ApagarPastaArquivoProcesso';
import NovoArquivoProcesso from './NovoArquivoProcesso';
import ArquivoModal from '../ArquivosComponents.tsx/ArquivoModal';
import { toast } from 'react-toastify';
import { converterValor } from '../../Utils/ConverterValor';



const AbrirPastaProcesso = ({pasta, fechar, processo, arquivos: arquivosPasta}: any) => {

    // //~MANUNTENÇÃO~//


    // //~MANUNTENÇÃO~//


    const [arquivosSelecionados, setArquivosSelecionados] = useState<any>('');
    const [arquivoSelecionado, setArquivoSelecionado] = useState<any>();
    const [apagarArquivo, setApagarArquivo] = useState<boolean>(false);
    const [arquivoAberto, setArquivoAberto] = useState<boolean>(false);
    const [novoArquivo, setNovoArquivo] = useState<boolean>(false);
    const [editarArquivo, setEditarArquivo] = useState<boolean>(false);
    const [abriArquivo, setAbrirArquivo] = useState<boolean>(false);

    const [arquivos, setArquivos] = useState<any>('');

    const fecharNovoArquivo = ()=>{
        setNovoArquivo(false)
    }

    useEffect(()=>{
        if(arquivosPasta){
            const arquivosFind = arquivosPasta.filter((i: any)=>{
            return i.pastaid === pasta._id

        })

       

        setArquivos(arquivosFind)}
    },[arquivosPasta])

    const dataAtual = moment();
    const checkStatus =(dataVencimento: string, status: string)=>{

        if(!dataVencimento || dataVencimento === undefined || dataVencimento.length <= 1){
            return <span className=' text-green-500'> Vigente </span>
        }


        const dataFinal = moment(dataVencimento);
        const dif = moment(dataFinal).diff(dataAtual);

    

        switch(true){
            case status === 'Deferido':
                return <span className=' text-green-500'> Deferido </span>
                break;
            case status === 'Indeferido':
                return <span className=' text-red-500'> Indeferido </span>
                break;
            case dif > 0 && status ==='nenhum' || dif > 0 && !status:
                return <span className=' text-green-500'> Vigente </span>
                break;
            case dif <= 0 && status === 'nenhum' || dif > 0 && !status:
                return <span className=' text-red-500'> Vencido </span>
                break;
            case status === 'entregue':
                return <span className=' text-green-500'> Entregue </span>
                break;
            case status === 'distratado':
                return <span className=' text-red-500'> Distratado </span>
                break;
            case status === 'encerrado':
                return <span className=' text-red-500'> Encerrado </span>
                break;
          
            
        }
    }

    const handleApagarArquivo =(arquivo: any)=>{
        setArquivoSelecionado(arquivo);
        setApagarArquivo(true);
    }

    const fecharApagar = ()=>{
        setApagarArquivo(false);
        setArquivoSelecionado('');
    }

    const fecharArquivo = ()=>{
        setAbrirArquivo(false);
        setArquivoSelecionado('')
    }


    const hanldeAbrirArquivo = (e: any, arquivo: any)=>{
        e.preventDefault();
        setArquivoSelecionado(arquivo);
        setAbrirArquivo(true);
    }

    const dowloadFile = (e: any, fileUrl: string, fileName: string)=>{
        e.preventDefault();

        const nomeValido = `${fileName.replaceAll(/\s/g, '_')}.pdf`

        try {
            fetch(fileUrl).then((response)=> response.blob()).then((blob)=> {
                const url = window.URL.createObjectURL( new Blob([blob]));
                const link = document.createElement('a');
                link.href =url;
                link.download = `${nomeValido}`
    
                document.body.appendChild(link);
    
                link.click();
                
                link.parentNode?.removeChild(link);
                window.URL.revokeObjectURL(fileUrl)
    
            });
        } catch (error) {
            console.error(error);
            toast.error('Erro no dowload!');
            return;
            
        }
    }

    const findLinkArquivo =(arquivo: any) =>{
        switch(true){
            case arquivo.oldDescartmed === true && arquivo.arquivoPath !== undefined:
                return `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/${arquivo.arquivoPath.replaceAll(/\\/g, "/")}`

            case arquivo.LinkAws !== undefined:
                return arquivo.LinkAws as string;
                break;
            case arquivo.arquivoPath !== undefined:
                const link = `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/${arquivo.arquivoPath.replaceAll(/\\/g, "/")}`
                return link;
                break;
            
        }
      }


    return (
        <div id="popup-modal" tabIndex={1} className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)]  ">

            {
                apagarArquivo && <ApagarPastaArquivoProcesso fechar ={fecharApagar} arquivo ={arquivoSelecionado} tipo ={'arquivo'}/>
            }
            {
                novoArquivo && <NovoArquivoProcesso processoid ={processo._id} pasta={pasta} editar={false} fechar={fecharNovoArquivo} />
            }
            {
                abriArquivo && <ArquivoModal arquivo ={arquivoSelecionado} fechar={fecharArquivo} processo={processo} noArqivo ={false}/>
            }

            <div className="relative p-4 w-full max-w-fit max-h-full">
                <div className="relative bg-white rounded-xl shadow dark:bg-gray-700 p-5">

    
                    <div className='flex flex-col mt-2'>
                    <h3 className='inline-flex gap-2 w-fit text-xl font-semibold'> {pasta.nomePasta}</h3>

                        <div className='inline-flex justify-between gap-2  mt-2  w-full' >
                            
                            <label onClick={()=> setNovoArquivo(current => !current)} className='inline-flex gap-2 w-fit opacity-80 hover:opacity-100 transition-opacity cursor-pointer'><span className=' text-lg  '><BsFilePlus/></span> Novo arquivo</label>

                            

                            <label className='inline-flex gap-2 w-fit opacity-80 hover:opacity-100 transition-opacity cursor-pointer' onClick={fechar}> <BsX className='text-3xl'/> </label>
                        
                        </div>

                        <div className="relative overflow-x-auto">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
                                    <tr>
                                        <th scope="col" className="px-6 ">
                                            Descrição
                                        </th>
                                        <th scope="col" className="px-6 ">
                                            Opção
                                        </th>
                                        <th scope="col" className="px-6 ">
                                            Categoria
                                        </th>
                                        <th scope="col" className="px-6 ">
                                            Emissão
                                        </th>
                                        <th scope="col" className="px-6 ">
                                            Vencimento
                                        </th>
                                        <th scope="col" className="px-6 ">
                                            Valor
                                        </th>
                                        <th scope="col" className="px-6 ">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody >
                                {
                                    arquivos && arquivos.map((arquivo: any)=>(
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6  font-medium text-gray-900
                                         whitespace-nowrap dark:text-white">
                                        <a target='_blank' href={findLinkArquivo(arquivo)} className='cursor-pointer w-fit'> 
                                            { arquivo.nomeDoArquivo.replaceAll('_', " ")}
                                        </a>
                                        </th>

                                        <td className="px-6  flex flex-row gap-2">
                                            <BsDownload className='cursor-pointer opacity-80 hover:opacity-100' title='abrir arquivo' onClick ={arquivo.LinkAws ? (e)=> dowloadFile(e, arquivo.LinkAws, arquivo.nomeDoArquivo):(e)=> dowloadFile(e,`https://portalglc.com/servidorapigrupors/${arquivo.arquivoPath}`, arquivo.nomeDoArquivo) }/>
                                            <FaEye className='cursor-pointer opacity-80 hover:opacity-100' title='abrir arquivo' onClick={(e)=> hanldeAbrirArquivo(e, arquivo)}/> 
                                            <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100' title='Apagar' onClick={()=> handleApagarArquivo(arquivo)}/>
                                        </td>
                                    
                                        <td className="px-6  text-center" >
                                            {arquivo.categoria}
                                        </td>
                                        <td className="px-6  text-center">
                                            {formatarData(arquivo.dataInicio)}
                                        </td>
                                        <td className="px-6  text-center">
                                            {formatarData(arquivo.dataVencimento)}
                                        </td>
                                        <td className="px-6  text-center">
                                            {converterValor(arquivo.valor)}
                                        </td>
                                        <td className="px-6  text-center inline-flex text-black dark:text-gray-300  gap-4">
                                        {checkStatus(arquivo.dataVencimento, arquivo.status)}
                                        </td>
                                        </tr>
                                    ))
                                }    
                                </tbody>
                            </table>
                        </div>     
                    </div>
                </div>
            </div>
        </div>

  )
}

export default AbrirPastaProcesso