import React, { useEffect, useLayoutEffect, useState } from 'react'
import { BsDownload, BsEye, BsPencil, BsTrash } from 'react-icons/bs';
import { SlShareAlt } from 'react-icons/sl';
import { formatarData } from '../../Utils/formatarData';
import { RiShare2Fill } from 'react-icons/ri';
import NovoArquivoPerfil from '../PerfilComponents/NovoArquivoPerfil';
import { useReactQueryTimeline } from '../../Utils/ReactQuery/getArquivosTimeline';
import { TArquivosExtra } from '../../Utils/types/PortalTypes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';


const BottonProcessosExtrasArquivos = ({arquivos, processo, modalidade}: any) => {



    const [arquivosFiltered, setArquivosFiltered] = useState<TArquivosExtra[] | null>(null)
   
    // //~~MANUTENÇÃO~~//
    // useEffect(()=>{
    //     console.log('modalidade e processo===================>: ', arquivos)
    // },[arquivos])
    // //~~MANUTENÇÃO~~//

    useLayoutEffect(()=>{

        if(arquivos){
            const arquivosCredTemp =  arquivos.arquivos.filter((item: any)=>{return item.Modalidade === modalidade})  
            setArquivosFiltered(arquivosCredTemp)}
        return () =>{}

    },[arquivos, processo])

    const [novoArquivo, setNovoArquivo] = useState<boolean>(false)

    const {apagarArquivosTimeline} = useReactQueryTimeline();
    const queryClient = useQueryClient();

    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false);
    const [confirmarApagarArquivo, setConfirmarApagar] = useState<boolean>(false);
    const [arquivoSelecionado, setArquivoSelecionado] = useState<TArquivosExtra | null>(null)
    const token = localStorage.getItem('token');


    const apagarTimelineMutation = useMutation({
        mutationFn: apagarArquivosTimeline,
        onMutate: async(config)=>{
            // console.log('Mutação iniciada!');
            setLoading(true);
        },
        onSuccess: async(response)=>{
            // console.log('Mutação bem sucedida: ', response);
            queryClient.invalidateQueries({queryKey: ['rqTimeline']});
            queryClient.fetchQuery({queryKey: ['rqTimeline']});
            queryClient.refetchQueries({queryKey: ['rqTimeline']});


            if(response.status === 1){
                toast.success(response.msg);
                setSucesso(true)
            }else{
                toast.error(response.msg);
            }
        },
        onError: async(error)=>{
            console.error(error.message);
            toast.error(error.message)
        },
        onSettled(data, error, variables, context) {
            // console.log('Mutação concluída!');
            setLoading(false);
        },
    })
  
    const fechar = ()=>{
          setNovoArquivo(false);
          setConfirmarApagar(false);
          setSucesso(false)
    }
  

    
    const apagarArquivo =(arquivo: TArquivosExtra)=>{
        setArquivoSelecionado(arquivo)
        setConfirmarApagar(true)
        
    }

    const empresaSelecionada = localStorage.getItem('empresaSelecionada')
    const handleApagarArquivo =(e: any)=>{
        e.preventDefault();
        apagarTimelineMutation.mutate({token: token as string, arquivoid: arquivoSelecionado?._id as string, empresaid: empresaSelecionada as string});
    }


    const downloadFile = (e: any, fileUrl: string, fileName: string)=>{
        e.preventDefault();

        const nomeValido = `${fileName.replaceAll(/\s/g, '_')}.pdf`

        try {
            fetch(fileUrl).then((response)=> response.blob()).then((blob)=> {
                const url = window.URL.createObjectURL( new Blob([blob]));
                const link = document.createElement('a');
                link.href =url;
                link.download = `${nomeValido}`
    
                document.body.appendChild(link);
    
                link.click();
                
                link.parentNode?.removeChild(link);
                window.URL.revokeObjectURL(fileUrl)
    
            });
        } catch (error) {
            console.error(error);
            toast.error('Erro no dowload!');
            return;
            
        }
    }

    if(sucesso){
        return(
        <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
    
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Arquivo apagado com sucesso!</h3>

                        <button onClick={fechar} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Fechar</button>

                    </div>
                </div>
            </div>
        </div>

        )
    }
    
    if(loading){
        return(
        <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <div className="p-4 md:p-5 text-center">
                    <div role="status" className='flex items-center justify-center'>
                        <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                
                </div>
                <label>Aguarde</label>
                    
                    </div>
                </div>
            </div>
        </div>

        )
    }

  return (
    <div className='mb-12 overflow-x-hidden'>

        {
            confirmarApagarArquivo &&
            <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Deseja apagar este arquivo? </h3>

                        <div>
                            <label> {arquivoSelecionado?.NomeDoArquivo}</label>
                            <small> Este arquivo será apagado permanentemente</small>
                        </div>

                        <button onClick={handleApagarArquivo} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Confirmar</button>

                        <button onClick={fechar} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Fechar</button>

                    </div>
                </div>
            </div>
        </div>
        }

        
        
        {
        novoArquivo &&
            <NovoArquivoPerfil fechar={fechar} processo={processo} modalidade ={modalidade}/>
        }

        <div >

               
                <div className='inline-flex cursor-pointer opacity-80 hover:opacity-100 transition-opacity w-fit my-4' onClick={()=> setNovoArquivo(current => !current)}>
                    <RiShare2Fill className='text-xl mr-2'/> 
                    <span className=''> Inserir arquivo </span>
                </div>
                   

                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Nome do arquivo
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Opções
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Descrição
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Criado em
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Última atualização
                            </th>


                        </tr>
                    </thead>
                    <tbody>
                       
                        {
                           arquivosFiltered && arquivosFiltered.map((item: TArquivosExtra, index: number)=>(
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={item._id}>
                           
                            <td className="px-6 py-4">
                                {item.NomeDoArquivo}
                            </td>
                           
                            <td className="px-6 py-4 flex flex-row gap-2">
                                <a href={item.LinkAws} target='_blank'>
                                <SlShareAlt className='cursor-pointer opacity-80 hover:opacity-100' title='Link' />
                                </a>
                                <BsDownload className='cursor-pointer opacity-80 hover:opacity-100' title='Baixar' onClick={(e)=>{downloadFile(e, item.LinkAws as string, item.NomeDoArquivo)}}/>
                                <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100' title='Apagar' onClick={()=> apagarArquivo(item)} />
                            </td>
                           
                            <td className="px-6 py-4">
                                {item.Descrição}
                            </td>
                            <td className="px-6 py-4">
                                {item.createdAt}
                            </td>
                            <td className="px-6 py-4">
                                {item.updatedAt}
                            </td>
                        
                        </tr>
                            ))
                        }
                        
                    </tbody>
                </table>
            </div>

    </div>
  )
}

export default BottonProcessosExtrasArquivos