import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { BsCaretDown, BsCaretRight, BsX } from 'react-icons/bs';
import AcessoHelpModal from '../RegisterComponents/AcessoHelpModal';
import styles from './Admin.module.css'
import AlterandoPerm from './AlterandoPerm';
import PerfilArquivos from '../PerfilComponents/PerfilArqivos';

const Funcionarios = ({usuarios, empresaData}: any) => {

    const placeholderRef = useRef<any>(null);
    const actualRef = useRef<any>(null);


    const [empresa, setEmpresa] = useState<any>();

    const [usuarioSelecionado, setUsuarioSelecionado] = useState<any>('');
    const [usuarioAlterado, setUsuarioAlterado] = useState<any>('');
    const [usuarioContextMenu, setUuarioContextMenu] = useState<boolean>(false);
    const [alterandoPermissão, setAlterandoPermissão] = useState<boolean>(false);

    const [pastaUsuario, setPastaUsuario] = useState<boolean>(false);

    //-----------------------------------------------
    
 
 
    //-----------------------------------------------

    
    const close = (e: any)=>{
        e.preventDefault();
        setAlterandoPermissão(false);
        setUsuarioSelecionado('')
    }

    const fechar =() =>{
        setPastaUsuario(false);
        setUsuarioSelecionado('')
    }

   useLayoutEffect(()=>{
       if(empresaData) {const empresaTemp = empresaData.find((i: any)=> {return i.EmpresaNome !== ""});
        setEmpresa(empresaTemp)
    }
    },[empresaData])


    // //~~MANUTENÇÃO~~// 
    // useEffect(()=>{
    //   console.log('Usuarios  do funcionários: ', usuarios)
    // },[usuarios])
    // //~~MANUTENÇÃO~~// 

  const permissões: any = (userid: string)=>{

    if(!empresaData && empresa){
        return ''
    }

    switch(true){
        case empresa && empresa.Empresa_Visualizar.includes(userid) && !empresa.Empresa_PastasArquivos.includes(userid):
        return 'Visualizar';
        break;
        case empresa && empresa.Empresa_PastasArquivos.includes(userid) :
        return 'Editar';
        break;
        case empresa && empresa.Empresa_Adm.includes(userid):
        return 'Administrador';
        break;
        default: return '';

    }

  }
  
  const buscarAcesso =(user: any)=>{
    try {
        if(!usuarios.Acessos && !empresa){
            return ''
        }

        const acessoTemp = user.Acessos.find((i: any)=>{
            return i.Empresas.includes(empresa._id)
        })

        if(!acessoTemp.TipoAcesso || acessoTemp.TipoAcesso === undefined){
            return '...'
        }
      
        return acessoTemp.TipoAcesso
       
    } catch (error) {
        console.error(error);
        return '...'
    }
      
  }


    const alterarPermissão = (e: any)=>{
        e.preventDefault();
        const usuario = usuarios.find((i: any)=>{
            return i._id === usuarioSelecionado
        })

        const permisssãoAtual = permissões(usuarioSelecionado);
        usuario.Permissão = permisssãoAtual
        setUsuarioAlterado(usuario);
        setAlterandoPermissão(true);

    }


    const abrirPastaUsuario =(usuario: any)=>{
        setUsuarioSelecionado(usuario)
        setPastaUsuario(true)
    }

  return (
    <div >
        
        {   alterandoPermissão &&
            <AlterandoPerm close ={close} usuario ={usuarioAlterado} empresaData ={empresa}/>
        }
        
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg min-h-screen">
        <h3 className='sm:text-md font-semibold md:text-xl'> Colaboradores com acesso a empresa </h3>
        <small> As permissões são geridas inidividualmente para cada usuário. </small>
            
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className=" text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>

                        <th scope="col" className="px-6 py-3">
                            NOME/EMAIL
                        </th>
                        <th scope="col" className="px-6 py-3">
                            CARGO
                        </th>
                        <th scope="col" className="px-6 py-3">
                            CARGO / FUNÇÃO
                        </th>
                     
                        <th scope="col" className="px-6 py-3">
                            Permissão
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Opção
                        </th>
                    </tr>
                </thead>
                <tbody>
                    
                    {
                        usuarios && usuarios.map((usuario: any)=>(
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key = {usuario._id}>
                   
                            <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                            {
                                usuario.Foto ?
                                <img className="w-10 h-10 rounded-full" src={usuario.Foto} alt={usuario.Nome.slice(0, 1)}/>
                                :
                                <div className='w-12 h-12 rounded-full flex justify-center items-center'><span>{usuario.Nome.slice(0, 1)}</span></div>
                            
                            }
                            <div className="ps-3">
                                <div className="text-base font-semibold">{usuario.Nome}</div>
                                <div className="font-normal text-gray-500">{usuario.Email}</div>
                            </div>  
                            </th>
                            <td className="px-6 py-4">
                                {usuario.CargoFuncao ? usuario.CargoFuncao:'Não informado!'}
                            </td>
         
                  
                            <td className="px-6 py-4">
                                <label className="flex items-center">{buscarAcesso(usuario)}</label>
                            </td>
                            <td className="px-6 py-4">
                                <label className="flex items-center">{permissões(usuario._id)}</label>
                            </td>
                        
                            <td className="px-6 py-4">
                                

                                <input className={`${styles.contextCb}`} type="checkbox" name="contextmenu" id={`context-${usuario._id}`}  />

                                <label htmlFor={`context-${usuario._id}`} className="font-medium text-blue-600 flex flex-row dark:text-blue-500 hover:underline cursor-pointer" onClick={()=> setUsuarioSelecionado(usuario._id)}> Opção <span className={`${styles.contextIcon}`}><BsCaretRight/></span> </label>

                                { usuarioSelecionado === usuario._id &&
                                    <div  className ={`${styles.contextWrapper} z-10 relative`} >
                                    <div className="flex flex-col gap-2 py-1 text-sm text-gray-700 dark:text-gray-200 rounded-lg absolute top-2 -left-10 bg-white dark:bg-gray-700" >
                                        <label>
                                            <label className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={alterarPermissão}>Alterar permissão</label>
                                        </label>
                                        <label>
                                            <label className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Perfil</label>
                                        </label>
                                        <label>
                                            <label className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Inativar</label>
                                        </label>
                                        <label>
                                            <label className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={()=> abrirPastaUsuario(usuario)}>Abrir pasta</label>
                                        </label>
                                    </div>
                                   
                                </div>}

                            </td>
                            </tr>
                        ))
                    }
                    
                    
                </tbody>
            </table>
        </div>

        {
            pastaUsuario &&
            <div className='fixed w-full h-full top-o left-0 flex justify-center items-center inset-0'>
                <div className='bg-white dark:bg-gray-600 border border-opacity-90 rounded-lg p-2'>

                    <div className='flex flex-row w-full rtl:right-0 justify-between'>
                        <label> Usuario: {usuarioSelecionado.Nome}</label>
                        <span className='text-3xl opacity-75 hover:opacity-100 cursor-pointer' onClick={fechar}>  <BsX/> </span>
                    </div>
                    <PerfilArquivos user = {usuarioSelecionado} admin ={true}/>
                </div>
            </div>
        }


    </div>
  )
}

export default Funcionarios