import { useMutation, useQueryClient } from '@tanstack/react-query'
import { cnpj } from 'cpf-cnpj-validator'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { BsPencil, BsPlus, BsX } from 'react-icons/bs'
import { IMaskInput } from 'react-imask'
import { toast } from 'react-toastify'
import { checkCpfCnpj } from '../../Utils/checkCpfCnpj'
import { formatarData } from '../../Utils/formatarData'
import { usePastasUtils } from '../../Utils/ReactQuery/getPastasUtils'
import { typePasta } from '../../Utils/types/pastasTypes'
import LoadingPage from '../LoadingPage'
import AlertaAssinatura from '../AlertaAssinatura'
import { UserContext } from '../../Context/UserContext'
import { useUserContext } from '../../Context/useUserContext'

type Props = {}

const NovaPasta = ({fechar}: any) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false);
    const [error, setError] =     useState<boolean>(false);
    const [empresaError, setEmpresaError] = useState<boolean>(false)

    const [planos, setPlanos] = useState<boolean>(false)


    const [userCntext] = useUserContext()

    // Mutação -------------------------------------------


    const {criarPasta} = usePastasUtils();
    const queryClient = useQueryClient();
    const empresaid = localStorage.getItem('empresaSelecionada');
    const token = localStorage.getItem('token')

    useLayoutEffect(()=>{
        if(!empresaid || empresaid.length <= 0){
            setError(true)
            setEmpresaError(true)
            toast.error('Selecione a empresa ao qual a pasta pertencerá!');
        }else{
            setError(false)
            setEmpresaError(false)
        }
    },[empresaid])

    const criarPastaMutation = useMutation({
        mutationFn: criarPasta,
        onMutate: async(config)=>{
            setLoading(true);
            // console.log('Mutação iniciada!');
            
        },
        onSuccess: async(response)=>{
            // console.log("Mutação bem sucedida!", response);
            if(response.status === 1){
                toast.success(response.msg);
                setSucesso(true);
                setNomePasta('')
                setDataEmissao('')
                setDataVencimento('')
                setSineDie(false)
                setLinkOEA('')
                setValor('')
                setCategoria('')

                queryClient.invalidateQueries({queryKey: ["rqPastas"]});
                queryClient.fetchQuery({queryKey: ["rqPastas"]});
                queryClient.refetchQueries({queryKey: ["rqPastas"]});
                
            }

            if(response.status === 4){
                toast.warn(response.msg);
                setPlanos(true)
            }

            if(response.status === 3){
                toast.error(response.msg);
                setError(true);
      
            }

        },
        onError: async(err)=>{
            console.error(err.message);
            toast.error('Erro ao tentar realizar a criação da pasta!')
        },
        onSettled: async(reesponse)=>{
            // console.log('Mutação bem sucedida');
            setLoading(false);
            
        }

    })



    // ----------------------------------------------------

    const [cnpjEmpresa, setCnpjEmpresa] = useState<string>('');
    const [NomePasta, setNomePasta] = useState<string>('');      
    const [DataEmissao, setDataEmissao] = useState<any>('');    
    const [DataVencimento, setDataVencimento] = useState<string>(''); 
    const [SineDie, setSineDie] = useState<boolean>(false);        
    const [LinkOEA, setLinkOEA] = useState<string>('');        
    const [Valor, setValor] = useState<string>('');          
    const [Categoria, setCategoria] = useState<string>('');       


    const handleNovaPasta = (e: any)=>{
        e.preventDefault();

        if(empresaError){
            return;
        }

        setError(false);

        const formData = new FormData(e.currentTarget);

        const data: typePasta = {
            Categoria : Categoria as string,
            DataEmissao     :formData.get("DataEmissao")  as unknown as Date,
            DataVencimento  :formData.get("DataVencimento")  as unknown as Date,
            LinkOEA         :formData.get("LinkOEA")  as string ,
            NomePasta       :formData.get("NomePasta")  as string ,
            SineDie         :SineDie,
            Valor           :formData.get("Valor")  as string ,
        }

        criarPastaMutation.mutate({empresaid: empresaid as string, token: token as string, pasta: data})
        return;
    }

    const fecharAlerta=()=>{
        setPlanos(false)
    }

    if(planos){
        return(
            <>
            <AlertaAssinatura fechar ={fecharAlerta} tipo ={'pasta'}/>
            </>
        )
    }

    if(loading){
        return(
            <div id="crud-modal" tabIndex ={-1} aria-hidden="true" className="overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 ">
                <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <LoadingPage/>
                </div>
                </div>
            </div>
        )
    }
    if(sucesso){
        return(
        <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Pasta criada com sucesso!</h3>
                    
                        <button onClick={()=> setSucesso(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"> Criar outa pasta</button>

                        <button onClick={fechar} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Fechar</button>

                    </div>
                </div>
            </div>
        </div>

        )
    }
    

    // Tailwind -------------------------------------------------
    const selectInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
    const optionInput ="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";

    
    // Tailwind -------------------------------------------------

  
  return (
        <div id="crud-modal" tabIndex ={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <form className="p-4 md:p-5" onSubmit={handleNovaPasta}>
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome da pasta</label>
                                <input type="text" name="NomePasta" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder='Nome da pasta' value={NomePasta } onChange ={(e)=> setNomePasta(e.target.value)} required={true}/>

                                {error && NomePasta.length <= 0 && <p className='text-red-500 text-sm'> Campo vazio </p>}
                            </div>
                     

                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Link OEA</label>
                                <input type="text" name="LinkOEA" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={'www.linkOEA.com'} value={LinkOEA} onChange ={(e)=> setLinkOEA(e.target.value)}/>
                            </div>

                            <div> 
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Categoria </label>
                                <select name="categoria" id="categota" className={`${selectInput}`}  onChange={(e)=> setCategoria(e.target.value)}>
                                        <option className={`${optionInput}`} value="Públicação">Públicação</option>
                                        <option className={`${optionInput}`} value="Edital/Anexos">Edital/Anexos</option>
                                        <option className={`${optionInput}`} value="Recibo Edital">Recibo Edital</option>
                                        <option className={`${optionInput}`} value="Cotação">Cotação</option>
                                        <option className={`${optionInput}`} value="C.P.H">C.P.H</option>
                                        <option className={`${optionInput}`} value="Esclarecimento">Esclarecimento</option>
                                        <option className={`${optionInput}`} value="Impugnação">Impugnação</option>
                                        <option className={`${optionInput}`} value="Decisão Impugnação">Decisão Impugnação</option>
                                        <option className={`${optionInput}`} value="Vistoria">Vistoria</option>
                                        <option className={`${optionInput}`} value="V.D.C">V.D.C</option>
                                        <option className={`${optionInput}`} value="Intenção Recuso">Intenção Recuso</option>
                                        <option className={`${optionInput}`} value="Recurso">Recurso</option>
                                        <option className={`${optionInput}`} value="Contrarrazão">Contrarrazão</option>
                                        <option className={`${optionInput}`} value="Parecer Jurídico">Parecer Jurídico</option>
                                        <option className={`${optionInput}`} value="Decisão Final Recurso">Decisão Final Recurso</option>
                                        <option className={`${optionInput}`} value="Ata Realização">Ata Realização</option>
                                        <option className={`${optionInput}`} value="act">Proposta Readequada</option>
                                        <option className={`${optionInput}`} value="Amostra">Amostra</option>
                                        <option className={`${optionInput}`} value="Homologação">Homologação</option>
                                        <option className={`${optionInput}`} value="Ajdudicação">Ajdudicação</option>
                                        <option className={`${optionInput}`} value="arp">Ata Registro Preços</option>
                                        <option className={`${optionInput}`} value="contrato">Contrato</option>
                                        <option className={`${optionInput}`} value="Seguro Garantia Contratual">Seguro Garantia Contratual</option>
                                        <option className={`${optionInput}`} value="Empenho">Empenho</option>
                                        <option className={`${optionInput}`} value="os">Ordem Serviço'ᴮ</option>
                                        <option className={`${optionInput}`} value="Comprovante Entrega">Comprovante Entrega</option>
                                        <option className={`${optionInput}`} value="Nota Fiscal">Nota Fiscal</option>
                                        <option className={`${optionInput}`} value="Boleto">Boleto</option>
                                        <option className={`${optionInput}`} value="Comprovante Pagamento">Comprovante Pagamento</option>
                                        <option className={`${optionInput}`} value="Adesão">Adesão</option>
                                        <option className={`${optionInput}`} value="Ofício's">Ofício's</option>
                                        <option className={`${optionInput}`} value="act">A.C.T</option>
                                        <option className={`${optionInput}`} selected={true} value="Outros">Outros</option>
                                </select>
                            </div>

                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Valor</label>
                                <input type="number" name="Valor" id="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder='R$ 000,00' onChange={(e)=> setValor(e.target.value)} value ={Valor}/>
                            </div>

                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">SineDie</label>
                                
                                <label htmlFor="sineDieTrue" className='mr-2'> Sim</label>
                                <input type="radio" name="SineDie" id='sineDieTrue' defaultChecked ={SineDie} className='mr-2' onChange={(e)=> {if(e.target.checked){setSineDie(true)}}}/>
                                
                                <label htmlFor="sineDieFalse" className='mr-2'> Não </label>
                                <input type="radio" name="SineDie" id='sineDieFalse' onChange={(e)=> {if(e.target.checked){setSineDie(false)}}}/>

                            </div>

                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data de emissão </label>
                                <input type="datetime-local" name="DataEmissao" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" onChange ={(e)=> setDataEmissao(e.target.value)}/>
                            </div>

                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data de vencimento </label>
                                <input type="datetime-local" name="DataVencimento" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"   onChange ={(e)=> setDataVencimento(e.target.value)}/>
                            </div>


                        </div>

               
                        <div className='flex flex-row gap-2'>
                            {
                            !empresaError ?
                                <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <BsPlus className='text-xl'/>
                                Criar pasta
                                </button>
                            :
                                <button disabled={true} className="text-white inline-flex items-center bg-blue-700 focus:ring-4  font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 opacity-65">
                                    <BsPlus className='text-xl'/>
                                    Criar pasta
                                </button>
                            }
                            <label onClick={fechar}  className="text-white inline-flex items-center cursor-pointer bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                                <BsX className='text-xl'/>
                                Cancelar
                            </label>
                        </div>
                        


                    </form>
                </div>
            </div>
        </div> 
  )
}

export default NovaPasta