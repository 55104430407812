import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { BsEye, BsFolder, BsFolderPlus, BsPencil, BsTrash, BsX } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import { usePastasUtils } from '../../Utils/ReactQuery/getPastasUtils';
import LoadingComponent from '../LoaadingComponent';
import DashboardPastasModal from '../DashboardComponents/DashboardPastasModal';
import styles from '../PastasComponents/Pastas.module.css';
import Pastas from '../../Pages/Pastas';
import PastaConteudo from '../PastasComponents/PastaConteudo';
import { DisplayPastas } from '../../Utils/types/pastasTypes';
import ApagarPasta from '../PastasComponents/ApagarPasta';
import PastaInfo from '../PastasComponents/PastaInfo';
import { formatarData } from '../../Utils/formatarData';
import NovaPasta from '../PastasComponents/NovaPasta';
import { CgLoadbar } from "react-icons/cg";
import { MdLink, MdLinkOff } from 'react-icons/md';

const PortalPastas = () => {

    const [pasta, setPasta] = useState<any>();
    const [pastaModal, setPastaModal] = useState<boolean>(false);
    const [empresaSelecionada, setEmpresaSelecionada] = useState<string | null>(localStorage.getItem('empresaSelecionada') || null);



    const [pastasCollapsed, setPastasCollapsed] = useState<boolean>(false);

    useLayoutEffect(() => {
        setEmpresaSelecionada(localStorage.getItem('empresaSelecionada'))
    })


    const token = localStorage.getItem('token')
    const { selecionarEmpresa } = useReactQueryEmpresas()
    const { getPastas } = usePastasUtils();

    const { data: pastasData, isLoading, isError } = useQuery({ queryKey: ["rqPastas", empresaSelecionada], queryFn: () => getPastas(empresaSelecionada as string) });


    if(pastasData){
        console.log('Pastas; ', pastasData)}

    const { data: empresaData, isLoading: empresaIsLoading } = useQuery({ queryKey: ["rqEmpresaSelecionada"], queryFn: () => selecionarEmpresa({ token: token as string, empresaid: empresaSelecionada as string }) })


    const [editando, setEditando] = useState<boolean>(false);
    const [apagando, setApagando] = useState<boolean>(false);
    const [abrirPasta, setAbrirPasta] = useState<any>('');
    const [criarPasta, setCriarPasta] = useState<boolean>(false)


    const handleAbrirPasta = (e: any, pasta: any) => {
        e.preventDefault();
        setPasta(pasta);
        setAbrirPasta(true)
    }


    const handleEditarPasta = (e: any, pastaid: string) => {
        const pastaEditada = pastasData.pastas.find((i: any) => {
            return i._id === pastaid
        })
        setPasta(pastaEditada);
        setEditando(true);
    }

    const hanldeApagarPasta = (e: any, pastaid: string) => {
        const pastaEditada = pastasData.pastas.find((i: any) => {
            return i._id === pastaid
        })
        setPasta(pastaEditada);
        setApagando(true);
    }

    const fechar = (e: any) => {
        e.preventDefault();
        setEditando(false);
        setApagando(false)
        setAbrirPasta(false);
        setCriarPasta(false);
        setPasta('')
        return;
    }


    const pastaStatus = (pasta: any) => {


        const dataVencimento = Date.parse(pasta.DataVencimento);
        const dataAtual: any = new Date().getTime();
        const statusNumber: any = ((dataVencimento - dataAtual) / (1000 * 60 * 60 * 24)).toFixed(0)


        switch (true) {
            case statusNumber > 0 && statusNumber > 7:
                return 'Vigente';
                break;
            case statusNumber <= 0:
                return 'Vencido';
                break;
            case statusNumber <= 7:
                return `Vence em ${statusNumber} dias`;
                break;
            default:
                return 'Vigente'
        }

    }


    // Tailwind ----------------------------
    const statusVigente = 'text-green-500 font-semibold';
    const statusVencido = 'text-red-500 font-semibold';
    const statusVencimentoPróximo = 'text-yellow-500 font-semibold'

    // Tailwind ----------------------------


    // //~~MANUTENÇÃO~~//

    // //~~MANUTENÇÃO~~//


    if (isLoading || !pastasData.pastas) {
        return (
            <div className={`w-full dark:bg-gray-600 shadow-2xl ${styles.dashboardPastas}`}>
                <LoadingComponent />
            </div>
        )
    }

    return (
        <div className={`w-full dark:bg-gray-600 shadow-2xl h-fit`}>

            {
                pastaModal && <DashboardPastasModal fechar={fechar} pasta={pasta} />
            }

            {
                apagando && <ApagarPasta pasta={pasta} fechar={fechar} />
            }

            {
                editando && <PastaInfo pasta={pasta} fechar={fechar} editando={true} />
            }

            {
                criarPasta && <NovaPasta fechar={fechar} />
            }

            {
                abrirPasta &&
                <div id="default-modal" aria-hidden="true" className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" >
                    <div className="relative p-4 w-full max-w-fit max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 ">

                            <span className='absolute right-3 top-3' onClick={fechar}><BsX className='text-3xl cursor-pointer' /></span>


                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <PastaConteudo pasta={pasta} displayPastas={DisplayPastas.DETAILS} arquivos={pastasData.arquivos} />
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className={`flex flex-col ${styles.dashboardPastas} h-52`}>

                {

                    pastasData ?
                        <div className="relative overflow-x-auto">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 cursor-pointer items-center flex" onClick={() => setCriarPasta(current => !current)}>
                                            <BsFolderPlus className='mr-1 cursor-pointer' />
                                            Pasta
                                        </th>
                                        <th scope="col" className="px-6">
                                            Opções
                                        </th>

                                        <th scope="col" className="px-6">
                                            Categoria
                                        </th>

                                        <th scope="col" className="px-6">
                                            Emissão
                                        </th>
                                        <th scope="col" className="px-6">
                                            Vencimento
                                        </th>


                                        <th scope="col" className="px-6">
                                            Valor
                                        </th>
                                        <th scope="col" className="px-6">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pastasData.pastas && pastasData.pastas.map((pasta: any) => (
                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                                                <th scope="row" className="px-6 font-medium text-gray-900
                          underline underline-offset-2 whitespace-nowrap dark:text-white">
                                                    <Link to={`/portal/pastas/${pasta.NomePasta}`} className='cursor-pointer w-fit'> {pasta.NomePasta.replace("_", " ")}</Link>
                                                </th>

                                                <td className="px-6 inline-flex text-black dark:text-gray-300  gap-4">
                                                    {
                                                    pasta.LinkOEA ?
                                                        <a target='_blank' href={pasta.LinkOEA} className='cursor-pointer opacity-80 hover:opacity-100 text-xl'><MdLink/></a>
                                                        :
                                                        <span className='cursor-pointer opacity-80 hover:opacity-100 text-xl' ><MdLinkOff/></span>
                                                    }

                                                    <BsFolder className='cursor-pointer opacity-80 hover:opacity-100' onClick={(e) => handleAbrirPasta(e, pasta)} />
                                                    <BsPencil className='cursor-pointer opacity-80 hover:opacity-100' onClick={(e) => handleEditarPasta(e, pasta._id)} />
                                                    <BsTrash className='cursor-pointer opacity-80 hover:opacity-100' onClick={(e) => hanldeApagarPasta(e, pasta._id)} />
                                                </td>


                                                <td className="px-6">
                                                    {pasta.Categoria}
                                                </td>


                                                <td className="px-6">
                                                    {formatarData(pasta.DataEmissao)}
                                                </td>
                                                <td className="px-6">
                                                    {formatarData(pasta.DataVencimento)}
                                                </td>

                                                <td className="px-6">
                                                    {pasta.Valor}
                                                </td>

                                                <td className="px-6">
                                                    <span
                                                        className={pastaStatus(pasta) === 'Vigente' ? `${statusVigente}` : pastaStatus(pasta) === 'Vencido' ? `${statusVencido}` : `${statusVencimentoPróximo}`}>
                                                        {pastaStatus(pasta)}

                                                    </span>

                                                </td>

                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                        :
                        <>

                        </>
                }
            </div>

        </div>
    )
}

export default PortalPastas