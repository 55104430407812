import React from 'react'
import { Link } from 'react-router-dom'
import NotificaçõesComponent from '../Notificações/NotificaçõesComponent'
import { BsCaretDown, BsLock } from 'react-icons/bs'
import { FaUser } from 'react-icons/fa'
import { useUserContext } from '../../Context/useUserContext'
import { MdLogout } from 'react-icons/md'

type Props = {}

const NavBar = (props: Props) => {



  const [userContext] = useUserContext();


  //Tailwind ---------------------------
  const activeTab ="inline-flex items-center justify-sttart p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group" ;
  const inactiveTab ="inline-flex items-center  justify-start p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group";
  const inactiveToggle = "inline-flex  p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 group";
  const activeMenu = "flex-col dark:bg-gray-800 min-h-screen flex-wrap text-sm font-medium  text-gray-500 dark:text-gray-400 bg-gray-100 dark:bg-gray-600 text-left"
  const inactiveMenu = "hidden"
  const activeList = 'flex flex-col mt-0 z-50 min-h-screen'
//Tailwind ---------------------------

  return (
    <div className='w-full bg-gray-50 min-h-12 z-50 fixed top-0'>

      <NotificaçõesComponent />
      
       </div>


                    
                    
  )
}

export default NavBar