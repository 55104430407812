import React, { useEffect, useState } from 'react'
import Chart from './Operações'
import Operações from './Operações'
import Organograma from '../NetWorkComponents/Organograma'
import { AiOutlineLeft, AiOutlineRight, AiOutlineUp } from 'react-icons/ai'
import { useUserContext } from '../../Context/useUserContext'
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useReactQueryDashboard } from '../../Utils/ReactQuery/getDashboardUtils'
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas'
import LoadingSmall from '../LoadingSmall'


const DashboardLarea = () => {

  const [userContext] = useUserContext();
  const [empresa, setEmpresa] = useState<any>('');
  const [chartUmData, setChartUmData] = useState<any>([]);
  const [totalProcessos, setTotalProcessos] = useState<any>([]);

  // //~~MANUTENÇÃO~~//

  // //~~MANUTENÇÃO~~//


  // ---------------------------------

  
  // ---------------------------------
  const token = localStorage.getItem('token');
  const empresaPadrão = localStorage.getItem('empresaPadrão');
  const empresaSelecionada = localStorage.getItem('empresaSelecionada');
  const [empresasTotais, setEmpresasTotais] = useState<[string]>([''])
  const { getDashboardData } = useReactQueryDashboard()

  const { empresasPermitidas } = useReactQueryEmpresas();
    const { data: empresasDisponiveis, isLoading: empresasDisponiveisLoading } = useQuery({
        queryFn: () => empresasPermitidas(token as string),
        queryKey: ['rqEmpresasPermitidas'],
        staleTime: Infinity,
        refetchOnWindowFocus: false
    });
    

    if(empresasDisponiveis){
      const totalTemp = empresasDisponiveis.empresas.flatMap((i: any)=>{
        return i._id
      })
      console.log('Empresas disponives: ', totalTemp)
    }

  const { data: chartData, isLoading: chartIsLoading , refetch} = useQuery({
    queryFn: () => getDashboardData({ token: token as string, empresaid: empresaSelecionada as string }),
    queryKey: ['rqChart', empresaSelecionada],
    staleTime: 1000 * 60 * 3,
  })

  useEffect(() => {
    if (chartData) {
      setChartUmData(chartData.contratos
      )
      setTotalProcessos(chartData.totalProcessos)
    }
  }, [chartData])
  //------------------------------------
  useEffect(()=>{
    if(userContext){
      setEmpresa(userContext.EmpresaPadrão)
    }
  },[userContext])

  //Mutação --------------------------------------------------

    
  
  //Mutação --------------------------------------------------

  const changeEmpresa = (empresaSelecionada: any)=>{
    try {
    if(empresaSelecionada !== '' && empresasDisponiveis){

      const empresaTemp = empresasDisponiveis.empresas.find((i: any)=>{
        return i._id === empresaSelecionada
      })
      setEmpresa(empresaTemp)

      
      localStorage.setItem('empresaSelecionada', empresaSelecionada)
      refetch()
    }
    if(empresaSelecionada === empresaPadrão || empresaSelecionada === ""){
      setEmpresa(userContext.EmpresaPadrão);
      localStorage.setItem('empresaSelecionada', empresaPadrão as string);
      refetch()


    }
    } catch (error) {
      console.error(error);
      return
    }
  }

  //Tailwind --------------------------------------------------------`
  const activeTab = 'rounded-full bg-black h-3 w-3 opacity-75 border border-black cursor-pointer hover:opacity-100';
  const inactiveTab = 'rounded-full bg-white h-3 w-3 opacity-75 border border-black cursor-pointer hover:opacity-100'

  const inputField = 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500';
  const labelField = 'block mb-2 text-sm font-medium text-gray-900 dark:text-white';


  const portrail = 'max-w-5xl max-h-80 min-h-72 overflow-y-auto chart';
  const expanded = 'w-full h-fit left-0 top-0  overflow-y-hidden overflow-x-hidden'


  //Tailwind --------------------------------------------------------`

  return (

    <section className="w-full h-full dark:bg-transparent rounded-lg">
      <div className="mt-20 pb-8 px-4 mx-auto max-w-screen-xl lg:py-2 z-10 relative flex flex-col justify-around rounded-lg shadow-lg">
        <h1 className="mb-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-3xl dark:text-white">Bem vindo <span>{userContext.User.Nome}</span></h1>

        <div className='flex justify-between gap-4 mb-5'>

          <div className='flex flex-col'>
            <div className='w-12 h-12 rounded-full object-contain origin-center '>
              {
                empresa && empresa.LogoEmpresa ?
                <img src={empresa && empresa.LogoEmpresa} alt="Empresa" className='w-12 h-12 rounded-full' />
                :
              
                <></>
              }

            </div>
            <span className='mt-4 font-semibold'> {empresa && empresa.NomeEmpresa}</span>

           {empresasDisponiveis && empresasDisponiveis.empresas && 
            <p className='max-w-lg'> {empresasDisponiveis.empresas[0].NomeEmpresa} e mais {empresasDisponiveis.empresas.length} outras </p>
           }
            
          </div>

          {

          userContext && userContext.User.AcessoAtual !== "Empresa" &&

         <div className='flex flex-col '>
            <p>Visualizando agora: <span className='font-semibold'>Quadro geral das empresas assessoradas</span></p>
            <label htmlFor=""> Selecione uma empresa para ver o relatório de operações</label>

            {
               
                chartIsLoading ? <label> <LoadingSmall/></label>:
              
              <select className={inputField}  onChange={(e)=> changeEmpresa(e.target.value)}>
                <option value=""> Selecione uma empresa </option>
             
              {
                empresasDisponiveis && empresasDisponiveis.empresas.map((empresa: any)=>(
                  <option key={empresa._id} value={empresa._id}>{empresa.NomeEmpresa} - ({empresa.CnpjEmpresa}) </option>
                ))
              }
            </select>
            }

          </div> 
          
          }

        </div>




        <div className={expanded}>

          <div className='fadeIn'><Operações dadosVencimento={chartUmData} totalProcessos={totalProcessos} /></div>

        </div>

      </div>

    </section>



  )
}

export default DashboardLarea