import React, { useState } from 'react';
import SideNavWrapper from '../Components/PortalComponents/SideNavWrapper';
import BannerNetwork from '../Components/NetWorkComponents/BannerNetwork';
import Organograma from '../Components/NetWorkComponents/Organograma';

const BemVindo: React.FC = () => {

    return (
        <div className="flex ">
            <SideNavWrapper />
            <div className="w-full ">
                <BannerNetwork />
                <Organograma />
                
            </div>
        </div>
    );
};

export default BemVindo;