import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react'
import { BsFileEarmarkSpreadsheet, BsQuestionCircle, BsX } from 'react-icons/bs';
import { IMaskInput } from 'react-imask';
import { toast } from 'react-toastify';

import { useUserContext } from '../../Context/useUserContext';
import { getCnpjData } from '../../Utils/cnpjAutocomplete';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';

import { InputUpdateStatus, UserUpdateStatus } from '../../Utils/types/userType';
import LoadingPage from '../LoadingPage';
import IncriçãoMunicipalHelp from '../PerfilComponents/IncriçãoMunicipalHelp';
import styles from '../PerfilComponents/Perfil.module.css'


const CriarEmpresa = () => {

    const [userContext, setUserContext] = useUserContext();
    const solicitação = userContext.Solicitações;
    const permissão = userContext.Permissões;
    const [admin, setAdmin]= useState<Boolean | null>(false)
    const [solicitado, setSolicitado] = useState<Boolean | null>(null);
  
     useEffect(()=>{
      if(permissão && permissão.includes("Adm")){
        setAdmin(true)
      }
      if(solicitação){
        setSolicitado(solicitação)
      }
     },[])
 

  const [loadingAutocomplete, setLoadingAutoComplete] = useState<Boolean>(false);

  //--- Validações padrão-------
  const [cnpj, setCnpj] = useState<String>('');
  const [departamentos, setDepartamentos] = useState<[String]>(['']);
  const [departamentosTmp, setDepartamentosTmp] = useState<String>('');
  const [cargos, setCargos] = useState<[String]>(['']);
  const [cargosTmp, setCargosTmp] = useState<String>('');
  const [foto, setFoto] = useState();

  const [helpIncriçãoMunicipal, setHelpInscriçãoMunicipal] = useState<Boolean>(false);

  const [updateStatus, setUpdateStatus] = useState<InputUpdateStatus>(InputUpdateStatus.IDLE);
  // Usa ctrl + spacepara ver o auto complete desse state

  const [cnpjData, setCnpjData] =useState<any>()

  const [cnpjVerify, setCnpjVerify] = useState<Boolean>(false);

  const [razaoSocial, setRazaoSocial] = useState<String>('');
  const [nomeFantasia, setNomeFantasia] = useState<String>('');
  const [nire, setNire] = useState<String>('');
  const [naturezaJuridica, setNaturezaJuridica] = useState<String>('');
  const [mei, setMEI] = useState<Boolean>(false);
  const [portedaEmpresa, setPortedaEmpresa] = useState<String>('');
  const [atividadeEconômicaP, setAtividadeEconômicaP] = useState<String>('');
  const [atividadeEconômicaS, setAtividadeEconômicaS] = useState<String>('');
  const [capitalSocial, setCapitalSocial] = useState<String>('');

  const [inscriçãoMunicipal, setInscriçãoMunicipal] = useState<String>('');
  const [inscriçãoEstadual, setInscriçãoEstadual] = useState<String>('');
  const [dataAbertura, setDataAbertura] = useState<String>('');
  const [endereçoEmpresa, setEndereçoEmpresa] = useState<String>('');
  const [complementoEmpresa, setComplementoEmpresa] = useState<String>();
  const [cepEmpresa, setCepEmpresa] = useState<String>('');
  const [foneEmpresa, setFoneEmpresa] = useState<String>('');
  const [emailEmpresa, setEmailEmpresa] = useState<String>('');
  const [siteEmpresa, setSiteEmpresa] = useState<String>('');
  const [NomeEmpresa, setNomeEmpresa] = useState<String>('');

  enum StatusTabs{
    IDLE = 'inalterado',
    ALTERADO ='alterado',
    ERRO = 'erro'
  }
  enum SituaçãoFinanceira{
    POSITIVO ='Positivo',
    NEGATIVO= 'Negativo',
    IDLE = ''
  }
  const [situaçãoFinanceira, setSituaçãoFinanceira] = useState<SituaçãoFinanceira>(SituaçãoFinanceira.IDLE);

  const [statusDepartmaneto, setStatusDepartamento] = useState<StatusTabs>(StatusTabs.IDLE);
  const [statusCargos, setStatusCargos] = useState<StatusTabs>(StatusTabs.IDLE);
  const [departamentoErrorMsg, setDepartamentoErrorMsg] = useState<string>('');
  


  const verifyStatusDep = (input: string)=>{

      switch (true){
        case departamentos.length < 3: 
          setStatusDepartamento(StatusTabs.ERRO)
          setDepartamentoErrorMsg('É necessário informar ao menos 3 departamentos!')
        break;
        case departamentos.includes(departamentosTmp):
            setStatusDepartamento(StatusTabs.ERRO);
            setDepartamentoErrorMsg('Departamento já cadastrado');
        break;
        case departamentos.length >= 3 && input.length > 0:
          setStatusDepartamento(StatusTabs.ALTERADO);
          setDepartamentoErrorMsg('Departamento já cadastrado');
        break;
        default:
          setStatusDepartamento(StatusTabs.IDLE)
      }

  }

  //---- Mutação ---------------------
//   const queryClient = useQueryClient();
//   const updateEmpresaMutation = useMutation({
//     mutationFn: alterarEmpresa,
//     onMutate: async(config)=>{

//       queryClient.invalidateQueries({queryKey: ['rqEmpresa']});
//       queryClient.refetchQueries({queryKey: ['rqEmpresa']});

//     },
//     onSuccess: async(response)=>{
//       if(response.status ===1){
//         setUpdateStatus(InputUpdateStatus.SUCCESS);
//         toast.success(response.msg, {autoClose: 3000});
//       }
//       if(response.status === 3){
//         setUpdateStatus(InputUpdateStatus.ERROR);
//         toast.error(response.msg,{autoClose: 3000})
//       }
//     },
//     onError: (error)=>{
//       console.error(error);
//       setUpdateStatus(InputUpdateStatus.ERROR);
//       toast.error('Erro interno!', {autoClose: 5000})
//     },
//     onSettled: async(response)=>{
//       console.log('Mutação concluída!')
//     }
//   })
 
  //---- Mutação ---------------------]

  const checkIfNewDepartamento =(arr1: [string], arr2: [string]): boolean=>{
    const a1 = JSON.stringify(arr1);
    const a2 = JSON.stringify(arr2);
    if(a1 === a2){
      return true
    }else{
      return false
    }
  }

  // Funções utilitárias ------------------------------------------------------------------

  const handleUserUpdate =(e: any)=>{
    e.preventDefault();
    
    setUpdateStatus(InputUpdateStatus.LOADING);
  
    const formData = new FormData(e.currentTarget);

    const updateData = { 
      CnpjEmpresa : formData.get('CnpjEmpresa'),
      NomeEmpresa : formData.get('NomeEmpresa'),
      RazãoSocial : formData.get('RazãoSocial'),
      NomeFantasia : formData.get('NomeFantasia'),
      LogoEmpresa : formData.get('LogoEmpresa'),
      Nire : formData.get('Nire'),
      NaturezaJuridica : formData.get('NaturezaJuridica'),
      PorteEmpresa : formData.get('PorteEmpresa'),
      AtividadeEconômicaP : formData.get('AtividadeEconômicaP'),
      AtividadeEconômicaS : formData.get('AtividadeEconômicaS'),
      CapitalSocial : formData.get('CapitalSocial'),
      SituaçãoFinanceira : formData.get('SituaçãoFinanceira'),
      InscriçãoMunicipal : formData.get('InscriçãoMunicipal'),
      InscriçãoEstadual : formData.get('InscriçãoEstadual'),
      Site : formData.get('Site'),
      DataAbertura: formData.get('DataAbertura'),
      MEI: mei,
      Fone: formData.get('FoneEmpresa'),
      Cep: formData.get('Cep'),
      Complemento: formData.get('Complemento'),
      Endereço: formData.get('Endereço'),
      Departamentos: departamentos,
      Cargos:  cargos
    
    }

   
    const dataLength = Object.values(updateData).filter((i:any)=> i!=="").length

    if(dataLength <= 0){
        setUpdateStatus(InputUpdateStatus.ERROR);
        toast.error('Não são permitidos envios vazios!')
        return;
    }


    const filteredEmpresa =Object.fromEntries( Object.entries(updateData).filter(([key, value])=> value !== "" &&  value !== null ));

    const token = localStorage.getItem('token')
    // updateEmpresaMutation.mutate({token: token as string, data: filteredEmpresa, empresaId: empresaPadrão._id})
  
}

  const handleDepartamento: any =(e: any)=>{

    if(departamentos.includes(departamentosTmp)){
      setDepartamentoErrorMsg('Departamentos repetidos!');
      setStatusDepartamento(StatusTabs.ERRO);
      return;
    }

    setStatusDepartamento(StatusTabs.ALTERADO);
    setDepartamentos((): any=> [...departamentos, departamentosTmp]);

    return;
  }

  const handleCargos: any =(e: any)=>{
    e.preventDefault();
    setCargos((): any=> [...cargos, cargosTmp]);
    return;
  }

const removeDepartamento =(e:any)=>{
    e.preventDefault();
    const tmpRemoval = departamentos.filter(i=> i !== e.target.id)
    setDepartamentos(tmpRemoval as [String]);
    return;
}

const removeCargo =(e: any) =>{
    e.preventDefault();
    const tmpRemoval = cargos.filter(i=> i !== e.target.id)
    setCargos(tmpRemoval as [String]);
    return;
}
    //-------------------------
    const inputField = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

    const inputFiled: string =` w-full
    block py-2.5 px-0  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer
    `
   
    const labelField: string = `
     peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6
    `;
    
    const company = `
    text-xl h-35 md:text:md
    border-t-2 border-solid 
      mb-4 text-gray-500 dark:text-gray-400
    `

    const activeTable : string="flex flex-wrap text-sm font-medium text-center w-full rounded-md h-50 border-2 border-green-500 border-solid h-32 text-green-500 dark:text-green-400"
    const errorTable  : string="flex flex-wrap h-32 text-sm font-medium text-center w-full rounded-md h-50 border-2 border-red-500 border-solid text-red-500 dark:text-red-400"
    const table: string = `flex flex-wrap text-sm h-32 font-medium text-center w-full rounded-md h-50 border-2 border-gray-500 border-solid text-gray-500 dark:text-gray-400 `;

    const emptyLabel = `text-xl h-35 md:text:md
      mb-4 text-gray-500 dark:text-gray-400`;

    const comonText = `text-md h-35 md:text:sm
      mb-4 text-gray-500 dark:text-gray-400`

    const lowKeyButton =`text-md h-35 md:text:sm
      mb-4 text-gray-500 dark:text-gray-400 opacity-80 hover:opacity-100 transition-opacity`

    const inactiveButton =`text-white bg-blue-400 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center opacity-75 dark:focus:ring-blue-200 my-6`;
    const activeButton =`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 my-6`;
  // Tailwind -----------------


  const verifyCnpj = async(e: any)=>{
    e.preventDefault();

    if(cnpj.length < 14){
      setLoadingAutoComplete(false);
      return;
    }

    setLoadingAutoComplete(true);  
    setUpdateStatus(InputUpdateStatus.LOADING)
    setTimeout(async()=>{
      
      const res = await getCnpjData(cnpj as string);

      if(res === null ){
        setLoadingAutoComplete(false);  
        return;
      }

      setCnpjData(res);
      setNaturezaJuridica(res.natureza_juridica);
      setCapitalSocial(res.capital_social);
      setRazaoSocial(res.razao_social);
      setNomeFantasia(res.nome_fantasia);
      setDataAbertura(res.data_inicio_atividade);
      setEndereçoEmpresa(`${res.logradouro}, ${res.municipio}, ${res.uf}`); 
      setComplementoEmpresa(res.complemento); 
      setCepEmpresa(res.cep );
      setFoneEmpresa(res.ddd_telefone_1 ); 
      setPortedaEmpresa(res.porte)

      setMEI(res.data_opcao_pelo_mei)

      console.log(res);   
      setLoadingAutoComplete(false)
      setUpdateStatus(InputUpdateStatus.IDLE)

      return;
    }, 3000)
    
    
  }


  if(updateStatus === InputUpdateStatus.LOADING){
    return(
      <LoadingPage/>
    )
  }


  const close =(e: any)=>{
    e.preventDefault()
    setHelpInscriçãoMunicipal(false)
  }


  return (
    <div className='w-full flex flex-col mt-5'>

    <span className='w-fit flex felx-row ml-4 gap-2 cursor-pointer opacity-80 hover:opacity-100 transition-opacity' onClick={()=> setHelpInscriçãoMunicipal(current => !current)}> <BsQuestionCircle/> Mais informações </span>

      {
        helpIncriçãoMunicipal && <IncriçãoMunicipalHelp close ={close}/>
      }

        <form onSubmit={handleUserUpdate} className='p-5 border-b-2 border-white border-solid' >
          
          <div className={`${styles.inputField}`}>
            <input type="file" name="Foto" placeholder='Envia a nova foto' className=' mt-5 rounded-lg' />
            <small  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> São
            permitidos apenas arquivos .png, .jpg e .ico com tamanho máximo de 15mb</small>
          </div>

          <div className={`${styles.autoGridForm} gap-4 my-4 h-fit`}>

            <div className={`${styles.inputField}`} >
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome da empresa</label>
              <input type="text" name="NomeEmpresa" className={`${inputField}`} onChange={(e)=> setNomeEmpresa(e.target.value)}/>
            </div>
            
            <div className={`${styles.inputField}`} >
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cnpj da empresa</label>
              <input type="text" name="CnpjEmpresa" className={`${inputField}`} onChange ={(e)=> setCnpj(e.target.value)}/>
              <div className="relative z-0 w-full mb-5 group text-left ">       
                    <button  className ={`${lowKeyButton} mt-2`} onClick={verifyCnpj}> Buscar dados do CNPJ </button>  
              </div>
              {loadingAutocomplete && <p className='text-white'> Aguarde, buscando dados ...</p>}
            </div>

          
              <div className={`${styles.departamentos}`}>

              <label className={`${emptyLabel}`}> Departamentos </label>
              <p className={`${comonText}`}> Informe ao menos 3 departamentos da sua empresa</p>
              <ul className={statusDepartmaneto === StatusTabs.IDLE ? `${table}` : statusDepartmaneto === StatusTabs.ERRO ? `${errorTable}` : `${activeTable}`} >
                  {
                      departamentos.length >= 0?
                      departamentos.map((dp: any, index: number)=>(
                          <li key={index} className="me-2 flex flex-row gap-1 bg-blue-500 h-fit rounded-md items-center justify-center text-white">
                            <span > {
                                  dp
                              }
                              </span>
                              <BsX id={String(dp)} onClick ={removeDepartamento}/>
                          </li>
                      ))
                      :
                      <p> Nenhum departamento informado!</p>
                  }
              </ul>

              <div className="relative z-0 w-full mb-5 group text-left ">       
                  <input type="text" name="Departamentos" className={`${inputFiled}`}  placeholder="Departamento"  onChange={(e)=> setDepartamentosTmp(e.target.value)} onBlur ={(e)=>verifyStatusDep(e.target.value) }/>  
                  {statusDepartmaneto === StatusTabs.ERRO && <p className={emptyLabel}> {departamentoErrorMsg}</p> }
                  <button onClick={handleDepartamento} className ={`${lowKeyButton}`} > Inserir departamento </button>
              </div>
          </div>

          <div className={`${styles.cargos}`}>
            <label className={`${emptyLabel}`}> Cargos </label>
            <p className={`${comonText}`}> Informe ao menos 3 cargos da sua empresa</p>   
            <ul className={`${table} `} >
                {
                    cargos.length > 0 ?
                    cargos.map((cr, index)=>(
                        <li key ={String(index)} className="me-2 flex flex-row gap-1 bg-blue-500 h-fit rounded-md items-center justify-center text-white">
                          <span> {String(cr)}</span>
                            <BsX id={String(cr)} onClick ={removeCargo}/>
                        </li>
                    ))
                    :
                    <p> Nenhum cargo informado! </p>
                }
            </ul>

            <div className="relative z-0 w-full mb-5 group text-left ">       
                <input type="text" name="Cargos" className={`${inputFiled}`}  placeholder=" Cargos"  onChange={(e)=> setCargosTmp(e.target.value)} />   
                <button onClick={handleCargos} className ={`${lowKeyButton}`}> Inserir cargo </button>    
            </div>

          </div>
    
    
          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Razão social</label>
            <input type="text" name="RazãoSocial" className={`${inputField}`} value ={razaoSocial as string} onChange ={(e)=> setRazaoSocial(e.target.value)}/>
          </div>

          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Telefone da empresa </label>
            
            <IMaskInput type="text" name="FoneEmpresa" className={`${inputField}`} value ={foneEmpresa as string}
            mask={'(00)-00000000'}
            onAccept ={(value, mask)=> setFoneEmpresa(value)}
            />
            <small> Informe o ddd, ex: (62)1234567</small>
          </div>


            <>
            <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Natureza jurídica</label>
            <input type="text" name="NaturezaJuridica" className={`${inputField}`}  value ={naturezaJuridica as string} onChange ={(e)=> setNaturezaJuridica(e.target.value)}/>
          </div>

          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Capital social</label>
            <input type="text" name="CapitalSocial" className={`${inputField}`} value ={capitalSocial as string} onChange ={(e)=> setCapitalSocial(e.target.value)} />
            
          </div>


          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome fantasia</label>
            <input type="text" name="NomeFantasia" className={`${inputField}`}  onChange ={(e)=> setNomeFantasia(e.target.value)}/>
          </div>
          
          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Porte</label>
            <input type="text" name="PorteEmpresa" className={`${inputField}`} onChange ={(e)=> setPortedaEmpresa(e.target.value)} value={portedaEmpresa as string}/>
          </div>

          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data da abertura</label>
            <input type="text" name="DataAbertura" className={`${inputField}`} value ={dataAbertura as string} onChange ={(e)=> setDataAbertura(e.target.value)}/>
          </div>

          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Situação financeira  </label>
              <select className='text-black dark:text-black rounded-md bg-white  cursor-pointer opacity-80 hover:opacity-100 transition-opacity' name="SituaçãoFinanceira" onChange={(e)=> setSituaçãoFinanceira(e.target.value as SituaçãoFinanceira)}>
                <option className='rounded-sm' defaultChecked={true} value={SituaçãoFinanceira.POSITIVO}>Positivo </option>
                <option className='rounded-sm' value={SituaçãoFinanceira.NEGATIVO}>Negativo </option>
              </select>
          </div>  
          
          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Inscrição municipal  </label>
            <input type="text" name="InscriçãoMunicipal" className={`${inputField}`} value ={inscriçãoMunicipal as string} onChange ={(e)=> setInscriçãoMunicipal(e.target.value)}/>


          </div>
          
          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Inscrição estadual  </label>
            <input type="text" name="InscriçãoEstadual" className={`${inputField}`}  value ={inscriçãoEstadual as string} onChange ={(e)=> setInscriçãoEstadual(e.target.value)}/>
          </div>
          
          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> NIRE  </label>
            <input type="text" name="Nire" className={`${inputField}`} onChange ={(e)=> setNire(e.target.value)} minLength={11} maxLength={11}/>
          </div>

          
          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cep</label>

            <IMaskInput
            mask={'000.000-00'}
            value ={cepEmpresa as string}
            onAccept ={(value, mask)=> setCepEmpresa(value)}
             name="Cep" className={`${inputField}`}  
            />
          </div>

          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Endereço</label>
            <input type="text" name="Endereço" className={`${inputField}`} value ={endereçoEmpresa as string } onChange ={(e)=> setEndereçoEmpresa(e.target.value)}/>
          </div>


          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Complemento </label>
            <input type="text" name="Complemento" className={`${inputField}`}  onChange ={(e)=> setComplementoEmpresa(e.target.value)}/>
          </div>
          
          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Atividade econômica primária </label>
            <input type="text" name="AtividadeEconômicaP" className={`${inputField}`} value ={atividadeEconômicaP as string} onChange ={(e)=> setAtividadeEconômicaP(e.target.value)}/>
          </div>
          
          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Atividade econômica Secundária </label>
            <input type="text" name="AtividadeEconômicaS" className={`${inputField}`} value ={atividadeEconômicaS as string} onChange ={(e)=> setAtividadeEconômicaS(e.target.value)}/>
          </div>
                   
          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">  Registrado como Mei </label>
            <div className='flex flex-row gap-2'>
              <div className='flex flex-row gap-2'>
                <label htmlFor='meiSim' className="cursor-pointer block mb-2 text-sm font-medium text-gray-900 dark:text-white">  Sim</label>
                <input className='cursor-pointer' type="radio" name="Mei" id="meiSim" onChange={(e)=>{
                  !e.target.checked ? setMEI(false) : setMEI(true)}}  />
              </div>
              <div className='flex flex-row gap-2'>
                <label htmlFor='meiNão' className="cursor-pointer block mb-2 text-sm font-medium text-gray-900 dark:text-white" >  Não</label>
                <input className='cursor-pointer' type="radio" name="Mei" id="meiNão" defaultChecked ={true} onChange={(e)=>{
                  e.target.checked ? setMEI(false) : setMEI(true)
                }}/>
              </div>
            </div>

          </div>
          </>
   

          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Site da empresa  </label>
            <input type="text" name="Site" className={`${inputField}`}  value ={siteEmpresa as string} onChange ={(e)=> setSiteEmpresa(e.target.value)}/>
          </div>


        </div>
          

        <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Alterar informações do usuário
        </button>


      </form>

    </div>
  )
}

     
export default CriarEmpresa