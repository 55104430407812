import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { BsEye, BsEyeSlash, BsFolder, BsFolder2Open, BsPencil, BsX } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser'
import { InputUpdateStatus, UserUpdateStatus } from '../../Utils/types/userType'
import LoadingPage from '../LoadingPage'
import styles from './Perfil.module.css'
import AlterarAvatar from './AlterarAvatar'
import { NivelHierarquicoRegistro, RegistroEmpresa } from '../../Utils/types/cadastroTypes'
import PerfilArquivos from './PerfilArqivos'

const UserConfig = ({user}: any) => {

    const {alterarUsuario, getUserInfo,} = useReactQueryUsers();

    const token = localStorage.getItem('token')
    const { data: organogramaData, isLoading: organogramaIsLoaing, refetch } = useQuery({
        queryFn: ()=> getUserInfo(token as string),
        queryKey: ["rqOrganogramaUsers"]
    })

    const [nome, setNome]   = useState<String>('');
    const [email, setEmail] = useState<String>('');
    const [senha, setSenha] = useState<String>('');
    const [senhaAntiga, setSenhaAntiga] = useState<String>('');
    const [cpfCnpj, setCpfCnpj] = useState<String>('');

    const [alterarFoto, setAlterarFoto] = useState<boolean>(false)
    const [senhaStatus, setSenhaStatus] = useState<InputUpdateStatus>(InputUpdateStatus.IDLE)
    const [senhaAntigaStatus, setSenhaAntigaStatus] = useState<InputUpdateStatus>(InputUpdateStatus.IDLE)
    const [erroMsgSenha, setMsgErroSenha] = useState<String>('');
    const [erroMsgSenhaAntiga, setMsgErroSenhaAntiga] = useState<String>('');

    const [showSenha, setShowSenha] = useState<Boolean>(false);
    const [showSenhaAntiga, setShowSenhaAntiga] = useState<Boolean>(false);

    const [NivelHierarquico, setNivelHierarquico] = useState<NivelHierarquicoRegistro | null>(null);
    const [editar, setEditar] = useState<boolean>(false);

    const [userPasta, setUserPasta] = useState<boolean>(false)

    // -------- Mutação ----------------------

    const queryClient = useQueryClient();

    const updateUserMutation = useMutation({
        mutationFn: alterarUsuario,
        onMutate: async(config)=>{
            setUserUpdateStatus(UserUpdateStatus.LOADING);
            // console.log('Mutação iniciada!')
        },
        onSuccess: async(response)=>{

            queryClient.invalidateQueries({queryKey: ['rqUser']});
            queryClient.refetchQueries({queryKey: ['rqUser']});
            

            // console.log('Mutação bem sucedida: ', response);
            if(response.status === 1){
                setUserUpdateStatus(UserUpdateStatus.SUCCESS);
            toast.success(response.msg, {autoClose: 5000})
            }else{
                setUserUpdateStatus(UserUpdateStatus.ERROR);
                toast.error(response.msg, {autoClose: 5000})
            }

        },onError: async(error)=>{
            console.error(error);
            setUserUpdateStatus(UserUpdateStatus.ERROR)
            toast.error('Erro interno, aguarde!');
        },
        onSettled: async(response)=>{
            // console.log('Mutação finalizada');
            setUserUpdateStatus(UserUpdateStatus.IDLE);
            setSenha('');
            setSenhaAntiga('');   
        }

    })


    // -------- Mutação ----------------------


    const verifySenha = (e: any)=>{
        e.preventDefault();

        switch(true){
            case (senha.length > 0 && senha.length < 9):
                 setSenhaStatus(InputUpdateStatus.ERROR)
                 setMsgErroSenha('A senha deve possuir mais de 9 dígitos!');
                break;
            case (senha.length > 0 && senha.length >= 9):
                return setSenhaStatus(InputUpdateStatus.SUCCESS);
                break;
            case (senhaAntiga.length > 0 && senhaAntiga.length < 9):
                 setSenhaAntigaStatus(InputUpdateStatus.ERROR)
                 setMsgErroSenhaAntiga('A senha deve possuir mais de 9 dígitos!');
                break;
            case (senhaAntiga.length > 0 && senhaAntiga.length >= 9):
                return setSenhaAntigaStatus(InputUpdateStatus.SUCCESS);
                break;
            default:
                setSenhaStatus(InputUpdateStatus.IDLE)
                setSenhaAntigaStatus(InputUpdateStatus.IDLE);  
        }
         
    }


    const [userUpdateStatus, setUserUpdateStatus] = useState<UserUpdateStatus>(UserUpdateStatus.IDLE);
  
    const handleChangeUser = (e: any)=>{
        e.preventDefault();

        setUserUpdateStatus(UserUpdateStatus.LOADING)
        const formData = new FormData(e.currentTarget);

        const data ={
            Nome: formData.get('Nome'),
            Email: formData.get('Email'),
            Senha: formData.get('Senha'),
            SenhaAntiga: formData.get('SenhaAntiga'),
            NivelHierarquico: indexNivelHierrarquico(formData.get('NivelHierarquico') as NivelHierarquicoRegistro),
            CargoFuncao: formData.get('CargoFuncao'),
            SuperiorImediato: formData.get("SuperiorImediato")

        }

        const dataLength = Object.values(data).filter((i:any)=> i!=="").length
        if(dataLength <= 0){
            setUserUpdateStatus(UserUpdateStatus.ERROR);
            toast.error('Não são permitidos envios vazios!')
            return;
        }

        const filteredData = Object.fromEntries(Object.entries(data).filter(([key, value])=> value !== "" ));

       
        
        const token = localStorage.getItem('token');
        updateUserMutation.mutate({tokenid: token as string, data: {
            Nome: filteredData.Nome as string,
            Email: filteredData.Email as string    ,
            Cargo:        filteredData.Cargo as string,
            CpfCnpj:      filteredData.CpfCnpj as string,
            Departamento: filteredData.Departamento as string,
            Senha:        filteredData.Senha as string, 
            SenhaAntiga:  filteredData.SenhaAntiga as string,
            NivelHierarquico: filteredData.NivelHierarquico as NivelHierarquicoRegistro,
            CargoFuncao: filteredData.CargoFuncao as RegistroEmpresa,
            SuperiorImediato:  filteredData.SuperiorImediato as string
           
        }});
        return;
    }

    const fechar = (e: any)=>{
        e.preventDefault();
        setAlterarFoto(false);
    }

    const indexNivelHierrarquico = (hierarquia: NivelHierarquicoRegistro)=>{
        switch(true){
            case hierarquia === NivelHierarquicoRegistro.ASSESSOR:
            return {tipo: 'Assessor', nivel:1}
            case hierarquia === NivelHierarquicoRegistro.PRESIDENTE:
                return {tipo: 'Presidente', nivel:2} 
            case hierarquia === NivelHierarquicoRegistro.VICE_PRESIDENTE:
                return {tipo: 'Vice-Presidente', nivel:3}
            case hierarquia === NivelHierarquicoRegistro.DIRETOR:
                return {tipo: 'Diretor'        , nivel:4};
            case hierarquia === NivelHierarquicoRegistro.GERENTE:
                return {tipo: 'Gerente'        , nivel:5};
            case hierarquia === NivelHierarquicoRegistro.SUPERVISOR:
                return {tipo: 'Supervisor'     , nivel:6};
            case hierarquia === NivelHierarquicoRegistro.COODERNADOR:
                return {tipo: 'Coodernador'    , nivel:7};
            case hierarquia === NivelHierarquicoRegistro.TÉCNICO:
                return {tipo: 'Técnico'        , nivel:8};
            case hierarquia === NivelHierarquicoRegistro.ANALISTA:
                return {tipo: 'Analista'       , nivel:9};
            case hierarquia === NivelHierarquicoRegistro.ASSISTENTE:
                return {tipo: 'Assistente'     , nivel:10};
            case hierarquia === NivelHierarquicoRegistro.AUXILIAR:
                return {tipo: 'Auxiliar'       , nivel:11};
            case hierarquia === NivelHierarquicoRegistro.ESTAGIÁRIO:
                return {tipo: 'Estagiário'     , nivel:12};
            case hierarquia === NivelHierarquicoRegistro.TRAINEE:
                return {tipo: 'Trainee'        , nivel:13};
            case hierarquia === NivelHierarquicoRegistro.JOVEM_APRENDIZ:
                return {tipo: 'Jovem Aprendiz' , nivel:14} 

        }
    }




    // Tailwind -----------------------
    const validInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";
    const invalidInput = "bg-gray-50 border border-red-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-red-600 block w-full p-2.5 dark:bg-gray-700 dark:border-red-600 dark:placeholder-gray-400 dark:text-red-500 dark:focus:ring-red-500 dark:focus:border-red-500";
    const successfullInput = "bg-gray-50 border border-green-300 text-green-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-green-600 dark:placeholder-green-400 dark:text-green-500 dark:focus:ring-blue-500 dark:focus:border-blue-500";

    const inputField = '"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"';
    const labelField ="block mb-2 text-sm font-medium text-gray-900 dark:text-white";

    const button = 'mt-5 cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit';
    const cancelButton = 'mt-5 cursor-pointer text-white inline-flex items-center bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 h-fit';
    // Tailwind -----------------------


    // //~~MANUTTENÇÃO~~//
    // useEffect(()=>{
    //     console.log('User do auth: ', user)
    // },[user])
    // // //~~MANUTTENÇÃO~~//


    if(userUpdateStatus === UserUpdateStatus.LOADING){
        return(
            <LoadingPage/>
        )
    }


  return (
    <div className=' w-full p-2' >

     

        {
            alterarFoto && <AlterarAvatar user={user} fechar ={fechar}/>
        }

        {
          !editar ?
            <div className={`${button} w-fit mb-5`} onClick={()=> setEditar(current => !current)}>
          <BsPencil className='text-3md mr-4'/> <span className='text-md'>Alterar informações</span>
          </div>
          :
          <div className={`${cancelButton} w-fit mb-5`} onClick={()=> setEditar(current => !current)}> 
            <BsX className='text-xl mr-4'/> <span> Cancelar</span>
          </div>
        }

        
            <div className='inline-flex cursor-pointer opacity-80 hover:opacity-100 transition-opacity w-fit' onClick={()=> setUserPasta(current=> !current)}>
                {
                    userPasta ?
                    <BsFolder2Open className='text-xl ml-10 mr-2'/>
                        :
                    <BsFolder className='text-xl ml-10 mr-2'/> 
                }
                <span className=''> Documentos </span>

            </div>
        
        <h3 className="mb-4 text-lg font-medium leading-none text-gray-900 dark:text-white">Dados do cadastro </h3>
        
        <form onSubmit={handleChangeUser} className='p-5'>
            <div className={`${styles.inputField}`}>
                
               {
                user.Foto ?
               <div className='w-20 h-20 rounded-full object-contain' onClick={()=> setAlterarFoto(current => !current)}>
                    <img src ={`${user.Foto}`} alt="Perfil..." className='w-full h-auto rounded-full'/>
                </div>

                : 
               
                <div className='w-20 h-20 rounded-full object-contain dark:bg-white text-black bg-gray-200 flex justify-center items-center cursor-pointer' onClick={()=> setAlterarFoto(current => !current)}>
                    <label className='fonte-bold text-3xl cursor-pointer'> {user.Nome.slice(0, 1)}</label>
                </div>
                }
                
                {
                    editar &&
                    <label className="text-xl font-semibold cursor-pointer opacity-80 hover:opacity-100 transition-opacity pt-4" onClick={()=> setAlterarFoto(current => !current)}> Alterar avatar</label>
                }

            </div>

            <div className={`${styles.autoGridForm} gap-4 my-4 h-fit`}>
                <div className={`${styles.inputField}`}>
                    <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome</label>
                    <input type="text" name="Nome" className={validInput} placeholder={`${user.Nome}`} onChange ={(e)=> setNome(e.target.value)} readOnly={editar ? false : true}/>
                </div>

                <div className={`${styles.inputField}`}>
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                    <input type="email" name="Email" className={`${validInput}`} placeholder={`${user.Email}`} onChange ={(e)=> setEmail(e.target.value)} readOnly={editar ? false : true}/>
                </div>
                
                {
                editar &&
                    <>
                <div className={`${styles.inputField}`}>
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Senha</label>
                    <input type={showSenha ? "text": "password"} name="Senha"
                    className={senhaStatus === 'erro' ? invalidInput:  senhaStatus==='sucesso'? successfullInput: validInput} placeholder="•••••••••"  onBlur={verifySenha} onChange ={(e)=> setSenha(e.target.value)}/>
                    <label className='flex flex-row items-center gap-5 my-2 text-sm font-medium cursor-pointer text-gray-900 dark:text-white opacity-80 hover:opacity-100 transition-opacity' onClick={()=>setShowSenha(current => !current)}> {showSenha ? <BsEyeSlash/> : <BsEye/>}  Mostrar senha </label>
                    { senhaStatus === "erro" && <small className='text-red-500'> {erroMsgSenha}</small>}
                    
                </div>    

                <div className={`${styles.inputField}`}>
                    <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Repita a senha antiga </label>
                    <input type="password" name="SenhaAntiga" className={senhaAntigaStatus === 'erro' ? invalidInput :  senhaAntigaStatus=== 'sucesso'? successfullInput : validInput} placeholder="•••••••••"  onChange ={(e: any)=> setSenhaAntiga(e.target.value)} onBlur={verifySenha}/>
                    <label className='flex flex-row items-center gap-5 my-2 text-sm font-medium cursor-pointer text-gray-900 dark:text-white opacity-80 hover:opacity-100 transition-opacity' onClick={()=>setShowSenhaAntiga(current => !current)}> {showSenhaAntiga ? <BsEyeSlash/> : <BsEye/>}  Mostrar senha </label>
                    { senhaAntigaStatus === "erro" && <small className='text-red-500'> {erroMsgSenhaAntiga}</small>}
                    <Link to={'/auth/recuperar-senha'} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 dark:hover:text-gray-50 cursor-pointer opacity-80 hover:opacity-100 transition-opacity" > Recuperar minha senha </Link>
                </div>
                </>
                }

                <div className='max-w-sm'>
                    <label className={`${labelField}`} >Nível hierárquico</label>
                    <select name="NivelHierarquico" className={`${inputField}`} onChange={(e)=> setNivelHierarquico(e.target.value as NivelHierarquicoRegistro)} disabled={!editar ? true : false}>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.PRESIDENTE? true: false} value={NivelHierarquicoRegistro.PRESIDENTE}      >Presidente      </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.VICE_PRESIDENTE} value={NivelHierarquicoRegistro.VICE_PRESIDENTE} >Vice-Presidente </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.VICE_PRESIDENTE ? true: false} value={NivelHierarquicoRegistro.DIRETOR}         >Diretor         </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.GERENTE       ? true: false} value={NivelHierarquicoRegistro.GERENTE       }         >Gerente         </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.SUPERVISOR    ? true: false} value={NivelHierarquicoRegistro.SUPERVISOR    }      >Supervisor      </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.COODERNADOR   ? true: false} value={NivelHierarquicoRegistro.COODERNADOR   }     >Coodernador     </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.TÉCNICO       ? true: false} value={NivelHierarquicoRegistro.TÉCNICO       }         >Técnico         </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.ANALISTA      ? true: false} value={NivelHierarquicoRegistro.ANALISTA      }        >Analista        </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.ASSISTENTE    ? true: false} value={NivelHierarquicoRegistro.ASSISTENTE    }      >Assistente      </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.AUXILIAR      ? true: false} value={NivelHierarquicoRegistro.AUXILIAR      }        >Auxiliar        </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.ESTAGIÁRIO    ? true: false} value={NivelHierarquicoRegistro.ESTAGIÁRIO    }      >Estagiário      </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.TRAINEE       ? true: false} value={NivelHierarquicoRegistro.TRAINEE       }         >Trainee         </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.JOVEM_APRENDIZ? true: false} value={NivelHierarquicoRegistro.JOVEM_APRENDIZ}  >Jovem Aprendiz  </option>
                            <option selected ={user.NivelHierarquico.tipo === NivelHierarquicoRegistro.ASSESSOR      ? true: false} value={NivelHierarquicoRegistro.ASSESSOR      }        >Assessor        </option>
                    </select>

                </div>

           
                <div className='max-w-sm'>
                    <label className={`${labelField}`}>Cargo/função</label>

                    {

                        editar ?
                        <select name="CargoFuncao" className={`${inputField}`} >
                            {
                            NivelHierarquico === NivelHierarquicoRegistro.PRESIDENTE ? 
                            <option className={inputField} value = {RegistroEmpresa.PRESIDENTE}
                            selected={NivelHierarquico === NivelHierarquicoRegistro.PRESIDENTE ? true : false}> Presidente</option>
                            :
                            NivelHierarquico === NivelHierarquicoRegistro.VICE_PRESIDENTE ?
                            <option value = {RegistroEmpresa.VICEPRESIDENTE} 
                            selected={NivelHierarquico === NivelHierarquicoRegistro.VICE_PRESIDENTE ? true : false}> Vice presidente</option>
                            :
                            <>
                            <option className={inputField} value = {RegistroEmpresa.GERAL}> Geral                                    </option>
                            <option className={inputField} value = {RegistroEmpresa.ADMINISTRATIVO}> Administrativo                  </option>
                            <option className={inputField} value = {RegistroEmpresa.ATENDIMENTO             }> Atendimento                        </option>
                            <option className={inputField} value = {RegistroEmpresa.FINANCEIRO              }> Financeiro                          </option>
                            <option className={inputField} value = {RegistroEmpresa.CONTAS_Á_RECEBER        }> Contas á Receber              </option>
                            <option className={inputField} value = {RegistroEmpresa.CONTAR_Á_PAGAR          }> Contar á Pagar                  </option>
                            <option className={inputField} value = {RegistroEmpresa.CONCILIAÇÃO_BANCÁRIA    }> Conciliação Bancária      </option>
                            <option className={inputField} value = {RegistroEmpresa.COBRANÇA                }> Cobrança                              </option>
                            <option className={inputField} value = {RegistroEmpresa.FATURAMENTO             }> Faturamento                        </option>
                            <option className={inputField} value = {RegistroEmpresa.COMERCIAL               }> Comercial                            </option>
                            <option className={inputField} value = {RegistroEmpresa.COMPRAS                 }> Compras                                </option>
                            <option className={inputField} value = {RegistroEmpresa.VENDAS                  }> Vendas                                  </option>
                            <option className={inputField} value = {RegistroEmpresa.MARKETING               }> Marketing                            </option>
                            <option className={inputField} value = {RegistroEmpresa.PROPAGANDA              }> Propaganda                          </option>
                            <option className={inputField} value = {RegistroEmpresa.ALMOXARIFADO            }> Almoxarifado                      </option>
                            <option className={inputField} value = {RegistroEmpresa.LICITAÇÕES_E_CONTRATOS  }> Licitações e Contratos </option>
                            <option className={inputField} value = {RegistroEmpresa.LICITAÇÕES              }> Licitações                          </option>
                            <option className={inputField} value = {RegistroEmpresa.CONTRATOS               }> Contratos                            </option>
                            <option className={inputField} value = {RegistroEmpresa.CAPTAÇÃO                }> Captação                              </option>
                            <option className={inputField} value = {RegistroEmpresa.PROPOSTA                }> Proposta                              </option>
                            <option className={inputField} value = {RegistroEmpresa.HABILITAÇÃO             }> Habilitação                        </option>
                            <option className={inputField} value = {RegistroEmpresa.REGISTRO                }> RegistroEmpresa.</option>
                            <option className={inputField} value = {RegistroEmpresa.RECURSOS_ADMINISTRATIVO }> Recursos Administrativo</option>
                            <option className={inputField} value = {RegistroEmpresa.OPERADOR                }> Operador                              </option>
                            <option className={inputField} value = {RegistroEmpresa.JURÍDICO_E_COMPLIANCE   }> Jurídico e Compliance    </option>
                            <option className={inputField} value = {RegistroEmpresa.JURÍDICO                }> Jurídico                              </option>
                            <option className={inputField} value = {RegistroEmpresa.COMPLIANCE              }> Compliance                          </option>
                            <option className={inputField} value = {RegistroEmpresa.CONTÁBIL                }> Contábil                              </option>
                            <option className={inputField} value = {RegistroEmpresa.FISCAL                  }> Fiscal                                  </option>
                            <option className={inputField} value = {RegistroEmpresa.AUDITORIA               }> Auditoria                            </option>
                            <option className={inputField} value = {RegistroEmpresa.RECEITAS                }> Receitas                              </option>
                            <option className={inputField} value = {RegistroEmpresa.REPRESENTAÇÃO           }> Representação                    </option>
                            <option className={inputField} value = {RegistroEmpresa.COMERCIAL               }> Comercial                            </option>
                            <option className={inputField} value = {RegistroEmpresa.SEGURO                  }> Seguro                                  </option>
                            <option className={inputField} value = {RegistroEmpresa.CONTROLLER              }> Controller                          </option>
                            <option className={inputField} value = {RegistroEmpresa.MANUTENÇÃO              }> Manutenção                          </option>
                            <option className={inputField} value = {RegistroEmpresa.PRODUÇÃO                }> Produção                              </option>
                            <option className={inputField} value = {RegistroEmpresa.SINISTRO                }> Sinistro                              </option>
                            <option className={inputField} value = {RegistroEmpresa.QUALIDADE               }> Qualidade                            </option>
                            <option className={inputField} value = {RegistroEmpresa.SEGURANÇA               }> Segurança                            </option>
                            <option className={inputField} value = {RegistroEmpresa.DESENVOLVIMENTO         }> Desenvolvimento                </option>
                            <option className={inputField} value = {RegistroEmpresa.PESQUISA                }> Pesquisa                              </option>
                            <option className={inputField} value = {RegistroEmpresa.TECNOLOGIA_DA_INFORMAÇÃO}> Tecnologia da Informação</option>
                            <option className={inputField} value = {RegistroEmpresa.RECURSOS_HUMANOS        }> Recursos Humanos                </option>
                            <option className={inputField} value = {RegistroEmpresa.RECRUTAMENTO            }> Recrutamento                        </option>
                            <option className={inputField} value = {RegistroEmpresa.TREINAMENTO             }> Treinamento                          </option>
                            <option className={inputField} value = {RegistroEmpresa.OPERAÇÕES               }> Operações                              </option>
                            <option className={inputField} value = {RegistroEmpresa.LOGÍSTICA               }> Logística                              </option>
                            <option className={inputField} value = {RegistroEmpresa.GESTÃO_DE_PESSOAS       }> Gestão de Pessoas            </option>
                            <option className={inputField} value = {RegistroEmpresa.CREDENCIAMENTO          }> Credenciamento                  </option>
                            <option className={inputField} value = {RegistroEmpresa.EXPEDIÇÃO               }> Expedição                            </option>
                            <option className={inputField} value = {RegistroEmpresa.CONSELHO                }> Conselho                              </option>
                            <option className={inputField} value = {RegistroEmpresa.ORÇAMENTISTA            }> Orçamentista                      </option>
                            <option className={inputField} value = {RegistroEmpresa.RELAÇÕES                }> Relações                              </option>
                            <option className={inputField} value = {RegistroEmpresa.CUSTOS                  }> Custos                                  </option>
                            <option className={inputField} value = {RegistroEmpresa.GESTÃO                  }> Gestão                                  </option>
                            <option className={inputField} value = {RegistroEmpresa.PROJETOS                }> Projetos                              </option>
                            <option className={inputField} value = {RegistroEmpresa.ESTOQUE                 }> Estoque                                </option>
                            <option className={inputField} value = {RegistroEmpresa.ENTREGA                 }> Entrega                                </option>
                            <option className={inputField} value = {RegistroEmpresa.CREDITO                 }> Credito                                </option>
                            <option className={inputField} value = {RegistroEmpresa.PATRIMÔNIO              }> Patrimônio                          </option>
                            <option className={inputField} value = {RegistroEmpresa.TESOURARIA              }> Tesouraria                         </option>
                            <option className={inputField} value = {RegistroEmpresa.INOVAÇÃO                }> Inovação                              </option>
                            <option className={inputField} value = {RegistroEmpresa.ARQUIVOS                }> Arquivos                              </option>
                            <option className={inputField} value = {RegistroEmpresa.PRODUTOS                }> Produtos                              </option>
                            <option className={inputField} value = {RegistroEmpresa.TÉCNICO                 }> Técnico                                </option>
                            <option className={inputField} value = {RegistroEmpresa.PROTOCOLO               }> Protocolo                            </option>
                            <option className={inputField} value = {RegistroEmpresa.IMPRENSA                }> Imprensa                              </option>
                            <option className={inputField} value = {RegistroEmpresa.AGENDAMENTO             }> Agendamento                        </option>
                            </>

                        }
                    </select>
                        :
                        <label className={`${inputField}`}>{user.CargoFuncao}</label>
                    }
                </div>
                
            


               
                    
                    
                {
                  NivelHierarquico !== NivelHierarquicoRegistro.PRESIDENTE && editar &&  

                  <>
                    <div className='max-w-sm'>
                    <select name="SuperiorImediato" className={inputField}>
                        <option className={inputField} > Selecione o seu superior imediato</option>
                        {
                            organogramaData && organogramaData.usuarios.map((usuario: any) => (
                                <option value={usuario._id}>{usuario.Nome} - </option>
                            ))
                        }
                    </select>
                    </div>

                    <div className={`${styles.inputField}`}>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white ">CPF/CNPJ </label>
                        <input type="password" className={`${validInput}`}placeholder={`${user.CpfCnpj}`} readOnly ={true} onChange ={(e)=>setCpfCnpj(e.target.name)}/>
                        <small  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Este campo não pode ser alterado, entre em contato com um administrador do sistema para solicitar a alteração!.</small>
                    </div>

                    </>
                    }


                   

            </div>


    

            {
            editar &&
            <button type="submit" className={`${button}`}>
                Alterar informações do usuário
            </button>
            }

            
        </form>

        {
            userPasta &&
            <PerfilArquivos user ={user}/>
        }
            

    </div>
  )
}

export default UserConfig