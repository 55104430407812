import React, { useState } from 'react';
import { DisplayMiddle } from '../../Utils/types/PortalTypes'; // Certifique-se de ajustar o caminho para onde o enum DisplayMiddle está definido
import ProcessosFerramentas from './ProcessosFerramentas';
import { BsChat, BsPerson } from 'react-icons/bs';

const TopProcessos = ({ processo, filtroMiddle, displayFiltroMiddle }: any) => {

  const [chatNotificações, setChatNotificações] = useState<number>(0);

  // Tailwind --------------------------

  const labelItem = 'flex flex-row justify-center w-full items-center gap-2 py-0.5 px-2 opacity-80 hover:opacity-100 transition-opacity cursor-pointer text-sm';

  const selectedLabelItem = 'flex flex-row justify-center w-full items-center gap-2 py-0.5 px-2 cursor-pointer text-sm scale-110 font-semibold bg-[#ff8027] text-white rounded-md';

  // Tailwind --------------------------



  return (
    <div className='w-full flex flex-row justify-between h-fit'>
      
      <ProcessosFerramentas processo={processo} filtroMiddle ={filtroMiddle} displayFiltroMiddle={displayFiltroMiddle}/>

      <label className={displayFiltroMiddle === DisplayMiddle.RESUMO ? selectedLabelItem : labelItem} onClick={() => filtroMiddle(DisplayMiddle.RESUMO)}>Resumo</label>
      <label className={displayFiltroMiddle === DisplayMiddle.DOCUMENTOS ? selectedLabelItem : labelItem} onClick={() => filtroMiddle(DisplayMiddle.DOCUMENTOS)}>Documentos</label>
      <label className={displayFiltroMiddle === DisplayMiddle.ARP ? selectedLabelItem : labelItem} onClick={() => filtroMiddle(DisplayMiddle.ARP)}>A.R.P</label>
      <label className={displayFiltroMiddle === DisplayMiddle.CONTRATOS ? selectedLabelItem : labelItem} onClick={() => filtroMiddle(DisplayMiddle.CONTRATOS)}>Contratos</label>
      <label className={displayFiltroMiddle === DisplayMiddle.OBJETO ? selectedLabelItem : labelItem} onClick={() => filtroMiddle(DisplayMiddle.OBJETO)}>Objeto</label>
      <label className={displayFiltroMiddle === DisplayMiddle.EMPENHO ? selectedLabelItem : labelItem} onClick={() => filtroMiddle(DisplayMiddle.EMPENHO)}>Empenho</label>
      <label className={displayFiltroMiddle === DisplayMiddle.OS ? selectedLabelItem : labelItem} onClick={() => filtroMiddle(DisplayMiddle.OS)}>O.S'ᴮ</label>
      <label className={displayFiltroMiddle === DisplayMiddle.FATURAMENTO ? selectedLabelItem : labelItem} onClick={() => filtroMiddle(DisplayMiddle.FATURAMENTO)}>Faturamento</label>
      <label className={displayFiltroMiddle === DisplayMiddle.ACT ? selectedLabelItem : labelItem} onClick={() => filtroMiddle(DisplayMiddle.ACT)}>A.C.T</label>
    
    </div>
  );
}

export default TopProcessos;
