import React, { useEffect, useState } from 'react'
import { getCnpjData } from '../../Utils/cnpjAutocomplete';
import { BsArrowRight } from 'react-icons/bs';
import LoadingPage from '../LoadingPage';
import NovoProcessoEtapaUm from './NovoProcessoEtapaUm';
import NovoProcessoEtapaDois from './NovoProcessoEtapaDois';
import NovoProcessoEtapaTres from './NovoProcessoEtapaTres';
import { toast } from 'react-toastify';
import { RegistrarProcesso } from '../../Utils/types/PortalTypes';
import NovoProcessoEtapaQuatro from './NovoProcessoEtapaQuatro';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import AlertaAssinatura from '../AlertaAssinatura';

type Props = {}

const NovoProcesso = ({fechar, captacaoPncp}: any) => {

    const {registrarProcesso} = useReactQueryProcessos();
    const token =localStorage.getItem('token');
    const empresaSelecionada = localStorage.getItem('empresaSelecionada');

    const [planos, setPlanos] = useState<boolean>(false)



    // MUTAÇÃO ---------------------------------
    const queryClient = useQueryClient();
    const registrarProcessoMutation = useMutation({
        mutationFn: registrarProcesso,
        onMutate: async(config)=>{
            // console.log('Mutação inicida!');
            setLoading(true)
        },
        onSuccess: async(response)=>{
            // console.log('Mutação bem sucedida!', response);

            queryClient.invalidateQueries({queryKey: ['rqProcessos']})
            queryClient.fetchQuery({queryKey: ['rqProcessos']})
            queryClient.refetchQueries({queryKey: ['rqProcessos']})
            
            queryClient.invalidateQueries({queryKey: ['rqPrcessosInfo']})
            queryClient.fetchQuery({queryKey: ['rqPrcessosInfo']})
            queryClient.refetchQueries({queryKey: ['rqPrcessosInfo']})



            if(response.status === 1){
                toast.success(response.msg);
            }
            if(response.status === 4){
                toast.warn(response.msg)
                setPlanos(true)
            }
            if(response.status === 3)
            {
                toast.error(response.msg);
            }
        },
        onError: async(error, variables, context)=> {
            console.error(error.message);
            toast.error(error.message);
            
        },
        onSettled: async(data)=>{
            // console.log('Mutação concluída!');
            setLoading(false)
        }
    })



    // MUTAÇÃO ---------------------------------



    const [registrarProcessoEtapa, setRegistrarProcessoEtapa] = useState<RegistrarProcesso>(RegistrarProcesso.INFOLICITANTE);
    const [processoData, setProcessoData] = useState<any>();

    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false);



    // // //~~MANUTENÇÃO~~//
    // useEffect(()=>{
    //     console.log('Dados do pncp: ', captacaoPncp)
    // },[captacaoPncp])

    // // //~~MANUTENÇÃO~~//


    const handleEtapa =(e: any, etapaData: any, contato: any, empresaId: any, usuarioSelecionado: any, portalSelecionado: any)=>{
        e.preventDefault()
        setRegistrarProcessoEtapa(RegistrarProcesso.INFOPROCESSO);


        setProcessoData({
            ...processoData,
            ...etapaData
        })

        switch(true){
            case registrarProcessoEtapa === RegistrarProcesso.INFOLICITANTE:
                    setRegistrarProcessoEtapa(RegistrarProcesso.INFOPROCESSO);
                    break;
            case registrarProcessoEtapa === RegistrarProcesso.INFOPROCESSO:
                    setRegistrarProcessoEtapa(RegistrarProcesso.INFODATAS);
                    break;
            case registrarProcessoEtapa === RegistrarProcesso.INFODATAS:
                    setRegistrarProcessoEtapa(RegistrarProcesso.CONTATOS);
                    break;
            case registrarProcessoEtapa === RegistrarProcesso.CONTATOS:
                    setLoading(true);

                    const formData = new FormData()
                    formData.append('file', processoData.file)

                    const dataTest = {
                        token: token as string, empresaid: empresaSelecionada as string, data: processoData,
                        Arquivo: formData,
                        contatos: contato,
                        empresaSelecionada: empresaId,
                        usuarioSelecionado,
                        portalSelecionado
                    }

                    console.log('Data final sended ---->  ', dataTest)

                    registrarProcessoMutation.mutate({
                        token: token as string, empresaid: empresaSelecionada as string, data: processoData,
                        Arquivo: formData,
                        contatos: contato,
                        empresaSelecionada: empresaId,
                        usuarioSelecionado: usuarioSelecionado,
                        portalSelecionado: portalSelecionado
                    })
              
                    return;
        
        }
    }

    
 
    const voltar =()=>{
        switch(true){
            case registrarProcessoEtapa === RegistrarProcesso.INFOPROCESSO:
                setRegistrarProcessoEtapa(RegistrarProcesso.INFOLICITANTE);
            return;
            break;
            case registrarProcessoEtapa === RegistrarProcesso.INFODATAS:
                setRegistrarProcessoEtapa(RegistrarProcesso.INFOPROCESSO);
            return;
            case registrarProcessoEtapa === RegistrarProcesso.CONTATOS:
                setRegistrarProcessoEtapa(RegistrarProcesso.INFODATAS);
            return;
            break;
        }
    }

   

    if(loading){
        return(
            <div id="crud-modal"  aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-5xl max-h-full">

                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                     <LoadingPage/>
                    </div>
                </div>
            </div>
        )
    }

    if(sucesso){
        return(
        <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
           
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Processo registrado com sucesso!</h3>
                    
                        <button onClick={()=> setSucesso(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"> Registrar outro processo</button>

                        <button onClick={fechar} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Fechar</button>

                    </div>
                </div>
            </div>
        </div>

        )
    }


    if(!empresaSelecionada){
        toast.warn('Selecione uma empresa para registrar um processo!')
        return(
            <>
            </>
        )
    }


  return (
    <div>

        <div className={registrarProcessoEtapa !== RegistrarProcesso.INFOLICITANTE ? 'hidden' : '' }>
            <NovoProcessoEtapaUm etapaUmData = {handleEtapa} cancelar = {fechar} dadosPncp ={captacaoPncp}/>
        </div>
         
        <div className={registrarProcessoEtapa !== RegistrarProcesso.INFOPROCESSO ? 'hidden' : '' }>
            <NovoProcessoEtapaDois etapaDoisData = {handleEtapa} cancelar = {fechar} voltar ={voltar} dadosPncp ={captacaoPncp}/>
        </div>
      
        <div className={registrarProcessoEtapa !== RegistrarProcesso.INFODATAS ? 'hidden' : '' }>
            <NovoProcessoEtapaTres etapaTresData = {handleEtapa} cancelar = {fechar} voltar ={voltar} dadosPncp ={captacaoPncp}/>
        </div>
        
        <div className={registrarProcessoEtapa !== RegistrarProcesso.CONTATOS ? 'hidden' : '' }>
            <NovoProcessoEtapaQuatro etapaQuatroData = {handleEtapa} cancelar = {fechar} voltar ={voltar}/>
        </div>
  
    </div>
  )
}

export default NovoProcesso