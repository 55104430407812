import axios from "axios";
import { useState } from "react";
import { TArquivosExtra } from "../types/PortalTypes";

export const useReactQueryTimeline =()=>{

    const [fileUpload, setFileUpload] = useState<any>('');
    const baseUrl: any = process.env.REACT_APP_PRIVATE_API_URL

 
    type TSalvarArquivoTimeline={
        token: string,
        empresaid: string,
        resumo:TArquivosExtra,
        Arquivo: HTMLFormElement | any
    }

    const salvarArquivosTimeline = async(data: TSalvarArquivoTimeline)=>{

        const url = `${baseUrl}/api/timeline/salvar-timeline?token=${data.token}&empresaid=${data.empresaid}`;
    
        try{
            const res: any = await axios({
            url: url,
            params:{
                resumo: {
                    NomeDoArquivo: data.resumo.NomeDoArquivo,
                    Descrição: data.resumo.Descrição,
                    Processoid: data.resumo.Processoid,
                    Modalidade: data.resumo.Modalidade,
                    Size: data.resumo.Size,
                    empresaid: data.empresaid
                }
            },
            method: 'POST',
            headers:{
                'Content-Type': 'multipart/form-data'
            },
            data: data.Arquivo,
            onUploadProgress: (p: any)=>{
                const completedUpload = p.loaded/p.total ;
                setFileUpload(completedUpload)
            }
            });
    
            const response = await res.data;
            return response;

            }
            catch(err){
                console.error(err);
                return null
    
            }
    
    }
    
    
    const getArquivosTimeline =async(token: string, processoid: string)=>{
    
        const url = `${baseUrl}/api/timeline/get-timeline?token=${token}&processoid=${processoid}`;
    
        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
            
        }
    }
    
    
    type TApagarArquivoTimeline = {
        token: string,
        arquivoid: string,
        empresaid: string
    }
    
    const apagarArquivosTimeline =async(data: TApagarArquivoTimeline)=>{
    
        const url = `${baseUrl}/api/timeline/apagar-timeline?token=${data.token}&arquivoid=${data.arquivoid}&empresaid=${data.empresaid}`;
    
        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
            
        }
    }


    return {
        salvarArquivosTimeline,
        getArquivosTimeline, 
        apagarArquivosTimeline
    }
}

