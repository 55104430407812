import React, { useEffect } from 'react'
import DashboardLarea from '../Components/DashboardComponents/DashboardLarea'
import SideNavWrapper from '../Components/PortalComponents/SideNavWrapper';
import { useUserContext } from '../Context/useUserContext'
import NotificaçõesComponent from '../Components/Notificações/NotificaçõesComponent'


const DashboardPage = () => {

  
  const [userContext]= useUserContext();

  // //~~MANUTENÇÃO~~//
  // useEffect(()=>{
  //   console.log('Context do usuario no dash: ', userContext)
  // },[userContext])
  // //~~MANUTENÇÃO~~//

  return (
    <div className='w-full h-fit min-h-screen  gap-2 dark:bg-gradient-to-r dark:from-stone-900 dark:to-slate-800 bg-gradient-to-tl from-slate-200 to-zinc-100'>
      <SideNavWrapper/>
      <div>

        <div className='w-full max-w-full h-fit gap-x-2 px-2 shadow-lg gap-2 pt-5'>
          <DashboardLarea />
        </div>
  
      </div>

    </div>
  )
}

export default DashboardPage