import React, { useEffect, useState } from 'react'
import { TCaptacaoCard, fakeData } from '../../Utils/DevUtils/FakeCaptacao'
import CaptacaoCard from './CaptacaoCard'
import { BsPencil, BsQuestionCircle } from 'react-icons/bs'
import NovoProcesso from '../ProcessosComponents/NovoProcesso'



const CaptacaoDisplay = ({editais}: any) => {


  // //~~MANUTENÇÃO~~//
  // useEffect(()=>{
  //   console.log('Edital: ', editais)
  // },[editais])
  // //~~MANUTENÇÃO~~//

  const activePage = 'w-ful rounded-md shadow-lg min-h-screen max-h-screen overflow-y-auto chart';


  return (

        <>
          {
            editais && editais.items.map((item: any, index:number)=>(
              <CaptacaoCard item ={item} key={index}/>
            ))
          }
        </>

  )
}

export default CaptacaoDisplay