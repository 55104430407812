import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { BsPlus, BsX } from 'react-icons/bs'
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { toast } from 'react-toastify';
import LoadingPage from '../LoadingPage';
import { DisplayMiddle } from '../../Utils/types/PortalTypes';

const NovaPastaProcesso = ({processo, fechar, editar, pasta}: any) => {


    console.log('Pastas --------> : ', pasta)

    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false)

    // //~~MANUTENÇÃO~~//
    // useEffect(()=>{
    //     console.log('Processo nova pasta: ', processo)
    //     console.log('Processo nova pasta: ', pasta)
    // },[processo])
    // //~~MANUTENÇÃO~~//


    // Mutation
    const token = localStorage.getItem('token');
    const empresaSelecionada = localStorage.getItem('empresaSelecionada')

    const queryClient = useQueryClient();
    const {processosPastas} = useReactQueryProcessos();

    const pastaProcessoMutation =useMutation({
        mutationFn: processosPastas,
        onMutate: async(config)=>{
            // console.log('Mutação iniciada!')
        },
        onSuccess: async(response)=>{

            // console.log('Mutação bem sucedida: ', response)

            queryClient.invalidateQueries({queryKey: ['rqPastasProcesso']})
            queryClient.fetchQuery({queryKey: ['rqPastasProcesso']})
            queryClient.refetchQueries({queryKey: ['rqPastasProcesso']})

            if(response.status === 1){
                toast.success(response.msg)
                setLoading(false);
                setSucesso(true);
            }
            if(response.status ===3){
                toast.error(response.mg)
            }
        },
        onError: async(err) =>{
            console.error(err.message);
            toast.error(err.message);
        },onSettled: async(data) =>{
            // console.log('Mutação concluida!');
            setLoading(false)
            fechar()
        }
    })
    

    // Mutation




    const handleNovaPasta =(e: any)=>{

        e.preventDefault()
        const formData = new FormData(e.currentTarget);

       
        const pastaData = {
        nomePasta:      formData.get('nomePasta'),
        dataInicio:     formData.get('dataInicio'),
        dataVencimento: formData.get('dataVencimento'),
        categoria:      formData.get('categoria'),
        valor:          formData.get('valor'),
        status:         formData.get('status'),
        }

        pastaProcessoMutation.mutate({
            empresaid: processo.empresaSelecionada as string,
            token: token as string,
            action: 'nova',
            processoid: processo._id,
            data: {
                nomePasta: pastaData.nomePasta as string || "",
                categoria: pastaData.categoria as string || "",
                valor: pastaData.valor as string || "",
                status : pastaData.status as string || "",
                processoId: processo._id,
                dataInicio: pastaData.dataInicio as string || "",
                dataVencimento: pastaData.dataVencimento as string || "",
                
            }

        })
    }

    const handleEditarPasta =(e: any)=>{
        e.preventDefault()
        const formData = new FormData(e.currentTarget);

       
        const pastaData = {
        nomePasta:      formData.get('nomePasta')|| pasta.nomePasta,
        dataInicio:     formData.get('dataInicio')|| pasta.dataEmissao,
        dataVencimento: formData.get('dataVencimento') || pasta.dtaVencimento,
        categoria:      formData.get('categoria')|| pasta.ategoria,
        valor:          formData.get('valor')|| pasta.valor,
        status:         formData.get('status') || pasta.status,
        }

        pastaProcessoMutation.mutate({
            empresaid: processo.empresaSelecionada as string,
            token: token as string,
            action: 'editar',
            processoid: processo._id,
            pastaid: pasta._id,
            data: {
                nomePasta: pastaData.nomePasta as string || pasta.nomePasta,
                categoria: pastaData.categoria as string || pasta.categoria,
                valor: pastaData.valor as string || pasta.valor,
                status : pastaData.status as string || pasta.status,
                processoId: processo._id,
                dataInicio: pastaData.dataInicio as string || pasta.dataInicio,
                dataVencimento: pastaData.dataVencimento as string || pasta.dataVencimento,
                
            }

        })
    }

    const selectInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
    const optionInput ="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";

    if(sucesso){
        return(
        <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Processo registrado com sucesso!</h3>
                    
                    

                        <button onClick={()=> setSucesso(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Fechar</button>

                    </div>
                </div>
            </div>
        </div>

        )
    }

  return (
    <div>
        <div id="crud-modal" tabIndex ={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {
                        loading && <LoadingPage/>                               
                    }
                
                    
                    <form className="p-4 md:p-5" onSubmit={editar ? handleEditarPasta: handleNovaPasta}>
                        <div className="grid gap-4 mb-4 grid-cols-2">

       
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome da pasta </label>
                                <input required={editar ? false : true} type="text" name="nomePasta" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={editar ? pasta.nomePasta:"Nome da pasta"} />
                            </div>
                           
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Valor </label>
                                <input type="number" name="valor" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={editar ? pasta.valor:"Valor"} />
                            </div>

                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data inicio </label>
                                
                                <input type="datetime-local" name="dataInicio" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"  required={true} />
                                
                            </div>
                            
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data vencimento </label>
                                <input type="datetime-local" name="dataVencimento" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Categoria" />
                            </div>

                            <div >
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status </label>
                            <select name="status" id="status" className={`${selectInput}`} required={editar ? false : true}>
                                    <option className={`${optionInput}`} selected ={editar && pasta.status === "Nenhum" ? true: false} value="Nenhum"> Nenhum</option>
                                    <option className={`${optionInput}`} selected ={editar && pasta.status === "Entregue"  ? true: false} value="Entregue"  >Entregue</option>
                                    <option className={`${optionInput}`} selected ={editar && pasta.status === "Distratado"? true: false} value="Distratado">Distratado</option>
                                    <option className={`${optionInput}`} selected ={editar && pasta.status === "Encerrado" ? true: false} value="Encerrado">Encerrado</option>
                                    
                                    <option className={`${optionInput}`} selected ={editar && pasta.status === "Deferido" ? true: false} value="Deferido">Deferido</option>
                                    <option className={`${optionInput}`} selected ={editar && pasta.status === "Indeferido" ? true: false} value="Indeferido">Indeferido</option>

                                </select>
                            </div>
                            
                            <div> 
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Categoria </label>
                                <select name="categoria" id="categoria" className={`${selectInput}`} required={editar ? false : true}>
                                        {
                                            editar &&
                                            <option className={`${optionInput}`} selected={true} value={pasta.categoria}>{pasta.categoria} </option>
                                        }
                                        
                                        <option className={`${optionInput}`} value="Públicação">Públicação</option>
                                        <option className={`${optionInput}`} value="Edital/Anexos">Edital/Anexos</option>
                                        <option className={`${optionInput}`} value="Recibo Edital">Recibo Edital</option>
                                        <option className={`${optionInput}`} value="Cotação">Cotação</option>
                                        <option className={`${optionInput}`} value="C.P.H">C.P.H</option>
                                        <option className={`${optionInput}`} value={DisplayMiddle.ESCLARECIMENTO}>Esclarecimento</option>
                                        <option className={`${optionInput}`} value="Impugnação">Impugnação</option>
                                        <option className={`${optionInput}`} value="Decisão Impugnação">Decisão Impugnação</option>
                                        <option className={`${optionInput}`} value="Vistoria">Vistoria</option>
                                        <option className={`${optionInput}`} value="V.D.C">V.D.C</option>
                                        <option className={`${optionInput}`} value={DisplayMiddle.IRECURSO}>Intenção Recuso</option>
                                        <option className={`${optionInput}`} value={DisplayMiddle.RECURSO}>Recurso</option>

                                        <option className={`${optionInput}`} value={DisplayMiddle.CONTRARRAZÃO}>Contrarrazão</option>

                                        <option className={`${optionInput}`} value="Parecer Jurídico">Parecer Jurídico</option>
                                        <option className={`${optionInput}`} value="Decisão Final Recurso">Decisão Final Recurso</option>
                                        <option className={`${optionInput}`} value={DisplayMiddle.ATR}>Ata Realização</option>
                                        <option className={`${optionInput}`} value="A.C.T">Proposta Readequada</option>
                                        <option className={`${optionInput}`} value="Amostra">Amostra</option>
                                        <option className={`${optionInput}`} value={DisplayMiddle.HOMOLOGAÇÃO}>Homologação</option>
                                        <option className={`${optionInput}`} value="Ajdudicação">Ajdudicação</option>
                                        <option className={`${optionInput}`} value="Ata Registro Preços">Ata Registro Preços</option>
                                        <option className={`${optionInput}`} value="Contrato">Contrato</option>
                                        <option className={`${optionInput}`} value="Seguro Garantia Contratual">Seguro Garantia Contratual</option>
                                        <option className={`${optionInput}`} value="Empenho">Empenho</option>
                                        <option className={`${optionInput}`} value={DisplayMiddle.OS}>Ordem Serviço'ᴮ</option>
                                        <option className={`${optionInput}`} value="Comprovante Entrega">Comprovante Entrega</option>
                                        <option className={`${optionInput}`} value="Nota Fiscal">Nota Fiscal</option>
                                        <option className={`${optionInput}`} value="Boleto">Boleto</option>
                                        <option className={`${optionInput}`} value="Comprovante Pagamento">Comprovante Pagamento</option>
                                        <option className={`${optionInput}`} value="Adesão">Adesão</option>
                                        <option className={`${optionInput}`} value="Ofício's">Ofício's</option>
                                        <option className={`${optionInput}`} value={DisplayMiddle.ACT}>A.C.T</option>
                                        
                                        <option className={`${optionInput}`} value={DisplayMiddle.DILIGENCIA}>Diligência</option>
                                        <option className={`${optionInput}`} value={DisplayMiddle.ADJUDICAÇÃO}>Adjudicação</option>
                                        <option className={`${optionInput}`} value={DisplayMiddle.REGISTROPROP}>Registro proposta</option>
                                        <option className={`${optionInput}`} value={DisplayMiddle.MANDATOS}>Mandato de segurança</option>
                                        <option className={`${optionInput}`} value={DisplayMiddle.APRESENTAÇÃO}>Apresentação</option>
                                        <option className={`${optionInput}`} value={DisplayMiddle.JULGAMENTOR}>Julgamento recurso</option>
                                        
                                        <option className={`${optionInput}`} value='Pedido Esclarecimento'>Pedido Esclarecimento</option>
                                        <option className={`${optionInput}`} value='Resposta Esclarecimento'>Resposta Esclarecimento</option>
                                        <option className={`${optionInput}`} value='Pedido Impugnação'  >Pedido impugnação</option>
                                        <option className={`${optionInput}`} value='Resposta Impugnação'>Resposta impugnação</option>
                                        <option className={`${optionInput}`} value="Solicitação Diligência"> Solicitação Diligência </option>
                                        <option className={`${optionInput}`} value="Resposta Diligência"> Resposta Diligência</option>
                                        <option className={`${optionInput}`} value="PPedido Mandato.s"> Pedido Mandato S. </option>
                                        <option className={`${optionInput}`} value="Resposta Mandato.s"> Resposta Mandato S.</option>
                                        <option className={`${optionInput}`} value="Publicação Aviso"> Publicação Aviso</option>
                                        <option className={`${optionInput}`} value="Licitação">        Licitação       </option>
                                        <option className={`${optionInput}`} value="Edital/Anexos">    Edital/Anexos   </option>
                                        <option className={`${optionInput}`} value="Termo Referência"> Termo Referência</option>
                                        <option className={`${optionInput}`} value="Recibo Edital">    Recibo Edital   </option>
                                        <option className={`${optionInput}`} value="Retificação">       Retificação     </option>
                                        <option className={`${optionInput}`} value="Rerratificação">   Rerratificação  </option>
                                        <option className={`${optionInput}`} value="Adiamento">        Adiamento       </option>
                                        <option className={`${optionInput}`} value="Cancelamento">     Cancelamento    </option>
                                        <option className={`${optionInput}`} value="Revogado">         Revogado        </option>
                                        <option className={`${optionInput}`} value="Suspenso">         Suspenso        </option>
                                        <option className={`${optionInput}`} value="Fracassado">       Fracassado      </option>
                                        <option className={`${optionInput}`} value="Deserto">          Deserto        </option> 

                                        <option className={`${optionInput}`} value="Outros">Outros</option>
                                </select>
                            </div>

               

                        </div>

               
                        <div className='flex flex-row gap-2'>
                            <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <BsPlus className='text-xl'/>
                                {editar ?'Salvar alterações' :'Criar pasta' }
                            </button>
                            <label onClick={fechar}  className="text-white inline-flex items-center cursor-pointer bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                                <BsX className='text-xl'/>
                                Cancelar
                            </label>
                        </div>
                        


                    </form>
                </div>
            </div>
        </div> 
    </div>
  )
}

export default NovaPastaProcesso